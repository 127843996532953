<template>
    <div class="content-detail">
        <div v-if="content">
            <div class="row full-row bg-fluid">
                <div class="bg-detail-main d-none d-lg-flex">
                    <div class="flex-fill"></div>
                    <div class="flex-fill bg-gray"></div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-8 detail-main">
                            <div class="row detail-main-area">
                                <div class="col-12 px-3 px-lg-0">
                                    <ul class="breadcrumb-list" aria-label="パンくずリスト">
                                        <li>
                                            <router-link :to="{name: 'Top'}"
                                                tabindex="0"
                                                id="topLink"
                                                :aria-label="$t('breadcrumbs.top')">
                                                <span class="step">{{ $t('siteInfo.top') }}</span>
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'Contents'}"
                                                tabindex="0"
                                                :aria-label="$t('breadcrumbs.searchResults')">
                                                <span class="step">{{ $t('siteInfo.contents') }}</span>
                                            </router-link>
                                        </li>
                                        <li>
                                            <span tabindex="0" aria-current="page">{{ content.relationships.details[this.$i18n.locale].title }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-12 px-3 px-lg-0">
                                    <h1 tabindex="0" id="contents-area" class="content-title">{{
                                            content.relationships.details[this.$i18n.locale].title
                                        }}</h1>
                                    <h2 tabindex="0" class="content-subtitle">
                                        {{ content.relationships.details[this.$i18n.locale].subtitle }}</h2>
                                </div>
                                <div class="p-0 col-12">
                                    <div class="embed-responsive embed-responsive-4by3 aspect-adjust">
                                        <div v-if="content.attributes.content_type === 'video'
                                                && content.attributes.video_type !== 'searchConditions.videoTypes.vr'">
                                            <div tabindex="0" v-html="content.attributes.video_path"
                                                :aria-label="(content.relationships.details[this.$i18n.locale].title) +
                                                     $t('accessibility.contentImage')">
                                            </div>
                                        </div>
                                        <div v-else-if="content.attributes.content_type === 'image'
                                        && content.attributes.image_type === 'searchConditions.imageTypes.vr'">
                                            <div class="embed-responsive-item">
                                                <Pano :source="content.attributes.detail_size_url"></Pano>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="embed-responsive-item text-center img-object">
                                                <img :src="content.attributes.detail_size_url"
                                                    class="img-top"
                                                    :alt="(content.relationships.details[this.$i18n.locale].title) +
                                                         $t('accessibility.contentImage')"
                                                    tabindex="0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4 md-btn-block">

                                <div class="col-12 d-lg-flex d-xl-flex text-lg-right px-0 pr-lg-3 detail-btn-wrap justify-content-sm-center">
                                    <div class="text-note">
                                        <span v-if="content.attributes.image_type === 'searchConditions.imageTypes.vr'">
                                            {{ $t('contents.imageVr') }}
                                        </span>
                                    </div>
                                    <div class="btn-utility-wrap">
                                        <a v-if="content.attributes.video_type === 'searchConditions.videoTypes.vr'"
                                            tabindex="0"
                                            class="btn btn-sp-full btn-light btn-sm"
                                            :aria-label="$t('accessibility.viewingVR')"
                                            :href="content.attributes.video_path"
                                            target=”_blank”>
                                            <svg class="ico_ex_link" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                                <path d="M24.24,5.98a.682.682,0,0,0-.082.007H19.821A.739.739,0,0,0,19.8,7.465h2.67l-7.6,7.6a.739.739,0,1,0,1.024,1.066l.021-.021,7.6-7.6v2.649a.739.739,0,0,0,1.478.021V6.82a.739.739,0,0,0-.631-.833A.681.681,0,0,0,24.24,5.98ZM9.478,8.942A1.489,1.489,0,0,0,8,10.42V21.5A1.489,1.489,0,0,0,9.478,22.98H20.56A1.489,1.489,0,0,0,22.038,21.5V12.209L20.56,13.687V21.5H9.478V10.42h7.815l1.478-1.478H9.478Z" transform="translate(-8 -5.98)" fill="#696969" />
                                            </svg>

                                            {{ $t('contents.vrLink') }}
                                        </a>
                                        <button v-if="enableAddToFavorites"
                                            tabindex="0"
                                            @click="addToFavorites(content)"
                                            class="btn btn-sp-full btn-light btn-sm"
                                            :aria-label="$t('accessibility.addToFavorites')">
                                            <svg class="ico_add" xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                viewBox="0 0 12 12">
                                                <g transform="translate(0 0)">
                                                    <text transform="translate(0 11)" fill="#696969" font-size="12"
                                                        font-family="HiraginoSans-W3, Hiragino Sans">
                                                        <tspan x="0" y="0">＋</tspan>
                                                    </text>
                                                </g>
                                            </svg>
                                            {{ $t('contents.addToFavorites') }}
                                        </button>
                                        <button v-if="!enableAddToFavorites"
                                            @click="removeFromFavorites(content.id)"
                                            class="btn btn-light btn-sm mr-2"
                                            :aria-label="$t('accessibility.removeFromFavorites')"
                                            tabindex="0">
                                            {{ $t('contents.removeFromFavorites') }}
                                        </button>
                                        <button class="btn btn-light btn-sm d-none d-lg-inline ml-2"
                                            v-if="content.attributes.content_type === 'image'"
                                            tabindex="0"
                                            @click="$refs.imagePreviewModal.open(content)"
                                            :aria-label="$t('accessibility.zoomContent')">
                                            <svg class="ico_zoom" xmlns="http://www.w3.org/2000/svg" width="16.905"
                                                height="16.905" viewBox="0 0 16.905 16.905">
                                                <g transform="translate(0.375 0.375)">
                                                    <circle cx="5.965" cy="5.965" r="5.965" fill="none"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-miterlimit="10" stroke-width="0.75" />
                                                    <line x2="5.5" y2="5.5" transform="translate(10.5 10.5)"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-miterlimit="10" fill="none" />
                                                </g>
                                            </svg>
                                            {{ $t('contents.zoom') }}
                                        </button>
                                        <image-preview-modal ref="imagePreviewModal"></image-preview-modal>
                                        <button class="btn btn-sp-full btn-success btn-sm p-0 d-inline d-lg-none"
                                            :class="[content.attributes.video_type === 'searchConditions.videoTypes.vr' ? 'btn-vr' : '']"
                                            tabindex="0"
                                            @click="$refs.downloadPanelModal.open(content)">
                                            <svg class="ico_download" xmlns="http://www.w3.org/2000/svg" width="20.631"
                                                height="16.631" viewBox="0 0 20.631 16.631">
                                                <g transform="translate(0.116 0.016)">
                                                    <g transform="translate(0.7 7.727)">
                                                        <path
                                                            d="M19.7,10.3v7.608a.485.485,0,0,1-.435.466H1.135A.485.485,0,0,1,.7,17.908V10.3"
                                                            transform="translate(-0.7 -10.3)" fill="none" stroke="#fff"
                                                            stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-miterlimit="10" stroke-width="1.631" />
                                                    </g>
                                                    <g transform="translate(6.31 0.8)">
                                                        <line y2="11.877" transform="translate(3.959)" stroke-width="1.631"
                                                            stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-miterlimit="10" fill="none" />
                                                        <path d="M16.718,10.5l-3.959,4.347L8.8,10.5"
                                                            transform="translate(-8.8 -2.97)" fill="none" stroke="#fff"
                                                            stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-miterlimit="10" stroke-width="1.631" />
                                                    </g>
                                                </g>
                                            </svg>
                                            {{ $t('downloads.download') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4 d-none d-lg-block detail-btn-area">
                                <div class="col-12 p-0 d-lg-flex">
                                    <div class="col-12 col-lg-6 px-0 right-border " role="table">
                                        <table class="table table-borderless content-table d-none d-lg-table"
                                            role="rowgroup">
                                            <caption tabindex="0" class="voice-guide">{{
                                                    $t('contents.aboutTheWork')
                                                }}
                                            </caption>
                                            <thead>
                                            <tr>
                                                <th tabindex="0" colspan="2" class="bg-light table-head w-50"
                                                    role="columnheader">
                                                    {{ $t('contents.aboutTheWork') }}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold adjust-pt" role="rowheader">
                                                    ID
                                                </td>
                                                <td tabindex="0" class="adjust-pt" role="cell">{{ content.id }}
                                                    <img tabindex="0" alt="" v-if="content.attributes.image_type === 'searchConditions.imageTypes.vr'
                                || content.attributes.video_type === 'searchConditions.videoTypes.vr'"
                                                        class="ico_movie_picture mb-adjust"
                                                        src="@/assets/icons/ico_vr.svg">
                                                    <img tabindex="0" alt="" v-else-if="content.attributes.content_type === 'video'
                                && content.attributes.video_type !== 'searchConditions.videoTypes.vr'"
                                                        class="ico_movie_picture mb-adjust"
                                                        src="@/assets/icons/ico_movie_play.svg">
                                                    <img tabindex="0" alt="" v-else-if="content.attributes.content_type === 'image'
                                && content.attributes.image_type !== 'searchConditions.imageTypes.vr'"
                                                        class="ico_movie_picture"
                                                        src="@/assets/icons/ico_picture.svg">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">{{
                                                        $t('contents.title')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="" role="cell">
                                                    {{ content.relationships.details[this.$i18n.locale].title }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">{{
                                                        $t('contents.subtitle')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="" role="cell">
                                                    {{ content.relationships.details[this.$i18n.locale].subtitle }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader"
                                                    v-if="content.attributes.content_type === 'image'">{{
                                                        $t('contents.photographed')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader"
                                                    v-if="content.attributes.content_type === 'video'">{{
                                                        $t('contents.filmed')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="" role="cell">
                                                    {{ content.attributes.photographed_at | dateFormat }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">{{
                                                        $t('contents.licensing')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="" role="cell">
                                                    {{ $t(content.attributes.license_type) }}
                                                </td>
                                            </tr>
                                            <tr v-if="content.attributes.content_type === 'image'">
                                                <td tabindex="0" class="font-weight-bold adjust-pb" role="rowheader">{{
                                                        $t('contents.size')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="adjust-pb" role="cell">
                                                    {{ content.attributes.width }} x {{ content.attributes.height }}
                                                    px ({{ convertByteSize(content.attributes.size) }})
                                                </td>
                                            </tr>
                                            <tr v-if="content.attributes.content_type === 'video'">
                                                <td tabindex="0" class="font-weight-bold adjust-pb" role="rowheader">{{
                                                        $t('contents.codec')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="adjust-pb" role="cell">
                                                    {{ content.attributes.codec }}
                                                </td>
                                            </tr>
                                            <tr v-if="content.attributes.content_type === 'video'">
                                                <td tabindex="0" class="font-weight-bold adjust-pb" role="rowheader">{{
                                                        $t('contents.fps')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="adjust-pb" role="cell">
                                                    {{ content.attributes.fps }}
                                                </td>
                                            </tr>
                                            <tr v-if="content.attributes.content_type === 'video'">
                                                <td tabindex="0" class="font-weight-bold adjust-pb" role="rowheader">{{
                                                        $t('contents.duration')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="adjust-pb" role="cell">
                                                    {{ content.attributes.duration }}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="col-12 col-lg-6 px-0 border-left" role="table">
                                        <table class="table table-borderless content-table d-none d-lg-table"
                                            role="rowgroup">
                                            <caption tabindex="0" class="voice-guide">{{
                                                    $t('contents.aboutTheCulturalProperty')
                                                }}
                                            </caption>
                                            <thead>
                                            <tr>
                                                <th tabindex="0" colspan="2" class="bg-light table-head w-50"
                                                    role="columnheader">
                                                    {{ $t('contents.aboutTheCulturalProperty') }}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold adjust-pt" role="rowheader">{{
                                                        $t('contents.name')
                                                    }}
                                                </td>
                                                <td tabindex="0" role="cell" class="adjust-pt">{{
                                                        content.relationships.culturalProps[this.$i18n.locale].name
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">{{
                                                        $t('contents.designation')
                                                    }}
                                                </td>
                                                <td tabindex="0" role="cell"
                                                    v-if="content.relationships.culturalProps[this.$i18n.locale].specified_category">
                                                    {{
                                                        content.relationships.culturalProps[this.$i18n.locale].specified_category
                                                    }}
                                                </td>
                                                <td tabindex="0" role="cell" v-else>-</td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">{{
                                                        $t('contents.year')
                                                    }}
                                                </td>
                                                <td tabindex="0" role="cell"
                                                    v-if="content.relationships.culturalProps[this.$i18n.locale].ad">
                                                    {{ content.relationships.culturalProps[this.$i18n.locale].ad }}
                                                </td>
                                                <td tabindex="0" role="cell" v-else>-</td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">
                                                    {{ $t('contents.location') }}
                                                </td>
                                                <td tabindex="0" role="cell"
                                                    v-if="content.relationships.culturalProps[this.$i18n.locale].location">
                                                    {{
                                                        content.relationships.culturalProps[this.$i18n.locale].location
                                                    }}
                                                </td>
                                                <td tabindex="0" role="cell" v-else>-</td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" role="none" class="adjust-pb"></td>
                                                <td tabindex="0" role="none" class="adjust-pb"></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="col-12 px-0" role="table">
                                    <table class="detail-info table table-borderless content-table d-none d-lg-table"
                                        role="rowgroup">
                                        <caption class="voice-guide">{{ $t('contents.content') }}</caption>
                                        <thead>
                                        <tr>
                                            <th tabindex="0" class="bg-light right-border table-head w-100"
                                                role="columnheader">
                                                {{ $t('contents.content') }}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th role="cell">
                                                <div id="detail-info-of-pc">
                                                    <div v-if="isDisplayAllContents">
                                                        <p tabindex="0"
                                                            v-html="content.relationships.details[this.$i18n.locale].content"></p>
                                                    </div>
                                                    <div v-else>
                                                        <p tabindex="0"
                                                            :class="{'overflow-hidden': isDisplayShowMoreButtonForPc}"
                                                            :style="{'max-height': [isDisplayShowMoreButtonForPc ?
                                                   strHeightForPc * 5 + 'px' : '']}"
                                                            v-html="content.relationships.details[this.$i18n.locale].content">
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="text-center">
                                                    <button v-if="!isDisplayAllContents && isDisplayShowMoreButtonForPc"
                                                        tabindex="0"
                                                        @click="showMoreButtonHasClicked"
                                                        class="btn btn-outline-dark">{{ $t('contents.readMore') }}
                                                    </button>
                                                </div>
                                            </th>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row d-block d-lg-none">
                                <div class="p-0 col-12 mt-4" role="table">
                                    <CollapseCard id="aboutTheWork" :title="$t('contents.aboutTheWork')">
                                        <table class="table table-borderless sp-content-table" role="rowgroup">
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">ID</td>
                                                <td tabindex="0" class="right-border" role="cell">{{ content.id }}
                                                    <img tabindex="0" v-if="content.attributes.content_type === 'video'"
                                                        class="ico_movie_picture"
                                                        src="@/assets/icons/ico_movie_play.svg" alt="">
                                                    <img tabindex="0" v-else class="ico_movie_picture"
                                                        src="@/assets/icons/ico_picture.svg" alt="">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">{{
                                                        $t('contents.title')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="right-border" role="cell">
                                                    {{ content.relationships.details[this.$i18n.locale].title }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader"
                                                    v-if="content.attributes.content_type === 'image'">{{
                                                        $t('contents.photographed')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader"
                                                    v-if="content.attributes.content_type === 'video'">{{
                                                        $t('contents.filmed')
                                                    }}
                                                </td>

                                                <td tabindex="0" class="right-border" role="cell">
                                                    {{ content.attributes.photographed_at | dateFormat }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">
                                                    {{ $t('contents.licensing') }}
                                                </td>
                                                <td tabindex="0" class="right-border" role="cell">
                                                    {{ $t(content.attributes.license_type) }}
                                                </td>
                                            </tr>
                                            <tr v-if="content.attributes.content_type === 'image'">
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">{{
                                                        $t('contents.size')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="right-border" role="cell">
                                                    {{ content.attributes.width }} x {{ content.attributes.height }}
                                                    px ({{ convertByteSize(content.attributes.size) }})
                                                </td>
                                            </tr>
                                            <tr v-if="content.attributes.content_type === 'video'">
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">{{
                                                        $t('contents.codec')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="right-border" role="cell">
                                                    {{ content.attributes.codec }}
                                                </td>
                                            </tr>
                                            <tr v-if="content.attributes.content_type === 'video'">
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">{{
                                                        $t('contents.fps')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="right-border" role="cell">
                                                    {{ content.attributes.fps }}
                                                </td>
                                            </tr>
                                            <tr v-if="content.attributes.content_type === 'video'">
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">{{
                                                        $t('contents.duration')
                                                    }}
                                                </td>
                                                <td tabindex="0" class="right-border" role="cell">
                                                    {{ content.attributes.duration }}
                                                </td>
                                            </tr>
                                        </table>
                                    </CollapseCard>
                                    <CollapseCard id="aboutTheCulturalProperty"
                                        :title="$t('contents.aboutTheCulturalProperty')">
                                        <table class="table table-borderless sp-content-table" role="rowgroup">
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">{{
                                                        $t('contents.name')
                                                    }}
                                                </td>
                                                <td tabindex="0" role="cell">
                                                    {{ content.relationships.culturalProps[this.$i18n.locale].name }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">
                                                    {{ $t('contents.designation') }}
                                                </td>
                                                <td tabindex="0" role="cell"
                                                    v-if="content.relationships.culturalProps[this.$i18n.locale].specified_category">
                                                    {{
                                                        content.relationships
                                                            .culturalProps[this.$i18n.locale].specified_category
                                                    }}
                                                </td>
                                                <td tabindex="0" role="cell" v-else>-</td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">{{
                                                        $t('contents.year')
                                                    }}
                                                </td>
                                                <td tabindex="0" role="cell"
                                                    v-if="content.relationships.culturalProps[this.$i18n.locale].ad">
                                                    {{ content.relationships.culturalProps[this.$i18n.locale].ad }}
                                                </td>
                                                <td tabindex="0" role="cell" v-else>-</td>
                                            </tr>
                                            <tr>
                                                <td tabindex="0" class="font-weight-bold" role="rowheader">
                                                    {{ $t('contents.location') }}
                                                </td>
                                                <td tabindex="0" role="cell"
                                                    v-if="content.relationships.culturalProps[this.$i18n.locale].location">
                                                    {{
                                                        content.relationships.culturalProps[this.$i18n.locale].location
                                                    }}
                                                </td>
                                                <td tabindex="0" role="cell" v-else>-</td>
                                            </tr>
                                        </table>
                                    </CollapseCard>
                                    <CollapseCard id="content"
                                        :title="$t('contents.content')">
                                        <div id="detail-info-of-sp">
                                            <div v-if="isDisplayAllContents">
                                                <p tabindex="0"
                                                    v-html="content.relationships.details[this.$i18n.locale].content"></p>
                                            </div>
                                            <div v-else>
                                                <p :class="{'overflow-hidden': isDisplayShowMoreButtonForSp}"
                                                    :style="{'max-height': [isDisplayShowMoreButtonForSp ?
                                                       strHeightForPc * 5.5 + 'px' : '']}"
                                                    v-html="content.relationships.details[this.$i18n.locale].content">
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-12 text-center">
                                            <button v-if="!isDisplayAllContents && isDisplayShowMoreButtonForSp"
                                                tabindex="0"
                                                @click="showMoreButtonHasClicked"
                                                class="btn btn-outline-dark">{{ $t('contents.readMore') }}
                                            </button>
                                        </div>
                                    </CollapseCard>
                                </div>
                            </div>
                        </div>
                        <div class="d-none d-lg-block col-lg-4 bg-light left-border side-area">
                            <p tabindex="0" class="mb-2 select-size-text">{{ $t('downloads.chooseSize') }}</p>
                            <download-panel :content="content"></download-panel>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 p-0">
                    <hr class="d-none d-lg-block m-0 p-0">
                </div>
            </div>
            <div class="row mt-4 wrapper-related-contents">
                <div class="col-12">
                    <related-contents ref="relatedContents"></related-contents>
                </div>
            </div>

            <div class="row mt-0 mt-lg-4">
                <div class="col-12 p-0">
                    <related-keywords :content="content"></related-keywords>
                </div>
            </div>

            <div class="row mt-4" v-if="existViewingHistories">
                <div class="col-12">
                    <viewing-histories ref="viewingHistories"></viewing-histories>
                </div>
            </div>
            <download-panel-modal ref="downloadPanelModal"></download-panel-modal>
        </div>
    </div>
</template>

<script>
import DownloadPanel from "../../components/DownloadPanel/DownloadPanel";
import ImagePreviewModal from "../../components/ImagePreviewModal/ImagePreviewModal";
import FavoriteKit from "../../components/Helper/FavoriteKit";
import Helper from "../../components/Helper/Helper";
import ApiRouter from "../../components/Systems/ApiRouter";
import ViewingHistoryKit from "../../components/Helper/ViewingHistoryKit";
import RelatedKeywords from "../../components/RelatedKeywords/RelatedKeywords";
import ViewingHistories from "../../components/ViewingHistories/ViewingHistories";
import RelatedContents from "../../components/RelatedContents/RelatedContents";
import SEOKit from "../../components/Systems/SEOKit";
import CollapseCard from "../../components/CollapseCard/CollapseCard";
import DownloadPanelModal from "../../components/DownloadPanel/DownloadPanelModal";
import {Pano} from 'vuejs-vr';

export default {
    name: "ContentDetail",
    mixins: [FavoriteKit, Helper, ApiRouter, ViewingHistoryKit, SEOKit],
    components: {
        DownloadPanelModal, CollapseCard, RelatedContents,
        ViewingHistories, RelatedKeywords, ImagePreviewModal, DownloadPanel, Pano
    },
    data: function () {
        return {
            pageTitle: '',
            content: '',
            strHeightForPc: '',
            strHeightForSp: '',
            isDisplayAllContents: false,
            isDisplayShowMoreButtonForPc: false,
            isDisplayShowMoreButtonForSp: false,
            jsonld: {
                name: '',
                datePublished: '2020/03/16',
                image: '',
                articleSection: '',
                articleBody: '',
                url: '',
            }
        }
    },
    jsonld: function () {
        return {
            "@context": "http://schema.org/",
            "@type": "Article",
            "name": this.jsonld.name,//都度変更される箇所
            "author": {
                "@type": "Organization",
                "name": "文化庁 AGENCY FOR CULTURAL AFFAIRS"
            },
            "datePublished": this.jsonld.datePublished,//都度変更される箇所
            "image": this.jsonld.image,//都度変更される箇所
            "articleSection": this.jsonld.articleSection,//都度変更される箇所
            "articleBody": this.jsonld.articleBody,//都度変更される箇所
            "url": window.location.href,//都度変更される箇所
            "publisher": {
                "@type": "Organization",
                "name": "文化庁 AGENCY FOR CULTURAL AFFAIRS"
            }
        }
    },
    created: function () {
        this.loadData(this.$route.params.id);
    },
    updated: function () {
        this.displayShowMoreButtonForPc();
        this.displayShowMoreButtonForSp();
        if (!this.isDisplayAllContents) {
            this.focusElement();
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.initData();
        this.$refs.relatedContents.initData();
        this.$refs.viewingHistories.initData();
        this.loadData(to.params.id, function () {
            next();
        });
    },
    methods: {
        loadData: function (id, callback = null) {
            this.$root.caches.relatedContents = [];
            window.axios.get(this.routes.content(id))
                .then((response) => {
                    this.content = response.data.data;
                    this.updateSeoInfoByContent(this.content);
                    this.contentHasAdded();
                    this.addToViewingHistory(this.content);
                    this.pageTitle = this.content.relationships.details[this.$i18n.locale].title;
                    this.getRelateData();
                    this.initJsonld();

                    if (callback) {
                        callback();
                    }
                });
        },
        initData: function () {
            this.strHeightForPc = '';
            this.strHeightForSp = '';
            this.isDisplayAllContents = false;
            this.isDisplayShowMoreButtonForPc = false;
            this.isDisplayShowMoreButtonForSp = false;
        },
        initJsonld: function () {
            this.jsonld.name = this.content.relationships.details[this.$i18n.locale].title;
            this.jsonld.datePublished = this.content.created_at;
            this.jsonld.image = this.content.attributes.thumbnail_url;
            this.jsonld.articleSection = this.content.relationships.details[this.$i18n.locale].subtitle;
            this.jsonld.articleBody = this.content.relationships.details[this.$i18n.locale].content;
        },
        getRelateData: function () {
            this.$root.caches.relatedContents = [];
            const keyword = this.content.relationships.details[this.$i18n.locale].keywords.join(' ');
            this.setSearchContents(keyword);
            this.setSearchContents(this.content.relationships.details[this.$i18n.locale].title);
            this.setSearchContents(this.content.relationships.details[this.$i18n.locale].subtitle);
        },
        setSearchContents: function (keyword) {
            window.axios.get(this.routes.contents, {
                params: {
                    keyword: keyword
                }
            })
                .then((response) => {
                    const tmp = [...this.$root.caches.relatedContents, ...response.data.data]
                        .reduce((obj, it) => {
                            obj[it.id] = it;
                            return obj;
                        }, {});
                    this.$root.caches.relatedContents = Object.values(tmp);
                });
        },
        displayShowMoreButtonForPc: function () {
            this.strHeightForPc = parseInt(window.getComputedStyle(document.getElementById('detail-info-of-pc')).lineHeight);
            const height = document.getElementById('detail-info-of-pc').clientHeight;

            if (this.strHeightForPc * 5 < height) {
                this.isDisplayShowMoreButtonForPc = true;
            }
        },
        displayShowMoreButtonForSp: function () {
            this.strHeightForSp = parseInt(window.getComputedStyle(document.getElementById('detail-info-of-sp')).lineHeight);
            const height = document.getElementById('detail-info-of-sp').clientHeight;

            if (this.strHeightForSp * 5 < height) {
                this.isDisplayShowMoreButtonForSp = true;
            }
        },
        showMoreButtonHasClicked: function () {
            this.isDisplayAllContents = !this.isDisplayAllContents;
        },
        focusElement: function () {
            document.getElementById('topLink').focus();
        }
    },
    computed: {
        existViewingHistories: function () {
            return Object.keys(this.$root.caches.viewingHistoryOfContents).length > 0;
        },
        langUpdated: function () {
            return this.$store.state.langUpdated
        }
    },
    watch: {
        langUpdated: function (newValue) {
            if (newValue) {
                this.updateSeoInfoByContent(this.content);
                this.$store.commit('endOfUpdateLang');
            }
        }
    }
}
</script>

<style src="./ContentDetail.scss" lang="scss" scoped />
