<template>
    <div class="related-keywords" v-if="content">
        <div class="jumbotron">
            <div class="container">
                <div class="row">
                    <div class="d-flex align-items-center justify-content-center col-lg-2 px-lg-0">
                    <div class="row mr-1 mr-lg-0 align-items-center flex-lg-column">
                        <h5 tabindex="0">{{ $t('contents.relatedTerms') }}</h5>
                    </div>
                </div>
                    <div class="col-lg-7 mt-4 mt-lg-0">
                    <div class="row">
                        <div class="col-6 col-md-4 col-lg-3"
                             v-for="(keyword, index) in content.relationships.details[$i18n.locale].keywords"
                             :key="index">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox"
                                       tabindex="0"
                                       class="custom-control-input"
                                       role="checkbox"
                                       aria-checked="true"
                                       :value="keyword"
                                       :id="keyword + index"
                                       v-model="selectedKeywords">
                                <label class="custom-control-label" :for="keyword + index">
                                    <a href="javascript:void(0)"
                                       tabindex="0"
                                       @click="searchByKeyword(keyword)">{{ keyword }}</a>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="col-lg-3 mt-4 mt-lg-0">
                    <div class="d-flex justify-content-center align-items-center h-100">
                        <button class="btn btn-dark btn-selected-keyword"
                                tabindex="0"
                                @click="searchByMultiKeywords()">
                            {{ $t('buttons.keywordSearch') }}
                        </button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RelatedKeywords",
        props: ['content'],
        data: function () {
            return {
                selectedKeywords: []
            }
        },
        methods: {
            searchByKeyword: function (keyword) {
                this.$router.push({
                    name: 'Contents',
                    query: {
                        keyword: keyword
                    }
                });
            },
            searchByMultiKeywords: function () {
                const keyword = this.selectedKeywords.join(' ');
                this.$router.push({
                    name: 'Contents',
                    query: {
                        keyword: keyword
                    }
                });
            }
        }
    }
</script>

<style src="./RelatedKeywords.scss" lang="scss" scoped />
