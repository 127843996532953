<template>
    <div>
        <div class="container sm-bg pr-xl-3 px-lg-0">
            <div class="row">
                <div class="col-12 col-md-8 px-0 px-md-3">
                    <ul class="breadcrumb-list" aria-label="パンくずリスト">
                        <li>
                            <router-link :to="{name: 'Top'}" tabindex="0" :aria-label="$t('breadcrumbs.top')">
                                <span class="step">{{ $t('siteInfo.top') }}</span>
                            </router-link>
                        </li>
                        <li>
                            <span tabindex="0" aria-current="page">{{ $t('siteInfo.contents') }}</span>
                            <div class="d-inline" v-if="$root.caches.searchedKeyword !== ''">
                                <span class="breadcrumb-keywords"> {{ $root.caches.searchedKeyword }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="search-result col-12 col-md-4 text-right d-flex justify-content-end align-items-center">
                    <span class="word">
                        {{ $t('numberOfHits.searchResults') }}
                    </span>
                    <span class="number" v-if="$root.caches.contentCollection.meta">
                        {{ $root.caches.contentCollection.meta.total }}{{ $t('numberOfHits.results') }}
                    </span>
                    <span class="number" v-else>
                        {{ $t('numberOfHits.results') }}
                    </span>
                </div>
            </div>
            <tool-bar @display-style-button-clicked="changeDisplayStyle"></tool-bar>
            <pagination class="pagenation-wrapper01 mb-md-4 mt-md-4" v-if="$root.caches.contentCollection.meta" :meta="$root.caches.contentCollection.meta"></pagination>
        </div>
        <div class="container-fluid p-0 p-md-2 sm-bg">
            <transition name="fade" mode="out-in">
                <div v-if="$root.caches.contentCollection.data.length > 0">
                    <content-collection
                        v-if="useCollectionView && collectionViewSize === 6"
                        size="6">
                    </content-collection>
                    <content-collection
                        v-else-if="useCollectionView && collectionViewSize === 8"
                        size="8">
                    </content-collection>
                    <content-list v-else></content-list>
                </div>
                <div v-if="$root.caches.contentCollection.data.length === 0 && $root.searching === false">
                    <p class="no-results">{{ $t('contents.noResults') }}</p>
                </div>
            </transition>
        </div>
        <div class="container sm-bg">
            <pagination class="pagenation-wrapper02 mb-md-5" v-if="$root.caches.contentCollection.meta" :meta="$root.caches.contentCollection.meta"></pagination>
        </div>
        <div class="d-flex justify-content-center align-items-center loader"
             v-if="$root.caches.contentCollection.data.length === 0 && $root.searching === true">
            <p class="loading-text">{{ $t('contents.loading') }}</p>
        </div>
    </div>
</template>

<script>
    import ContentCollection from "../../components/ContentCollection/ContentCollection";
    import ToolBar from "../../components/ToolBar/ToolBar";
    import ContentList from "../../components/ContentList/ContentList";
    import Pagination from "../../components/Pagination/Pagination";
    export default {
        name: "Contents",
        components: {Pagination, ContentList, ToolBar, ContentCollection},
        metaInfo: function() {
            return {
                title: this.$t('siteInfo.contents'),
                titleTemplate: `%s | ${this.$t('siteInfo.title')}`,
                meta: [
                    {
                        vmid: 'og-title',
                        property: 'og:title',
                        content: this.title,
                    }
                ]
            }
        },
        data: function () {
            return {
                useCollectionView: true,
                collectionViewSize: 8
            }
        },
        beforeRouteEnter (to, from, next) {
            const keyword = to.query.keyword;
            next(vm => {
                if (keyword) {
                    vm.$root.searchConditions.keyword = keyword;
                }
                if (vm.$store.state.historyState !== window.history.state.key) {
                    vm.$root.searchContents();
                }
                vm.$store.commit('updateHistoryState');
            });
        },
        methods: {
            changeDisplayStyle: function ($event) {
                switch ($event) {
                    case 'largeCollection':
                        this.useCollectionView = true;
                        this.collectionViewSize = 6;
                        break;
                    case 'smallCollection':
                        this.useCollectionView = true;
                        this.collectionViewSize = 8;
                        break;
                    case 'list':
                        this.useCollectionView = false;
                }
            }
        }
    }
</script>

<style src="./Contents.scss" lang="scss" scoped />
