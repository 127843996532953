<template>
    <div class="card content-card"
         v-if="content"
         tabindex="0"
         role="group"
         @keyup.enter.self="contentCardHasClicked(content)"
         @keyup.space.self="contentCardHasClicked(content)"
         :aria-label="(content.relationships.details[this.$i18n.locale].title) + $t('accessibility.contentCard')">
        <div class="embed-responsive embed-responsive-4by3"
             tabindex="0"
             role="link"
             @keyup.enter="contentCardHasClicked(content)"
             @keyup.space="contentCardHasClicked(content)"
             @mouseover="selectVideo(content)"
             @mouseleave="selectedVideo = ''"
             @click="contentCardHasClicked(content)">
            <div v-if="!imageExist" class="embed-responsive-item text-center img-object">
                <p>既に掲載が終了しています</p>
            </div>
            <div v-if="content.attributes.content_type === 'video'
                    && content.attributes.video_type !== 'searchConditions.videoTypes.vr'
                    && imageExist"
                    v-html="videoPreview(content)"
                    class="embed-responsive-item text-center img-object"
                    :aria-label="(content.relationships.details[this.$i18n.locale].title) +
                     $t('accessibility.contentImage')"></div>
            <div v-else class="embed-responsive-item text-center img-object">
                <img :src="content.attributes.thumbnail_url"
                     class="img-top"
                     :alt="(content.relationships.details[this.$i18n.locale].title) +
                         $t('accessibility.contentImage')">
            </div>
        </div>
        <div class="card-body">
            <div class="info-area">
                <p class="card-subtitle text-secondary mb-2 mb-md-1"
                   role="note"
                   :aria-label="$t('accessibility.contentId')"
                   @click="contentCardHasClicked(content)"
                   @keyup.enter="contentCardHasClicked(content)"
                   @keyup.space="contentCardHasClicked(content)">
                    <img tabindex="0" alt="" v-if="content.attributes.image_type === 'searchConditions.imageTypes.vr'
                                || content.attributes.video_type === 'searchConditions.videoTypes.vr'"
                         class="ico_movie_picture mb-adjust"
                         src="@/assets/icons/ico_vr.svg">
                    <img tabindex="0" alt="" v-else-if="content.attributes.content_type === 'video'
                                && content.attributes.video_type !== 'searchConditions.videoTypes.vr'"
                         class="ico_movie_picture mb-adjust"
                         src="@/assets/icons/ico_movie_play.svg">
                    <img tabindex="0" alt="" v-else-if="content.attributes.content_type === 'image'
                                && content.attributes.image_type !== 'searchConditions.imageTypes.vr'"
                         class="ico_movie_picture"
                         src="@/assets/icons/ico_picture.svg">
                </p>
                <p class="card-title font-weight-bold"
                   @click="contentCardHasClicked(content)"
                   @keyup.enter="contentCardHasClicked(content)"
                   @keyup.space="contentCardHasClicked(content)"
                   tabindex="0">
                    {{ content.relationships.details[this.$i18n.locale].title }}
                </p>
                <p class="card-subtitle font-weight-normal"
                   @click="contentCardHasClicked(content)"
                   @keyup.enter="contentCardHasClicked(content)"
                   @keyup.space="contentCardHasClicked(content)"
                   tabindex="0">
                    {{ content.relationships.details[this.$i18n.locale].subtitle }}
                </p>
            </div>
            <div class="btn-area">
                <button v-if="enableAddToFavorites"
                    @click="addToFavorites(content)"
                    class="btn btn-light btn-sm"
                    :aria-label="$t('accessibility.addToFavorites')"
                    tabindex="0">
                    <svg class="ico_add" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                        <g transform="translate(0 0)">
                            <text transform="translate(0 11)" fill="#696969" font-size="12" font-family="HiraginoSans-W3, Hiragino Sans"><tspan x="0" y="0">＋</tspan></text>
                        </g>
                    </svg> {{ $t('contents.addToFavorites') }}
                </button>
                <button v-if="!enableAddToFavorites"
                    @click="removeFromFavorites(content.id)"
                    class="btn btn-light btn-sm"
                    :aria-label="$t('accessibility.removeFromFavorites')"
                    tabindex="0">
                    {{ $t('contents.removeFromFavorites') }}
                </button>
                <button class="btn btn-light btn-sm d-none d-md-inline btn-zoom"
                    v-if="content.attributes.content_type === 'image'"
                    @click="$emit('zoom-button-has-clicked', content)"
                    :aria-label="$t('accessibility.zoomContent')"
                    tabindex="0">
                    <svg class="ico_zoom" xmlns="http://www.w3.org/2000/svg" width="16.905" height="16.905" viewBox="0 0 16.905 16.905">
                        <g transform="translate(0.375 0.375)">
                            <circle cx="5.965" cy="5.965" r="5.965" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.75"/>
                            <line x2="5.5" y2="5.5" transform="translate(10.5 10.5)" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" fill="none"/>
                        </g>
                    </svg> {{ $t('contents.zoom') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import FavoriteKit from "../Helper/FavoriteKit";
    export default {
        name: "ContentCard",
        mixins: [FavoriteKit],
        props: ['content'],
        created: function () {
            this.contentHasAdded();
            this.checkImage();
        },
        data: function () {
            return {
                selectedVideo: "",
                imageExist: false,
            }
        },
        methods: {
            contentCardHasClicked: function (content) {
                this.$router.push({name: 'ContentDetail', params: {id: content.id}});
            },
            videoPreview: function (content) {
                if (this.selectedVideo === content.id) {
                    return content.attributes.video_path
                } else {
                    return "<img src='" + content.attributes.thumbnail_url + "' " +
                        "style=\"width: 100%; display: flex; align-items: center; justify-content: center;\">"
                }
            },
            selectVideo: function (content) {
                this.selectedVideo = content.id;
            },
            checkImage: function () {
                var image = new Image();

                image.src = this.content.attributes.thumbnail_url;

                image.onload = () => {
                    this.imageExist = true;
                }
            }
        }
    }
</script>

<style src="./ContentCard.scss" lang="scss" scoped />
