<template>
    <div class="default-layout">
        <voice-guide></voice-guide>
        <default-header></default-header>
        <search-panel class="d-md-block"></search-panel>
        <div class="container-fluid px-0">
            <router-view></router-view>
        </div>
        <footer-panel></footer-panel>
        <footer-panel-smart-phone :show-back-to-top-btn="true"></footer-panel-smart-phone>
    </div>
</template>

<script>
    import FooterPanel from "../../FooterPanel/FooterPanel";
    import SearchPanel from "../../SearchPanel/SearchPanel";
    import DefaultHeader from "../../DefaultHeader/DefaultHeader";
    import FooterPanelSmartPhone from "../../FooterPanelSmartPhone/FooterPanelSmartPhone";
    import VoiceGuide from "../../VoiceGuide/VoiceGuide";
    export default {
        name: "DefaultLayout",
        components: {VoiceGuide, FooterPanelSmartPhone, DefaultHeader, SearchPanel, FooterPanel}
    }
</script>

<style>
    .container-fluid.px-0 {
        position: relative;
    }
</style>
