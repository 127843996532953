<template>
    <main class="top" role="main">
        <div class="container">
            <div class="row info-text">
                <p tabindex="0" class="col-12 description-text" v-html="$t('messages.topInfo')"></p>
            </div>
            <div class="row info-button text-center">
                <router-link :to="{name: 'TermsOfUse'}"
                             role="button"
                             :aria-label="$t('accessibility.terms')"
                             tabindex="0" class="btn btn-light">
                    <span class="mr-3 btn-text">{{ $t('buttons.terms') }}</span>
                    <svg class="btn-how-to-use" xmlns="http://www.w3.org/2000/svg" width="8.368" height="15.465" viewBox="0 0 8.368 15.465">
                        <path d="M-3.418-21.266l-.467.5,6.067,6.494L-3.885-7.766l.467.5,6.533-7.007Z" transform="translate(4.569 21.998)" stroke-width="1"/>
                    </svg>
                </router-link>
            </div>
            <div class="row category-link">
                <div class="col-12 p-0">
                    <top-category-link></top-category-link>
                </div>
            </div>
            <div class="row category-link bg-width">
                <top-season-link></top-season-link>
            </div>
        </div>
    </main>
</template>

<script>
    import TopCategoryLink from "../../components/TopCategoryLink/TopCategoryLink";
    import TopSeasonLink from "@/components/TopSeasonLink/TopSeasonLink";
    export default {
        name: "Top",
        components: {TopSeasonLink, TopCategoryLink},
        metaInfo: function() {
            return {
                title: this.$t('siteInfo.top'),
                titleTemplate: `%s | ${this.$t('siteInfo.title')}`,
            }
        },
        created: function () {
            this.removeContentType();
        },
        methods: {
            removeContentType: function () {
                this.$root.searchConditions.isVr = false;
                this.$root.searchConditions.selectedContentTypes = [];
                this.$root.searchConditions.selectedImageTypes = [];
                this.$root.searchConditions.selectedVideoTypes = [];
            },
        }
    }
</script>

<style src="./Top.scss" lang="scss" scoped />
