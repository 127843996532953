<template>
   <div>
       <div class="modal fade"
            tabindex="-1"
            :aria-label="$t('accessibility.imageModal')"
            :aria-hidden="!isOpen"
            :aria-modal="isOpen"
            @click.self="close"
            :class="{show: isOpen}"
            :style="{display: isOpen ? 'flex' : 'none'}">
           <div class="modal-dialog modal-xl"
                role="dialog"
                tabindex="0"
                :aria-label="$t('accessibility.imageModal')">
               <div class="modal-content" role="document">
                   <div class="modal-header">
                       <button type="button"
                               tabindex="0"
                               @click="close"
                               class="close"
                               data-dismiss="modal"
                               :aria-label="$t('accessibility.closeModal')">
                           <span aria-hidden="true"><span class="text-close">{{ $t('contents.closePreview') }}</span><br><img alt="拡大イメージを閉じる" class="ico_close" src="../../assets/icons/ico_close_white.svg"></span>
                       </button>
                   </div>
                   <div class="modal-body">
                       <img class="modal-img" :src="imageUrl" :alt="$t('accessibility.contentImage')">
                   </div>
                   <div class="modal-footer">
                       <p class="modal-text">{{ content.id }}</p>
                       <p class="modal-text">{{ title }}</p>
                   </div>
               </div>
           </div>
       </div>
       <div class="modal-backdrop fade show" v-show="isOpen"></div>
   </div>
</template>

<script>
    export default {
        name: "ImagePreviewModal",
        data: function () {
            return {
                isOpen: false,
                imageUrl: null,
                title: null,
                content: {},
                lastFocusedElement: null
            }
        },
        updated: function () {
            this.focusElement();
        },
        methods: {
            open: function (content) {
                this.lastFocusedElement = document.activeElement;
                const body = document.getElementsByTagName('body')[0];
                body.classList.toggle('modal-open');
                this.content = content;
                this.title = content.relationships.details[this.$i18n.locale].title;
                this.imageUrl = content.attributes.detail_size_url;
                this.isOpen = true;
            },
            close: function () {
                const body = document.getElementsByTagName('body')[0];
                body.classList.remove('modal-open');
                this.isOpen = false;
                this.lastFocusedElement.focus();
            },
            focusElement: function () {
                if (this.isOpen) {
                    const modal = document.getElementsByClassName('zoomModal');
                    modal.focus();
                }
            }
        }
    }
</script>

<style src="./ImagePreviewModal.scss" lang="scss" scoped />
