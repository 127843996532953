<template>
    <div class="related-contents container" v-if="$root.caches.relatedContents.length > 0">
        <div class="row">
            <div class="col-12 p-0">
                <h3 class="title">{{ $t('contents.relatedContents') }}</h3>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-5 mt-2 d-none d-md-flex">
            <template v-for="(content, loop) in $root.caches.relatedContents">
                <div class="col mb-2 mb-md-4"
                     :key="content.id"
                     v-focus="loop === 5"
                     v-if="(loop < initDisplayCount || isDisplayAllContents) && loop <= 19">
                    <content-card :content="content"
                                  @zoom-button-has-clicked="$refs.imagePreviewModal.open(content)">

                    </content-card>
                </div>
            </template>
        </div>
        <div class="row d-block d-md-none">
            <div class="col-12 p-0">
                <div class="col mb-4"
                     v-for="(content, loop) in $root.caches.relatedContents"
                     :key="content.id">
                    <content-table :content="content"
                                   @zoom-button-has-clicked="$refs.imagePreviewModal.open(content)"
                                   v-focus="loop === 5"
                                   v-if="(loop < initDisplayCount || isDisplayAllContents) && loop <= 19">
                    </content-table>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-12 text-center" v-if="isDisplayShowMoreButton">
                <button @click="showMoreButtonHasClicked"
                        tabindex="200"
                        :aria-label="$t('accessibility.readMoreRelated')"
                        class="btn btn-outline-dark btn-sm btn-more-detail">{{ $t('contents.seeMore') }}</button>
            </div>
        </div>
        <image-preview-modal ref="imagePreviewModal"></image-preview-modal>
    </div>
</template>

<script>
    import ContentCard from "../ContentCard/ContentCard";
    import ImagePreviewModal from "../ImagePreviewModal/ImagePreviewModal";
    import ContentTable from "../ContentTable/ContentTable";
    export default {
        name: "RelatedContents",
        components: {ContentTable, ImagePreviewModal, ContentCard},
        data: function () {
            return {
                isDisplayAllContents: false,
                initDisplayCount: 5
            }
        },
        methods: {
            showMoreButtonHasClicked: function () {
                this.isDisplayAllContents = true;
            },
            initData: function () {
                this.isDisplayAllContents = false;
            },
        },
        directives: {
            focus: {
                inserted: function (el, binding) {
                    if (binding.value) {
                        el.focus()
                    }
                }
            }
        },
        computed: {
            isDisplayShowMoreButton: function () {
                return !this.isDisplayAllContents &&
                    Object.keys(this.$root.caches.relatedContents).length > this.initDisplayCount;
            }
        }
    }
</script>

<style src="./RelatedContents.scss" lang="scss" scoped/>
