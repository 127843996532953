<template>
    <div class="px-md-0 col-12">
        <ul class="breadcrumb-list container mx-md-3 mx-xl-auto row" aria-label="パンくずリスト">
            <li>
                <router-link tabindex="0" :to="{name: 'Top'}" :aria-label="$t('breadcrumbs.top')">
                    <span class="step">{{ $t('buttons.top') }}</span>
                </router-link>
            </li>
            <li>
                <span tabindex="0" aria-current="page">{{ $t('buttons.terms') }}</span>
            </li>
        </ul>
        <div class="container text-center">
            <h1 class="title" id="contents-area"><span tabindex="0">{{ $t('buttons.terms') }}</span></h1>
        </div>
        <div class="px-0">
            <div class="terms-of-use row">
                <div v-html="$t('staticPages.TermsOfUse')"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TermsOfUse",
        metaInfo: function() {
            return {
                // TODO ご利用方法の言語変数が出来次第差し替える
                title: this.$t('buttons.terms'),
                titleTemplate: `%s | ${this.$t('siteInfo.title')}`,
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
                    },
                    {
                        vmid: 'og-title',
                        property: 'og:title',
                        content: this.$t('buttons.terms'),
                    },
                    {
                        vmid: 'og-description',
                        property: 'og:description',
                        content: this.$t('staticPages.TermsOfUse'),
                    }
                ]
            }
        }
    }
</script>
