<script>
    const historiesKey = 'viewingHistories';
    const historiesMaxCount = 20;
    export default {
        name: "ViewingHistoryKit",
        methods: {
            addToViewingHistory: function (content) {
                this.$set(this.$root.caches.viewingHistoryOfContents, content.id, content);

                if (Object.keys(this.$root.caches.viewingHistoryOfContents).length > historiesMaxCount) {
                    this.deleteViewHistory();
                }

                const saveData = JSON.stringify(this.$root.caches.viewingHistoryOfContents);
                window.localStorage.setItem(historiesKey, saveData);
            },
            getViewingHistories: function () {
                const saveData = window.localStorage.getItem(historiesKey);
                const contents = JSON.parse(saveData);

                if (contents === null) {
                    return {};
                }

                return contents;
            },
            deleteViewHistory: function () {
                const firstKey = Object.keys(this.$root.caches.viewingHistoryOfContents).shift();
                this.$delete(this.$root.caches.viewingHistoryOfContents, firstKey);
            }
        }
    }
</script>
