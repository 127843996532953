<template>
    <div class="top-header-wrap">
        <header class="header">
            <div class="card bg-dark text-white selectedImage">
                <video class="d-none d-md-flex main-movie"
                       src="https://cb-contents.s3-ap-northeast-1.amazonaws.com/contents/assets/TOP/top_movie2020.mp4"
                       autoplay="autoplay" loop="loop" muted="muted"></video>
                <img data-v-7dc2c428="" src="../../assets/sp_top.jpg" alt="" class="sp-only sp-main-img">
                <div class="menu-button d-inline d-md-none">
                    <button class="btn btn-sm"
                            @click="sideBarSwitch"
                            aria-controls="navbarSupportedContent"
                            :aria-expanded="sideBar ? 'true' : 'false'"
                            tabindex="2"
                            :aria-label="$t('accessibility.openMenu')">
                        <img alt="スマホメニューを開く" class="sp_menu" src="../../assets/icons/ico_sp_menu_white.svg">
                    </button>
                </div>
                <div class="card-img-overlay">
                    <div class="header-content container p-sm-0">
                        <div class="main-component row">
                            <div class="col-10 col-md-7 col-lg-8">
                                <div class="row header-main-visual">
                                    <div class="col-12 p-0">
                                        <div class="logo-text d-flex align-items-center">
                                            <h1>
                                                <img src="../../assets/logo.svg" class="logo"
                                                     :alt="$t('accessibility.logo')" tabindex="1">
                                            </h1>
                                            <div>
                                                <p class="card-title" tabindex="2"
                                                   v-html="$t('messages.headerTitle')"></p>
                                                <p class="card-text" tabindex="4"
                                                   v-html="$t('messages.headerText')"></p>
                                            </div>
                                        </div>
                                        <div class="card-title-en">
                                            <p class="card-title"><span
                                                tabindex="3">CULTURAL<br>PROPERTIES<br>DIGITAL<br>CONTENT<br>DOWNLOAD<br>SITE</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 col-md-5 col-lg-4">
                                <nav class="header-links d-none d-md-inline-flex" role="navigation">
                                    <router-link id="common-menu" :to="{name: 'ViewingHistory'}" tabindex="6">
                                        {{ $t('buttons.viewingHistory') }}
                                    </router-link>
                                    <div class="line"></div>
                                    <router-link :to="{name: 'Favorites'}" tabindex="7">
                                        {{ $t('buttons.favorites') }}
                                    </router-link>
                                    <div class="line"></div>
                                    <a href="javascript:void(0)"
                                       :aria-label="$t('accessibility.switchEnglish')"
                                       tabindex="5"
                                       v-if="$route.params.lang !== 'en'"
                                       @click="$root.changeLocale('en')">
                                        ENGLISH
                                    </a>
                                    <a href="javascript:void(0)"
                                       :aria-label="$t('accessibility.switchJapanese')"
                                       tabindex="5"
                                       v-if="$route.params.lang !== 'ja'"
                                       @click="$root.changeLocale('ja')">
                                        日本語
                                    </a>
                                </nav>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <transition name="slide-fade">
                <div v-if="sideBar" class="side-menu d-block d-md-none" id="navbarSupportedContent">
                    <div class="close-area">

                        <div class="sp_btn_close text-center">
                            <button @click="sideBarSwitch"
                                    tabindex="5"
                                    class="btn btn-link text-dark px-0"
                                    :aria-label="$t('accessibility.closeMenu')">
                                <img alt="" class="sp_menu_close" src="../../assets/icons/ico_sp_menu_close.svg">
                            </button>
                        </div>
                    </div>
                    <footer-panel-smart-phone @link-has-clicked="sideBarSwitch"></footer-panel-smart-phone>
                </div>
            </transition>
        </header>

        <div class="header-search-area">
            <div class="search-area">
                <div class="search-drop-outer">
                    <div class="dropdown">
                        <button class="btn dropdown-toggle show" type="button"
                                id="dropdownMenuButton" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false"
                                @click="pulldownSwitch"
                                tabindex="7">
                            {{ $t('buttons.' + selectedPulldown) }}
                        </button>
                        <div :class="['dropdown-menu', contentTypePulldown ? 'show' : '']"
                             aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" @click="selectContentType('all')" tabindex="7">
                                {{ $t('buttons.all') }}
                            </a>
                            <a class="dropdown-item" @click="selectContentType('photos')" tabindex="7">
                                {{ $t('buttons.photos') }}
                            </a>
                            <a class="dropdown-item" @click="selectContentType('videos')" tabindex="7">
                                {{ $t('buttons.videos') }}
                            </a>
                            <a class="dropdown-item" @click="selectContentType('photosAndVideos')" tabindex="7">
                                {{ $t('buttons.photosAndVideos') }}
                            </a>
                            <a class="dropdown-item" @click="selectContentType('vr')" tabindex="7">
                                {{ $t('buttons.vr') }}
                            </a>
                            <a class="dropdown-item" @click="selectContentType('vrPhotos')" tabindex="7">
                                {{ $t('buttons.vrPhotos') }}
                            </a>
                            <a class="dropdown-item" @click="selectContentType('vrVideos')" tabindex="7">
                                {{ $t('buttons.vrVideos') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="search-input" v-click-outside="hideSuggest">
                    <input tabindex="8"
                           class="form-control"
                           v-model="$root.searchConditions.keyword"
                           :aria-label="$t('accessibility.keywordSearchForm')"
                           :placeholder="$t('accessibility.keywordSearch')"
                           @input="inputHasChanged"
                           @click="openSuggest"
                           @keydown.enter="setSuggest"
                           @keyup.up="moveSuggestUp"
                           @keyup.down="moveSuggestDown"
                           @compositionstart="composing=true"
                           @compositionend="composing=false">
                    <ul class="list-group" v-show="suggest">
                        <li tabindex="8" v-for="(suggestWord, index) in suggestWords.slice(0, 10)"
                            :key="index"
                            class="list-group-item"
                            :class="{ 'is-active': index === activeSuggestIndex }"
                            @click="selectSuggest(suggestWord.attributes.title)"
                            @keyup.enter="selectSuggest(suggestWord.attributes.title)"
                            @keyup.space="selectSuggest(suggestWord.attributes.title)"
                            @keyup.up="moveSuggestUp"
                            @keyup.down="moveSuggestDown"
                            @mouseover="setActiveSuggestIndex(index)"
                            @focus="setActiveSuggestIndex(index)">
                            {{ suggestWord.attributes.title }}
                        </li>
                    </ul>
                </div>
                <div class="search-icon-bg">
                    <button class="search-icon"
                            tabindex="8"
                            role="button"
                            :aria-label="$t('accessibility.searchButton')"
                            @click="searchButtonHasClicked"
                            @keyup.enter="searchButtonHasClicked"
                            @keyup.space="searchButtonHasClicked">
                        <svg class="ico_zoom top" xmlns="http://www.w3.org/2000/svg"
                             width="16.905" height="16.905" viewBox="0 0 16.905 16.905">
                            <g transform="translate(0.375 0.375)">
                                <circle cx="5.965" cy="5.965" r="5.965" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        stroke-miterlimit="10" stroke-width="1"/>
                                <line x2="5.5" y2="5.5" transform="translate(10.5 10.5)"
                                      stroke-linecap="round" stroke-linejoin="round"
                                      stroke-miterlimit="10" fill="none"/>
                            </g>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FooterPanelSmartPhone from "../FooterPanelSmartPhone/FooterPanelSmartPhone";
    import SearchEvent from "../Helper/SearchEvent";
    import ApiRouter from "../Systems/ApiRouter";
    import ClickOutside from 'vue-click-outside';

    export default {
        name: "TopHeader",
        mixins: [SearchEvent, ApiRouter],
        components: {FooterPanelSmartPhone},
        data: function () {
            return {
                suggestWords: [],
                suggest: false,
                activeSuggestIndex: -1,
                selectedImage: '',
                sideBar: false,
                contentTypePulldown: false,
                selectedPulldown: 'all',
            }
        },
        created: function () {
            this.randomTopImage();
        },
        methods: {
            randomTopImage: function () {
                const random = Math.floor(Math.random() * 3) + 1;
                this.selectedImage = `../${process.env.BASE_URL}/img/topimage0${random}.jpg`;
            },
            sideBarSwitch: function () {
                this.sideBar = !this.sideBar;
            },
            pulldownSwitch: function () {
                this.contentTypePulldown = !this.contentTypePulldown;
            },
            selectContentType: function ($value) {
                this.removeContentType();
                switch ($value) {
                    case 'photos':
                        this.$root.searchConditions.selectedContentTypes = ['image'];
                        break;
                    case 'videos':
                        this.$root.searchConditions.selectedContentTypes = ['video'];
                        break;
                    case 'vr':
                        this.$root.searchConditions.isVr = true;
                        break;
                    case 'vrPhotos':
                        this.$root.searchConditions.isVr = true;
                        this.$root.searchConditions.selectedImageTypes = ['searchConditions.imageTypes.vr'];
                        break;
                    case 'vrVideos':
                        this.$root.searchConditions.isVr = true;
                        this.$root.searchConditions.selectedVideoTypes = ['searchConditions.videoTypes.vr'];
                        break;
                    default:
                        this.$root.searchConditions.selectedContentTypes = ['image', 'video'];
                }
                this.$root.caches.selectedSearchPullDown = $value;
                this.selectedPulldown = $value;
                this.contentTypePulldown = false;
            },
            removeContentType: function () {
                this.$root.searchConditions.isVr = false;
                this.$root.searchConditions.selectedContentTypes = [];
                this.$root.searchConditions.selectedImageTypes = [];
                this.$root.searchConditions.selectedVideoTypes = [];
            },
            inputHasChanged: function ($event) {
                this.activeSuggestIndex = -1;

                window.axios.get(this.routes.keywords, {
                    params: {
                        keyword: $event.target.value,
                    }
                })
                    .then((response) => {
                        this.suggestWords = response.data.data.filter((element, index, self) =>
                            self.findIndex(e =>
                                e.attributes.title === element.attributes.title
                            ) === index
                        );
                        this.suggest = true;
                    });
            },
            setSuggest: function ($event) {
                if (this.activeSuggestIndex > -1) {
                    this.$root.searchConditions.keyword = this.suggestWords[this.activeSuggestIndex].attributes.title;
                }
                this.hideSuggest();
                this.searchButtonHasClicked($event);
            },
            selectSuggest: function ($event) {
                this.$root.searchConditions.keyword = $event;
                this.suggest = false;
                this.searchButtonHasClicked($event);
            },
            openSuggest: function () {
              this.suggest = true;
            },
            hideSuggest: function () {
                this.suggest = false;
            },
            moveSuggestDown: function () {
                if (!this.suggestWords) {
                    return;
                }
                if (this.activeSuggestIndex >= this.suggestWords.slice(0, 10).length -1) {
                    this.activeSuggestIndex = -1;
                    return;
                }
                this.activeSuggestIndex++;
            },
            moveSuggestUp: function () {
                if (this.activeSuggestIndex > 0) {
                    this.activeSuggestIndex--;
                } else if (this.activeSuggestIndex === 0 ) {
                    this.activeSuggestIndex = -1;
                } else {
                    this.activeSuggestIndex = this.suggestWords.slice(0, 10).length -1;
                }
            },
            setActiveSuggestIndex: function (index) {
                this.activeSuggestIndex = index;
            }
        },
        directives: {
            ClickOutside
        }
    }
</script>

<style src="./TopHeader.scss" lang="scss" scoped/>
