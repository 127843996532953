<template>
    <div class="container season">
        <h3 class="contents-title">{{ $t('messages.japaneseSeasons') }}</h3>
        <p class="contents-subtitle">{{ $t('messages.english.japaneseSeasons') }}</p>
        <ul class="cardblock-season">
            <li class="cardblock-card" :aria-label="$t('messages.seasons.spring') + $t('messages.english.easons.spring')">
                <a href="javascript:void(0)" tabindex="0" :aria-label="$t('accessibility.seasons.spring')"
                   @click="searchSeasons('春')"
                   @keyup.enter="searchSeasons('春')"
                   @keyup.space="searchSeasons('春')">
                    <p class="card-title">{{ $t('messages.seasons.spring') }}</p>
                    <p class="card-subtitle">{{ $t('messages.english.seasons.spring') }}</p>
                    <img src="@/assets/seasons_spring.jpg" alt="spring">
                </a>
            </li>
            <li class="cardblock-card" :aria-label="$t('messages.seasons.summer') + $t('messages.english.seasons.summer')">
                <a href="javascript:void(0)" tabindex="0" :aria-label="$t('accessibility.seasons.summer')"
                   @click="searchSeasons('夏')"
                   @keyup.enter="searchSeasons('夏')"
                   @keyup.space="searchSeasons('夏')">
                    <p class="card-title">{{ $t('messages.seasons.summer') }}</p>
                    <p class="card-subtitle">{{ $t('messages.english.seasons.summer') }}</p>
                    <img src="@/assets/seasons_summer.jpg" alt="summer">
                </a>
            </li>
            <li class="cardblock-card" :aria-label="$t('messages.seasons.autumn') + $t('messages.english.seasons.autumn')">
                <a href="javascript:void(0)" tabindex="0" :aria-label="$t('accessibility.seasons.autumn')"
                   @click="searchSeasons('秋')"
                   @keyup.enter="searchSeasons('秋')"
                   @keyup.space="searchSeasons('秋')">
                    <p class="card-title">{{ $t('messages.seasons.autumn') }}</p>
                    <p class="card-subtitle">{{ $t('messages.english.seasons.autumn') }}</p>
                    <img src="@/assets/seasons_autumn.jpg" alt="autumn">
                </a>
            </li>
            <li class="cardblock-card" :aria-label="$t('messages.seasons.winter') + $t('messages.english.seasons.winter')">
                <a href="javascript:void(0)" tabindex="0" :aria-label="$t('accessibility.seasons.winter')"
                   @click="searchSeasons('冬')"
                   @keyup.enter="searchSeasons('冬')"
                   @keyup.space="searchSeasons('冬')">
                    <p class="card-title">{{ $t('messages.seasons.winter') }}</p>
                    <p class="card-subtitle">{{ $t('messages.english.seasons.winter') }}</p>
                    <img src="@/assets/seasons_winter.jpg" alt="winter">
                </a>
            </li>

        </ul>
    </div>
</template>

<script>
export default {
    name: "TopSeasonLink",
    methods: {
        searchSeasons: function (name) {
            this.removeAllCondition();
            const season = this.$root.caches.categories.seasons.find(season =>
                season.attributes.name.ja === name
            );
            this.$root.searchConditions.selectedSeasons.push(season.id);
            this.$router.push({name: 'Contents'}, () => {});
        },
        removeAllCondition: function () {
            this.$root.caches.selectedSearchPullDown = "all";
            this.$root.searchConditions.keyword = "";
            this.$root.searchConditions.selectedCategories = [];
            this.$root.searchConditions.selectedCulturalPropertyTypes = [];
            this.$root.searchConditions.selectedSeasons = [];
            this.$root.searchConditions.selectedRegions = [];
            this.$root.searchConditions.selectedPeople = [];
            this.$root.searchConditions.selectedPhotos = [];
            this.$root.searchConditions.selectedVideos = [];
            this.$root.searchConditions.selectedSizes = [];
            this.$root.searchConditions.selectedLicenses = [];
        },
    }
}
</script>

<style src="./TopSeasonLink.scss" lang="scss" scoped />
