require('./bootstrap');
import Vue from 'vue'
import Vuex from 'vuex'
import VueMeta from 'vue-meta';
import router from './Routes';
import i18n from './Localize';
import App from './App.vue'
import ApiRouter from "./components/Systems/ApiRouter";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import VueJsonLD from 'vue-jsonld';

require('./icons');

Vue.component('icon', FontAwesomeIcon);
Vue.use(VueMeta);

Vue.use(Vuex);
Vue.use(VueJsonLD);

const store = new Vuex.Store({
    state: {
        langUpdated: false,
        historyState: "",
    },
    mutations: {
        updateLang: function (state) {
            state.langUpdated = true;
        },
        endOfUpdateLang: function (state) {
            state.langUpdated = false;
        },
        updateHistoryState: function (state) {
            state.historyState = window.history.state.key;
        }
    }
});

Vue.config.productionTip = false;

new Vue({
    el: '#app',
    name: 'Root',
    mixins: [ApiRouter],
    router,
    store,
    i18n,
    data: {
        caches: {
            categories: [],
            contentCollection: {
                data: []
            },
            favoriteContents: {},
            viewingHistoryOfContents: {},
            relatedContents: [],
            searchedKeyword: "",
            selectedSearchPullDown: "all",
        },
        searchConditions: {
            keyword: "",
            sort: "created_at",
            limit: 50,
            selectedContentTypes: [],
            selectedCategories: [],
            selectedCulturalPropertyTypes: [],
            selectedSeasons: [],
            selectedRegions: [],
            selectedPeople: [],
            selectedPhotos: [],
            selectedVideos: [],
            selectedSizes: [],
            selectedImageTypes: [],
            selectedVideoTypes: [],
            selectedLicenses: [],
            isVr: false
        },
        searching: false
    },
    methods: {
        searchContents: function () {
            this.searching = true;
            this.caches.searchedKeyword = "";
            this.caches.contentCollection.data = [];
            const seasons = [];
            const culturalPropertyTypes = [];
            const regions = [];
            const self = this;

            if (this.searchConditions.selectedSeasons.length > 0) {
                this.searchConditions.selectedSeasons.forEach(function (season) {
                    seasons.push(self.$t(season));
                });
            }

            if (this.searchConditions.selectedCulturalPropertyTypes.length > 0) {
                this.searchConditions.selectedCulturalPropertyTypes.forEach(function (culturalPropertyType) {
                    culturalPropertyTypes.push(self.$t(culturalPropertyType));
                });
            }

            if (this.searchConditions.selectedRegions.length > 0) {
                this.searchConditions.selectedRegions.forEach(function (region) {
                    regions.push(self.$t(region));
                });
            }

            window.axios.get(this.routes.contents, {
                params: {
                    keyword: this.searchConditions.keyword,
                    sort: this.searchConditions.sort,
                    limit: this.searchConditions.limit,
                    filter: {
                        content_type: this.searchConditions.selectedContentTypes,
                        category_id: this.searchConditions.selectedCategories,
                        specified_category: culturalPropertyTypes,
                        season: seasons,
                        location: regions,
                        human_exist: this.searchConditions.selectedPeople,
                        direction: this.searchConditions.selectedPhotos,
                        duration: this.searchConditions.selectedVideos,
                        frame_size: this.searchConditions.selectedSizes,
                        image_type: this.searchConditions.selectedImageTypes,
                        video_type: this.searchConditions.selectedVideoTypes,
                        license_type: this.searchConditions.selectedLicenses,
                        is_vr: this.searchConditions.isVr

                    }
                }
            })
                .then((response) => {
                    this.caches.contentCollection = response.data;
                    this.caches.searchedKeyword = this.searchConditions.keyword;
                })
                .finally(() => {
                    this.searching = false;
                });
        },
        searchContentsByPage: function (page) {
            this.searching = true;
            const seasons = [];
            const culturalPropertyTypes = [];
            const regions = [];
            const self = this;

            if (this.searchConditions.selectedSeasons.length > 0) {
                this.searchConditions.selectedSeasons.forEach(function (season) {
                    seasons.push(self.$t(season));
                });
            }

            if (this.searchConditions.selectedCulturalPropertyTypes.length > 0) {

                const self = this;
                this.searchConditions.selectedCulturalPropertyTypes.forEach(function (culturalPropertyType) {
                    culturalPropertyTypes.push(self.$t(culturalPropertyType));
                });
            }

            if (this.searchConditions.selectedRegions.length > 0) {
                const self = this;
                this.searchConditions.selectedRegions.forEach(function (region) {
                    regions.push(self.$t(region));
                });
            }

            window.axios.get(this.routes.contents, {
                params: {
                    page: page,
                    keyword: this.searchConditions.keyword,
                    sort: this.searchConditions.sort,
                    limit: this.searchConditions.limit,
                    filter: {
                        content_type: this.searchConditions.selectedContentTypes,
                        category_id: this.searchConditions.selectedCategories,
                        specified_category: culturalPropertyTypes,
                        season: seasons,
                        location: regions,
                        human_exist: this.searchConditions.selectedPeople,
                        direction: this.searchConditions.selectedPhotos,
                        duration: this.searchConditions.selectedVideos,
                        frame_size: this.searchConditions.selectedSizes,
                        image_type: this.searchConditions.selectedImageTypes,
                        video_type: this.searchConditions.selectedVideoTypes,
                        license_type: this.searchConditions.selectedLicenses,
                        is_vr: this.searchConditions.isVr
                    }
                }
            })
                .then((response) => {
                    this.caches.contentCollection = response.data;
                    scrollTo(0, 0);
                })
                .finally(() => {
                    this.searching = false;
                });
        },
        removeAllCondition: function () {
            this.searchConditions.keyword = "";
            this.searchConditions.selectedContentTypes = [];
            this.searchConditions.selectedCategories = [];
            this.searchConditions.selectedCulturalPropertyTypes = [];
            this.searchConditions.selectedSeasons = [];
            this.searchConditions.selectedRegions = [];
            this.searchConditions.selectedPeople = [];
            this.searchConditions.selectedPhotos = [];
            this.searchConditions.selectedVideos = [];
            this.searchConditions.selectedSizes = [];
            this.searchConditions.selectedImageTypes = [];
            this.searchConditions.selectedVideoTypes = [];
            this.searchConditions.selectedLicenses = [];
        },
        changeLocale: function (lang) {
            const currentPath = this.$router.history.current.path;
            const currentLang = this.$route.params.lang;

            if (currentLang !== lang) {
                this.$i18n.locale = lang;
                this.$router.push(currentPath.replace(currentLang, lang));
                document.getElementsByTagName("html")[0].lang = lang;
                this.$store.commit('updateLang');
            }
        }
    },
    render: h => h(App),
});
