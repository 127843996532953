<template>
    <div class="top-category-link">
        <div class="container px-md-0">
            <div class="row">
                <div class="col-12">
                    <div class="row row-cols-1 row-cols-md-3">
                        <div class="col"
                             :aria-label="$t('messages.vr') + $t('messages.english.vr')">
                            <div class="card">
                                <div class="card-body">
                                    <a tabindex="0" href="javascript:void(0)" @click="searchVrs" :aria-label="$t('accessibility.vr')">
                                        <p class="card-title">{{ $t('messages.vr') }}</p>
                                        <p class="card-subtitle">{{ $t('messages.english.vr') }}</p>
                                        <img src="./images/toplink_vr_sazaedo.jpg"
                                             @click="searchVrs"
                                             @keyup.enter="searchVrs"
                                             @keyup.space="searchVrs"
                                             alt=""
                                             class="card-img-bottom">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col"
                             :aria-label="$t('messages.japanHeritage') + $t('messages.english.japanHeritage')">
                            <div class="card">
                                <div class="card-body">
                                    <a tabindex="0" href="javascript:void(0)" @click="searchJapanHeritages" :aria-label="$t('accessibility.japanHeritage')">
                                        <p class="card-title">{{ $t('messages.japanHeritage') }}</p>
                                        <p class="card-subtitle">{{ $t('messages.english.japanHeritage') }}</p>
                                        <img src="./images/toplink_japan_heritage.jpg"
                                             @click="searchJapanHeritages"
                                             @keyup.enter="searchJapanHeritages"
                                             @keyup.space="searchJapanHeritages"
                                             alt=""
                                             class="card-img-bottom">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col"
                             :aria-label="$t('messages.worldHeritage') + $t('messages.english.worldHeritage')">
                            <div class="card">
                                <div class="card-body">
                                    <a tabindex="0" href="javascript:void(0)" @click="searchWorldHeritages" :aria-label="$t('accessibility.worldHeritage')">
                                        <p class="card-title">{{ $t('messages.worldHeritage') }}</p>
                                        <p class="card-subtitle">{{ $t('messages.english.worldHeritage') }}</p>
                                        <img src="./images/toplink_world_heritage.jpg"
                                             @click="searchWorldHeritages"
                                             @keyup.enter="searchWorldHeritages"
                                             @keyup.space="searchWorldHeritages"
                                             alt=""
                                             class="card-img-bottom">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col"
                             :aria-label="$t('messages.castles') + $t('messages.english.castles')">
                            <div class="card">
                                <div class="card-body">
                                    <a tabindex="0" href="javascript:void(0)" @click="searchCastles" :aria-label="$t('accessibility.castles')">
                                        <p class="card-title">{{ $t('messages.castles') }}</p>
                                        <p class="card-subtitle">{{ $t('messages.english.castles') }}</p>
                                        <img src="./images/toplink02.jpg"
                                             @click="searchCastles"
                                             @keyup.enter="searchCastles"
                                             @keyup.space="searchCastles"
                                             alt=""
                                             class="card-img-bottom">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col"
                             :aria-label="$t('messages.temple') + $t('messages.english.temple')">
                            <div class="card">
                                <div class="card-body">
                                    <a tabindex="0" href="javascript:void(0)" @click="searchTemples" :aria-label="$t('accessibility.temple')">
                                        <p class="card-title">{{ $t('messages.temple') }}</p>
                                        <p class="card-subtitle">{{ $t('messages.english.temple') }}</p>
                                        <img src="./images/toplink03.jpg"
                                             @click="searchTemples"
                                             @keyup.enter="searchTemples"
                                             @keyup.space="searchTemples"
                                             alt=""
                                             class="card-img-bottom">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col"
                             :aria-label="$t('messages.traditionalEvents') + $t('messages.english.traditionalEvents')">
                            <div class="card">
                                <div class="card-body">
                                    <a tabindex="0" href="javascript:void(0)" @click="searchTraditionalEvents" :aria-label="$t('accessibility.traditionalEvents')">
                                        <p class="card-title">{{ $t('messages.traditionalEvents') }}</p>
                                        <p id="contents-area" class="card-subtitle">{{ $t('messages.english.traditionalEvents') }}</p>
                                        <img src="./images/toplink05.jpg"
                                             @click="searchTraditionalEvents"
                                             @keyup.enter="searchTraditionalEvents"
                                             @keyup.space="searchTraditionalEvents"
                                             alt=""
                                             class="card-img-bottom">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
         name: "TopCategoryLink",
        methods: {
            searchVrs: function () {
                this.removeAllCondition();
                this.$root.caches.selectedSearchPullDown = "vr";
                this.$root.searchConditions.isVr = true;
                this.$router.push({name: 'Contents'}, () => {});
            },
            searchSeasons: function () {
                this.removeAllCondition();
                const season = this.$root.caches.categories.seasons.find(season =>
                    season.attributes.name.ja === "夏"
                );
                this.$root.searchConditions.selectedSeasons.push(season.id);
                this.$router.push({name: 'Contents'}, () => {});
            },
            searchCastles: function () {
                this.removeAllCondition();
                const category = this.$root.caches.categories.categories.find(category =>
                    category.attributes.name.ja === "城郭"
                );
                this.$root.searchConditions.selectedCategories.push(category.id);
                this.$router.push({name: 'Contents'}, () => {});
            },
            searchTemples: function () {
                this.removeAllCondition();
                const category = this.$root.caches.categories.categories.find(category =>
                    category.attributes.name.ja === "神社仏閣"
                );
                this.$root.searchConditions.selectedCategories.push(category.id);
                this.$router.push({name: 'Contents'}, () => {});
            },
            searchJapanHeritages: function () {
                this.removeAllCondition();
                const japanHeritage = this.$root.caches.categories.specified_categories.find(category =>
                    category.attributes.name.ja === "日本遺産"
                );
                this.$root.searchConditions.selectedCulturalPropertyTypes.push(japanHeritage.id);
                this.$root.searchConditions.selectedVideoTypes.push('searchConditions.videoTypes.edited');
                this.$router.push({name: 'Contents'}, () => {});
            },
            searchWorldHeritages: function () {
                this.removeAllCondition();
                const worldHeritage = this.$root.caches.categories.specified_categories.find(category =>
                    category.attributes.name.ja === "世界遺産"
                );
                this.$root.searchConditions.selectedCulturalPropertyTypes.push(worldHeritage.id);
                this.$router.push({name: 'Contents'}, () => {});
            },
            searchFestivals: function () {
                this.removeAllCondition();
                const category = this.$root.caches.categories.categories.find(category =>
                    category.attributes.name.ja === "祭"
                );
                this.$root.searchConditions.selectedCategories.push(category.id);
                this.$router.push({name: 'Contents'}, () => {});
            },
            searchTraditionalEvents: function () {
                this.removeAllCondition();
                this.$root.searchConditions.keyword = this.$root.$t('searchConditions.traditionalEvents');
                this.$router.push({name: 'Contents'}, () => {});
            },
            removeAllCondition: function () {
                this.$root.caches.selectedSearchPullDown = "all";
                this.$root.searchConditions.keyword = "";
                this.$root.searchConditions.selectedCategories = [];
                this.$root.searchConditions.selectedCulturalPropertyTypes = [];
                this.$root.searchConditions.selectedSeasons = [];
                this.$root.searchConditions.selectedRegions = [];
                this.$root.searchConditions.selectedPeople = [];
                this.$root.searchConditions.selectedPhotos = [];
                this.$root.searchConditions.selectedVideos = [];
                this.$root.searchConditions.selectedSizes = [];
                this.$root.searchConditions.selectedLicenses = [];
            },
        }
    }
</script>

<style src="./TopCategoryLink.scss" lang="scss" scoped />
