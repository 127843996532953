<template>
    <div class="voice-guide">
        <p tabindex="1">{{ $t('accessibility.voiceGuide.pageLink') }}</p>
        <ul tabindex="1" role="menu">
            <li role="menuitem"><a tabindex="1" href="#common-menu">{{ $t('accessibility.voiceGuide.moveToCommonMenu') }}</a></li>
            <li role="menuitem"><a tabindex="1" href="#contents-area">{{ $t('accessibility.voiceGuide.moveToContentsArea') }}</a></li>
            <li role="menuitem"><a tabindex="1" href="#footer-menu">{{ $t('accessibility.voiceGuide.moveToFooterMenu') }}</a></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "VoiceGuide"
    }
</script>

<style scoped>
    .voice-guide {
        white-space: nowrap;
        border: 0;
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        position: absolute !important;
    }
    .voice-guide:focus {
         border: none;
         outline: none;
     }
</style>
