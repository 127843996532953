<template>
    <div class="footer-panel-smart-phone d-block d-lg-none">
        <div role="navigation">
            <div class="back-to-top-btn d-flex justify-content-center" v-if="showBackToTopBtn">
                <button tabindex="0" class="btn btn-link" @click="scroll">
                    <img class="ico_pagetop" src="../../assets/icons/ico_back_to_top.svg" alt=""><br>T O P
                </button>
            </div>
            <div class="row nav-links">
                <div class="col-12">
                    <table class="table table-bordered" role="menu" @click="this.$root.removeAllCondition">
                        <tr>
                            <td colspan="2" role="menuitem">
                                <router-link tabindex="0" :to="{name: 'Top'}" @click.native="$emit('link-has-clicked')">
                                    {{ $t('buttons.top') }}
                                </router-link>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" role="menuitem">
                                <router-link tabindex="0" :to="{name: 'ViewingHistory'}" @click.native="$emit('link-has-clicked')">
                                    {{ $t('buttons.viewingHistory') }}
                                </router-link>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" role="menuitem">
                                <router-link tabindex="0" :to="{name: 'Favorites'}" @click.native="$emit('link-has-clicked')">
                                    {{ $t('buttons.favorites') }}
                                </router-link>
                            </td>
                        </tr>
                        <tr>
                            <td role="menuitem">
                                <router-link tabindex="0" :to="{name: 'PrivacyPolicy'}" @click.native="$emit('link-has-clicked')">
                                    {{ $t('buttons.privacy') }}
                                </router-link>
                            </td>
                        </tr>
                        <tr>
                            <td role="menuitem">
                                <router-link tabindex="0" :to="{name: 'TermsOfUse'}" @click.native="$emit('link-has-clicked')">
                                    {{ $t('buttons.terms') }}
                                </router-link>
                            </td>
                        </tr>
                        <tr>
                            <td role="menuitem">
                                <router-link tabindex="0" :to="{name: 'Accessibility'}" @click.native="$emit('link-has-clicked')">
                                    {{ $t('buttons.accessibility') }}
                                </router-link>
                            </td>
                        </tr>
                        <tr>
                            <td role="menuitem">
                                <a tabindex="0" href="https://www.inquiry.mext.go.jp/inquiry89/" target="_blank">
                                    {{ $t('contents.opinionsAndInquiries') }}
                                </a>
                            </td>
                        </tr>
                        <tr v-if="$route.params.lang === 'ja'">
                            <td role="menuitem">
                                <router-link tabindex="0" :to="{name: 'AboutCommentary'}" @click.native="$emit('link-has-clicked')">
                                    解説文について
                                </router-link>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row lang-switch">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 text-center lang-switch-name">
                            <p>LANGUAGE</p>
                        </div>
                    </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <a href="javascript:void(0)"
                           role="button"
                           class="btn btn-light mr-2"
                           :aria-label="$t('accessibility.switchJapanese')"
                           @click="$root.changeLocale('ja')"
                           tabindex="0">
                            JP
                        </a>
                        <a href="javascript:void(0)"
                           role="button"
                           class="btn btn-light"
                           :aria-label="$t('accessibility.switchEnglish')"
                           @click="$root.changeLocale('en')"
                           tabindex="0">
                            EN
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright d-flex justify-content-center align-items-center text-center"
             :aria-label="$t('accessibility.copyright')">
            <p tabindex="0">Copyright @ Agency for Cultural Affairs. All Rights Reserved.</p>
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FooterPanelSmartPhone",
        props: ['showBackToTopBtn'],
        methods: {
            scroll: function () {
                setTimeout(function () {
                    scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }, 0);
            }
        }
    }
</script>

<style src="./FooterPanelSmartPhone.scss" lang="scss" scoped />
