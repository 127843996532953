<script>
    const contentsKey = 'contents';
    export default {
        name: "FavoriteKit",
        data: function () {
            return {
                enableAddToFavorites: false
            }
        },
        methods: {
            addToFavorites: function (content) {
                this.$set(this.$root.caches.favoriteContents, content.id, content);
                this.saveToLocalStorage();
                this.contentHasAdded();
            },
            getFavoriteContents: function () {
                const saveData = window.localStorage.getItem(contentsKey);
                const contents = JSON.parse(saveData);

                if (contents === null) {
                    return {};
                }

                return contents;
            },
            contentHasAdded: function () {
                this.enableAddToFavorites = (this.$root.caches.favoriteContents[this.content.id] === undefined)
            },
            removeFromFavorites: function (id) {
                if (id) {
                    this.$delete(this.$root.caches.favoriteContents, id);
                    this.saveToLocalStorage();
                    this.contentHasAdded();
                }
            },
            saveToLocalStorage: function () {
                const saveData = JSON.stringify(this.$root.caches.favoriteContents);
                window.localStorage.setItem(contentsKey, saveData);
            }
        }
    }
</script>