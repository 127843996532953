<template>
    <footer class="footer d-none d-lg-block">
        <nav class="footer-nav-bar d-flex justify-content-center align-items-center"
             role="navigation"
             @click="this.$root.removeAllCondition">
            <router-link :to="{name: 'Top'}" tabindex="0" id="footer-menu" :aria-label="$t('accessibility.top')">
                {{ $t('buttons.top') }}
            </router-link>
            <router-link :to="{name: 'ViewingHistory'}" tabindex="0" :aria-label="$t('accessibility.viewingHistory')">
                {{ $t('buttons.viewingHistory') }}
            </router-link>
            <router-link :to="{name: 'Favorites'}" tabindex="0" :aria-label="$t('accessibility.favorites')">
                {{ $t('buttons.favorites') }}
            </router-link>
        </nav>
        <div class="footer-nav" role="navigation">
            <div class="back-to-top-btn d-flex justify-content-center">
                <button class="btn btn-link"
                        @click="scroll"
                        tabindex="0"
                        :aria-label="$t('accessibility.returnTop')">
                    <svg class="ico_pagetop" xmlns="http://www.w3.org/2000/svg" width="15.465" height="8.368" viewBox="0 0 15.465 8.368">
                        <path d="M2.648-21.266l.467.5-6.067,6.494L3.115-7.766l-.467.5-6.533-7.007Z" transform="translate(-6.533 4.569) rotate(90)" stroke-width="1"/>
                    </svg><br>TOP
                </button>
            </div>
            <div class="footer-nav-links d-flex justify-content-center" role="menu" @click="this.$root.removeAllCondition">
                <router-link :to="{name: 'PrivacyPolicy'}"
                             role="menuitem"
                             tabindex="0"
                             :aria-label="$t('accessibility.privacy')">
                    {{ $t('buttons.privacy') }}
                </router-link>
                <router-link :to="{name: 'TermsOfUse'}"
                             role="menuitem"
                             tabindex="0"
                             :aria-label="$t('accessibility.terms')">
                    {{ $t('buttons.terms') }}
                </router-link>
                <router-link :to="{name: 'Accessibility'}"
                             role="menuitem"
                             tabindex="0"
                             :aria-label="$t('accessibility.accessibility')">
                    {{ $t('buttons.accessibility') }}
                </router-link>
                <a tabindex="0" href="https://www.inquiry.mext.go.jp/inquiry89/" target="_blank">
                    {{ $t('contents.opinionsAndInquiries') }}
                </a>
                <router-link v-if="$route.params.lang === 'ja'"
                             :to="{name: 'AboutCommentary'}"
                             role="menuitem"
                             tabindex="0"
                             :aria-label="$t('accessibility.commentary')">
                    解説文について
                </router-link>
            </div>
        </div>
        <div class="copyright d-flex justify-content-center align-items-center text-center"
             :aria-label="$t('accessibility.copyright')">
            <p tabindex="0">Copyright @ Agency for Cultural Affairs. All Rights Reserved.</p>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "FooterPanel",
        methods: {
            scroll: function () {
                scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        }
    }
</script>

<style src="./FooterPanel.scss" lang="scss" scoped />
