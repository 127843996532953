import Vue from 'vue';
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
    en: {
        siteInfo: {
            top: 'Top',
            title: 'Cultural Properties Digital Content Download Site',
            description: 'Download information and free high-resolution photos/videos of ' +
                'Japan\'s National Treasures, Important Cultural Properties, and more!',
            contents: 'Search results for',
            favorites: 'Favorites',
            histories: 'Viewing History'
        },
        messages: {
            headerTitle: 'Cultural Properties' + '<br>' + 'Digital Content Download Site',
            headerText: 'Download information and free high-resolution photos/videos of ' +
                'Japan\'s National Treasures, Important Cultural Properties, and more!',

            topInfo: 'All downloadable material can be used in accordance with our Terms of Use and the applicable ' +
                'Creative Commons License. ' + '<br>' +
                'Please use freely to promote tourism to Japan, to create new content, and other purposes.',
            vr: '',
            japaneseSeasons: 'Japanese Four Seasons',
            seasons: {
                spring: 'Spring',
                summer: 'Summer',
                autumn: 'Autumn',
                winter: 'Winter'
            },
            castles: '',
            temple: '',
            japanHeritage: '',
            worldHeritage: '',
            festivals: '',
            traditionalEvents: '',
            english: {
                vr: 'Ultra High Definition VR / Panorama',
                japaneseSeasons: '',
                seasons: {
                    spring: '',
                    summer: '',
                    autumn: '',
                    winter: ''
                },
                castles: 'Castles',
                temple: 'Temples and Shrines',
                japanHeritage: 'Japan Heritage Stories',
                worldHeritage: 'World Heritage',
                festivals: 'Festivals',
                traditionalEvents: 'Traditional Events',
            }
        },
        buttons: {
            top: 'Top',
            viewingHistory: 'Viewing History',
            favorites: 'Favorites',
            howToUse: 'How To Use',
            searchCriteria: 'Refine Search',
            categories: 'Categories',
            culturalPropertyType: 'Designation',
            seasons: 'Seasons',
            regions: 'Regions',
            people: 'People',
            all: 'All',
            photos: 'Photos',
            videos: 'Videos',
            photosAndVideos: 'Photos & Videos',
            vr: 'VR',
            vrPhotos: 'VR Photos',
            vrVideos: 'VR Videos',
            videoTypes: 'Type of Video',
            licensing: 'Licensing',
            search: 'Search',
            searching: 'Searching...',
            clear: 'Clear',
            privacy: 'Privacy Policy',
            cookie: 'Cookies Policy',
            copyright: 'Copyright',
            terms: 'Terms of Use',
            contact: 'Contact Us',
            accessibility: 'Accessibility',
            keywordSearch: 'Search using selected terms',
            removeContents: 'Remove from Favorites',
            agree: 'I agree',
            disagree: 'I don\'t agree',
        },
        breadcrumbs: {
            top: 'Bread crumb list on top page',
            searchResults: 'Breadcrumbs on search results'
        },
        numberOfHits: {
            searchResults: '',
            results: ' results'
        },
        toolbars: {
            styles: 'Display style',
            perPage: 'Results per page',
            sortBy: 'Sort by',
            createdAt: 'Date added',
            photographedAt: 'Date filmed'
        },
        contents: {
            aboutTheWork: 'About the Work',
            aboutTheCulturalProperty: 'About the Cultural Property',
            title: 'Title',
            subtitle: 'Subtitle',
            date: 'Date photographed/filmed',
            photographed: 'Photographed',
            filmed: 'Filmed',
            licensing: 'Licensing',
            codec: 'Codec',
            fps: 'Fps',
            duration: 'Duration',
            size: 'Size',
            name: 'Name',
            designation: 'Designation',
            year: 'Date',
            location: 'Location',
            description: 'Description',
            vrLink: 'View VR Video',
            imageVr: 'You can browse 360 degrees by grabbing the image and dragging it.',
            addToFavorites: 'Add to Favorites',
            removeFromFavorites: 'Remove from Favorites',
            favorites: 'Favorites',
            zoom: 'Enlarge',
            closePreview: 'Close',
            content: 'Detailed Information',
            relatedContents: 'Related Works',
            relatedTerms: 'Related Search Terms',
            viewingHistory: 'Viewing History',
            opinionsAndInquiries: 'Opinions and Inquiries',
            readMore: 'Read More',
            seeMore: 'See More',
            loading: 'Searching...',
            noResults: 'Sorry, no content matched your search criteria.'
        },
        downloads: {
            textData: 'Description',
            format: '34k / txt file',
            agree: 'I agree to the ' + '<a href="/en/pages/terms-of-use" tabindex="0" target="_blank">'
                + 'Terms of Use' + '</a>',
            download: 'Download',
            chooseSize: 'Choose a size.',
            canDownloadTextData: 'Select "Description" to download text.',
            vrTextData: 'After downloading, a dedicated browser or application is required to use the VR content.',
            additionalTerms: 'Users must agree to Additional Restrictions to download this data.',
            readAdditionalTerms: 'Read Additional Restrictions'
        },
        searchConditions: {
            culturalPropertyTypes: {
                worldHeritage: 'World Heritage',
                japaneseHeritage: 'Japan Heritage',
                nationalTreasure: 'National Treasures',
                important: 'Important Cultural Properties',
                other: 'Other'
            },
            seasons: {
                spring: 'Spring',
                summer: 'Summer',
                autumn: 'Autumn',
                winter: 'Winter'
            },
            regions: {
                hokkaido: 'Hokkaido',
                tohoku: 'Tohoku',
                kanto: 'Kanto',
                hokuriku: 'Hokuriku Shinetsu',
                tokai: 'Tokai',
                kansai: 'Kansai',
                chugoku: 'Chugoku',
                shikoku: 'Shikoku',
                kyusyu: 'Kyushu',
                okinawa: 'Okinawa'
            },
            people: {
                exist: 'Includes people',
                notExist: 'No people'
            },
            photos: {
                length: 'Vertical',
                width: 'Horizontal'
            },
            imageTypes: {
                vr: '360VR',
            },
            videos: {
                duration: {
                    less10Seconds: 'Less than 10 seconds',
                    more10Seconds: '10 seconds or more',
                    more30Seconds: '30 seconds or more',
                    more60Seconds: '60 seconds or more'
                }
            },
            videoTypes: {
                edited: 'Edited video package',
                material: 'Raw footage',
                vr: 'VR',
            },
            licenses: {
                all: 'No restrictions',
                impossible: 'Commercial use restricted or not allowed (see Additional Restrictions)',
                notation: 'Attribution required (see Additional Restrictions)',
                others: 'Other restrictions (see Additional Restrictions)',
                iseShrine: 'Other conditions (Ise Jingu)',
                izumoShrine: 'Other conditions (Izumo Taisha Grand Shrine)',
                todaiji: 'Other conditions (Todai-ji Temple)'
            },
            traditionalEvents: 'Traditional Events',
            additionalRestrictions: {
                dazaifu: '<div class="additional">\n' +
                    '    <h3 tabindex="0">Additional Provisions for Dazaifu Tenmangu\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">The following special provisions shall be applied to the exploitation of the Contents of images and videos of Dazaifu Tenmangu offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Credit<br>\n' +
                    '            Users must insert the following credit for use of the Contents, including but not limited to images and videos, of Dazaifu Tenmangu.<br>\n' +
                    '            Provided by: Dazaifu Tenmangu<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2  Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Dazaifu Tenmangu shall be licensed for exploitation for non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-NC 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                isejingu: '<div class="additional">\n' +
                    '    <h3 tabindex="0">Additional Provisions for Jingu</h3>\n' +
                    '    <p tabindex="0">The following special provisions shall be applied to the exploitation of Contents of images and videos of Jingu offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Jingu shall be licensed for exploitation for non-profit purposes only, and NOT for commercial purposes.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2  Prohibition of editing or modifying the Contents<br>\n' +
                    '            Users may NOT edit or modify the Contents (images and videos) of Jingu for use. Notwithstanding the foregoing, minor modification such as editing parts from the videos for exploitation is allowed, but in any event, any editing or modification that may impair the dignity of Jingu, including but not limited to image processing, layout changes, and adding descriptions, captions, narrations or subtitles, is strictly prohibited.<br><br>\n' +
                    '            Due to paragraph 1 and 2 above, the terms for exploitation under the Creative Commons license shall be [CC BY-NC-ND 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            3  Application for use<br>\n' +
                    '            Users must notify the Jingu Shicho (Administration Office) at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Jingu regarding the specific means of use.<br><br>\n' +
                    '            Public Relations, Jingu Shicho (Administration Office) TEL: 0596-24-1111<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                kunozan: '<div class="additional">\n' +
                    '    <h3 tabindex="0">Additional Provisions for Kunosan Toshogu Shrine\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">The following special provisions shall be applied to the exploitation of Contents of images and videos of Kunosan Toshogu Shrine offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Kunosan Toshogu Shrine shall be licensed for exploitation for non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-NC 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                murakami: '<div class="additional">\n' +
                    '    <h3 tabindex="0">Addition Provisions for Japan\'s Largest "Pirate" Group and their Territory<br>\n' +
                    '        in the Geiyo Archipelago\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">The following special provisions shall be applied to the exploitation of Contents of images and videos of Japan\'s Largest "Pirate" Group and their Territory in the Geiyo Archipelago offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Application for use<br>\n' +
                    '            [(1) Haori - a short Japanese overgarment- (red), Yoroi - a piece of armor -(black)]<br>\n' +
                    '            An application to the Murakami Navy Museum, Imabari City is required for exploitation of the Contents (images and videos) stored at the Murakami Navy Museum, Imabari City.<br>\n' +
                    '            Users must notify the Murakami Navy Museum, Imabari City at the attention provided below to apply for special exploitation prior to use.<br>\n' +
                    '            Imabari Murakami Kaizoku Museum<br>\n' +
                    '            TEL: 0897-74-1065, e-mail: i2922@imabari-city.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                murakamiForChosenji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">Addition Provisions for Japan\'s Largest "Pirate" Group and their Territory<br>\n' +
                    '        in the Geiyo Archipelago\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">The following special provisions shall be applied to the exploitation of Contents of images and videos of Japan\'s Largest "Pirate" Group and their Territory in the Geiyo Archipelago offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Application for use<br>\n' +
                    '            [(1) Haori - a short Japanese overgarment- (red), Yoroi - a piece of armor -(black)]<br>\n' +
                    '            An application to the Murakami Navy Museum, Imabari City is required for exploitation of the Contents (images and videos) stored at the Murakami Navy Museum, Imabari City.<br>\n' +
                    '            Users must notify the Murakami Navy Museum, Imabari City at the attention provided below to apply for special exploitation prior to use.<br>\n' +
                    '            Imabari Murakami Kaizoku Museum<br>\n' +
                    '            TEL: 0897-74-1065, e-mail: i2922@imabari-city.jp<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            [(2) A picture of a convoy *A picture showing when a Korean delegation arrived at Kaminoseki, Japan]<br>\n' +
                    '            Users must notify the Board of Education of Kaminoseki Town, Yamaguchi Prefecture at the attention provided below to apply for special exploitation prior to use of the Contents (images and videos) stored at Chosenji located in Kaminoseki town, Yamaguchi prefecture.\n' +
                    '            Board of Education, Kaminoseki Town, Yamaguchi Prefecture\n<br>\n' +
                    '            TEL: 0820-62-1460, e-mail: kyouiku2@town.kaminoseki.lg.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                nachikumanotaisha: '<div class="additional">\n' +
                    '    <h3 tabindex="0">Additional Provisions for Nachi Kumano Taisha\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">The following special provisions shall be applied to the exploitation of Contents of images and videos of Nachi Kumano Taisha offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Credit<br>\n' +
                    '            Users must insert the following credit as follows for use of the Contents, including but not limited to images and videos, of Nachi Kumano Taisha, Waterfall of Nachi and Daimon Slope.<br>\n' +
                    '            Credit for insert: Nachi Kumano Taisha<br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                nageiredou: '<div class="additional">\n' +
                    '    <h3 tabindex="0">Additional Provisions for Mitoku-san Sanbutsu-ji Temple Okunoin "Nageire Dou"\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">The following special provisions shall be applied to the exploitation of Contents of images and videos of Mitoku-san Sanbutsu-ji Temple Okunoin "Nageire Dou".<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Credit<br>\n' +
                    '            Users must give credit as follows for use of the Contents, including but not limited to images and videos, of Mitoku-san Sanbutsu-ji Temple Nageire Dou.<br>\n' +
                    '            Credit for insert: 三徳山三佛寺奥の院「投入堂」Mitokusan Sanbutsuji Nageiredo<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2  Application for use<br>\n' +
                    '            Users must notify the Mitoku-san Sanbutsu-ji Temple at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Mitoku-san Sanbutsu-ji Temple Nageire Dou regarding the specific means of use.<br>\n' +
                    '            Mitoku-san Sanbutsu-ji Temple TEL: 0858-43-2666<br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>\n',
                sazaedo: '<div class="additional">\n' +
                    '    <h3 tabindex="0">Additional Provisions for SazaedoTemple\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">The following special provisions shall be applied to the exploitation of Contents of images and videos of Sazaedo offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Sazaedo shall be licensed for exploitation for non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-NC 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                takeoonsen: '<div class="additional">\n' +
                    '    <h3 tabindex="0">Additional Provisions for Takeo Onsen Tower Gate, Takeo Onsen New Building\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">The following special provisions shall be applied to the exploitation of Contents of images and videos of Takeo Onsen Tower Gate and Takeo Onsen New Building offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Credit<br>\n' +
                    '            Users must insert the following credit as follows for use of the Contents, including but not limited to images and videos, of Takeo Onsen Tower Gate and Takeo Onsen New Building.\n' +
                    '            Cooperation: Takeo Onsen Co., Ltd.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2  Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Takeo Onsen Tower Gate and Takeo Onsen New Building shall be licensed for exploitation for non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-NC 4.0].<br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                yashimaji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">Additional Provisions for Yashimaji Temple\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">The following special provisions shall be applied to the exploitation of Contents of images and videos of Yashimaji offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Yashimaji shall be licensed for exploitation for non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-NC 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                about: '<div class="additional">\n' +
                    '<h3 tabindex="0">Addition Provisions for What is Japan Heritage?</h3>\n' +
                    '<p tabindex="0">The following special provisions shall be applied to the exploitation of Contents of images and videos of What is Japan Heritage? offered.<br>\n' +
                    'Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.\n' +
                    '</p>\n' +
                    '<ul>\n' +
                    '<li tabindex="0">\n' +
                    '1  Application for use<br>\n' +
                    '[(1) Haori - a short Japanese overgarment- (red), Yoroi - a piece of armor -(black)]<br>\n' +
                    'An application to the Murakami Navy Museum, Imabari City is required for exploitation of the Contents (images and videos) stored at the Murakami Navy Museum, Imabari City. Users must notify the Murakami Navy Museum, Imabari City at the attention provided below to apply for special exploitation prior to use.<br>\n' +
                    'Imabari Murakami Kaizoku Museum<br>\n' +
                    'TEL: 0897-74-1065, e-mail: i2922@imabari-city.jp\n' +
                    '</li>\n' +
                    '</ul>\n' +
                    '</div>',
                ama: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for “Ama diving technique”\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of “Ama diving technique” offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Credit<br>\n' +
                    '            Users must insert the following credit for use of the Contents, including but not limited to images and videos, of “Ama diving technique.”<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Provided by: Ama Hut SATOUMIAN<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                asukadera: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Asukadera Temple\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of Asukadera Temple and Asuka Daibutsu　offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Application for use<br>\n' +
                    '            Users must notify the Asukadera Temple and Asuka Daibutsu at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Asukadera Temple regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Asukadera Temple<br>\n' +
                    '            TEL　０７４４―５４－２１２６<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                futagoji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Futagoji Temple\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of Futagoji Temple offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Application for use<br>\n' +
                    '            Users must notify the Futagoji Temple at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Futagoji Temple regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Futagoji Temple<br>\n' +
                    '            TEL０９７８－６５－０２５３<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                gyodaCityLocalMuseum: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Gyoda City Local Museum\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of Gyoda City Local Museum offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1   Application for use<br>\n' +
                    '            Users must notify the Gyoda City Local Museum　at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Gyoda City Local Museum regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Gyoda City Local Museum<br>\n' +
                    '            TEL ０４８－５５４－５９１１<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                hakoneSekisho: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Former Hakone Kaido highway「Hakone Sekisho」\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of Former Hakone Kaido highway「Hakone Sekisho」 offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Former Hakone Kaido highway「Hakone Sekisho」shall be licensed for exploitation for non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-NC 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2  Prohibition of editing or modifying the Contents<br>\n' +
                    '            Users may NOT edit or modify the Contents (images and videos) of Former Hakone Kaido highway「Hakone Sekisho」for use. Due to paragraph 2 above, the terms for exploitation under the Creative Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-ND 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            3  Application for use<br>\n' +
                    '            Users must notify the Former Hakone Kaido highway「Hakone Sekisho」 at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Hakone Sekisho regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Hakone Sekisho<br>\n' +
                    '            TEL　 0460－83－6635<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                jionji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Jionji Temple\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of Jionji Temple’s Buddhist statues offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Application for use<br>\n' +
                    '            Users must notify Jionji Temple at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of the temple’s Buddhist statues regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Honzan Jionji Temple (a religious corporation)<br>\n' +
                    '            Tel (from within Japan): 0237-87-3993<br>\n' +
                    '            Tel (from outside Japan): +81-237-87-3993<br>\n' +
                    '            Email: customer@honzan-jionji.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                kamakurabungakukan: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Kamakura Museum of Literature\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of Kamakura Museum of Literature offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Kamakura Museum of Literature shall be licensed for exploitation for non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-NC 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2  Application for use<br>\n' +
                    '            Users must notify the Kamakura Museum of Literature at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Kamakura Museum of Literature regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Kamakura Museum of Literature<br>\n' +
                    '            TEL　0467-23-3911<br>\n' +
                    '            e-mail bungakukan@kamakura-arts.or.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                kashiharajingu: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Fukada Pond\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of Fukada Pond offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1　　Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Fukada Pond shall be licensed for exploitation for non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-NC 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2  Prohibition of editing or modifying the Contents<br>\n' +
                    '            Users may NOT edit or modify the Contents (images and videos) of Fukada Pond for use. Due to paragraph 2 above, the terms for exploitation under the Creative Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-ND 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            3  Application for use<br>\n' +
                    '            Users must notify the Kashiharajingu at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Fukada Pond regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Kashiharajingu<br>\n' +
                    '            TEL　0744－22－3271<br>\n' +
                    '            e-mail kasiharajingu_syougai@yahoo.co.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                kenchoji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Kenchoji Temple\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of Kenchoji Temple offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1   Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Kenchoji Temple shall be licensed for exploitation for non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-NC 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2  Application for use<br>\n' +
                    '            Users must notify Kenchoji Temple at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Kenchoji Temple regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Administrative division, headquarters of the Kenchoji school, Rinzai sect<br>\n' +
                    '            Tel (from within Japan): 0467-22-0981<br>\n' +
                    '            Tel (from outside Japan): +81-467-22-0981<br>\n' +
                    '            *Japanese only<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                kishutoshogu: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Kishu Toshogu Shrine\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of Kishu Toshogu Shrine offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Credit<br>\n' +
                    '            Users must insert the following credit for use of the Contents, including but not limited to images and videos, of Kishu Toshogu Shrine<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Provided by: Kishu Toshogu Shrine<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                konojinjya: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Konojinjya\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of  Konojinjya offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Application for use<br>\n' +
                    '            Users must notify the Konojinjya at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Konojinjya regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            konojinjya<br>\n' +
                    '            TEL　0772-27-0006<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                oosakihachimangu: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Osaki Hachimangu Shrin\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of Osaki Hachimangu Shrine offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Application for use<br>\n' +
                    '            Users must notify the Osaki Hachimangu Shrine at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Osaki Hachimangu Shrine regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Osaki Hachimangu Shrine<br>\n' +
                    '            TEL　０２２-２３４-３６０６<br>\n' +
                    '            e-mail oosaki@oosaki-hachiman.or.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                sendaitoshogu: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Sendai Toshogu Shrine\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of 　Sendai Toshogu Shrine　offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Application for use<br>\n' +
                    '            Users must notify the Sendai Toshogu Shrine　at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Sendai Toshogu Shrine regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Sendai Toshogu Shrine<br>\n' +
                    '            Phone: +81-22-234-3247<br>\n' +
                    '            e-mail: sakura@s-toshogu.com<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                shurijoato: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for shurijoato\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of shurijoato offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Credit<br>\n' +
                    '            Users must insert the following credit for use of the Contents, including but not limited to images and videos, of shurijoato<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Provided by: Okinawa Commemorative National Government Park（Shurijo Castle Park）<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                wakayamajo: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Wakayama castle\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of  drone videos of Wakayama castle offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Application for use<br>\n' +
                    '            Users must notify the Wakayama City at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of drone videos of Wakayama castle regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Wakayama City<br>\n' +
                    '            TEL　073-435-1044<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                nihonkoku: '<div class="additional">\n' +
                    '    <h3 class="contents-title" tabindex="0">\n' +
                    '        Additional Provisions for<br>　\n' +
                    '        The Asuka Women who Fostered the Building of a New Nation\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of Fukada Pond，Asukadera Temple and Asuka Daibutsu offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul class="note">\n' +
                    '        <li tabindex="0">\n' +
                    '            1　　Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Fukada Pond shall be licensed for exploitation for non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-NC 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2  Prohibition of editing or modifying the Contents<br>\n' +
                    '            Users may NOT edit or modify the Contents (images and videos) of Fukada Pond for use. Due to paragraph 2 above, the terms for exploitation under the Creative Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-ND 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            3  Application for use<br>\n' +
                    '            Users must notify the Kashiharajingu at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Fukada Pond regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Kashiharajingu<br>\n' +
                    '            TEL　0744－22－3271<br>\n' +
                    '            e-mail kasiharajingu_syougai@yahoo.co.jp<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            4  Application for use<br>\n' +
                    '            Users must notify the Asukadera Temple and Asuka Daibutsu at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Asukadera Temple regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Asukadera Temple<br>\n' +
                    '            TEL　０７４４―５４－２１２６<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                wakanoura: '<div class="additional">\n' +
                    '    <h3 class="contents-title" tabindex="0">\n' +
                    '        Additional Provisions for<br>\n' +
                    '        Wakanoura – A Treasure Trove of Beautiful Views\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of Kishu Toshogu Shrine, drone videos of Wakayama castle offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul class="note">\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Credit<br>\n' +
                    '            Users must insert the following credit for use of the Contents, including but not limited to images and videos, of Kishu Toshogu Shrine<br>\n' +
                    '            Provided by: Kishu Toshogu Shrine<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2  Application for use<br>\n' +
                    '            Users must notify the Wakayama City at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of drone videos of Wakayama castle regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Wakayama City<br>\n' +
                    '            TEL　073-435-1044<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                izakamakura: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Kamakura - A Historical and Cultural Mosaic<br><br>\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of 　Kenchoji，Kamakura Museum of Literature　offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1   Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Kenchoji　shall be licensed for exploitation for non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-NC 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2  Application for use<br>\n' +
                    '            Users must notify the Kenchoji　at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Kenchoji regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Administrative division, headquarters of the Kenchoji school, Rinzai sect<br>\n' +
                    '            Tel (from within Japan): 0467-22-0981<br>\n' +
                    '            Tel (from outside Japan): +81-467-22-0981<br>\n' +
                    '            ※Japanese only<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            3  Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Kamakura Museum of Literature shall be licensed for exploitation for non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the Creative Commons license shall be [CC BY-NC 4.0].<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            4  Application for use<br>\n' +
                    '            Users must notify the Kamakura Museum of Literature at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Kamakura Museum of Literature regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Kamakura Museum of Literature<br>\n' +
                    '            TEL　0467-23-3911<br>\n' +
                    '            e-mail bungakukan@kamakura-arts.or.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                masamune: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Sendai – Home of the Sophisticated Samurai<br><br>\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of Osaki Hachimangu Shrine and Sendai Toshogu Shrine offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Application for use<br>\n' +
                    '            Users must notify the Osaki Hachimangu Shrine at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Osaki Hachimangu Shrine regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Osaki Hachimangu Shrine<br>\n' +
                    '            TEL　０２２-２３４-３６０６<br>\n' +
                    '            e-mail oosaki@oosaki-hachiman.or.jp<br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2  Application for use<br>\n' +
                    '            Users must notify the Sendai Toshogu Shrine　at the attention provided below to apply for exploitation prior to use of the Contents (images and videos) of Sendai Toshogu Shrine regarding the specific means of use.<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Sendai Toshogu Shrine<br>\n' +
                    '            Phone: +81-22-234-3247<br>\n' +
                    '            e-mail: sakura@s-toshogu.com<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                kongoji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Amanosan Kongoji\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of\n' +
                    '        Kongoji　Temple offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following\n' +
                    '        additional provisions for downloading. Users must comply with the following terms of additional provisions along\n' +
                    '        with the Terms of Use of the Contents Bank.\n' +
                    '        <br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1 Credit<br>\n' +
                    '            Users must insert the following credit for use of the Contents, including but not limited to images and\n' +
                    '            videos, of Kongoji　Temple<br>\n' +
                    '            Provided by: Amanosan Kongoji\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2 Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Kongoji　Temple shall be licensed for exploitation for\n' +
                    '            non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the\n' +
                    '            Creative Commons license shall be [CC BY-NC 4.0].\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            3 Prohibition of editing or modifying the Contents<br>\n' +
                    '            Users may NOT edit or modify the Contents (images and videos) of Kongoji　Temple for use. Due to paragraph 2\n' +
                    '            above, the terms for exploitation under the Creative Accordingly, the terms for exploitation under the\n' +
                    '            Creative Commons license shall be [CC BY-ND 4.0].\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            4 Application for use<br>\n' +
                    '            Users must notify the Kongoji　Temple at the attention provided below to apply for exploitation prior to use\n' +
                    '            of the Contents (images and videos) of Amanosan Kongoji regarding the specific means of use.\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Amanosan Kongoji<br>\n' +
                    '            TEL: 0721-52-2046<br>\n' +
                    '            *Japanese only<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                muroji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Murouji Temple\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of\n' +
                    '        Murouji Temple offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following\n' +
                    '        additional provisions for downloading. Users must comply with the following terms of additional provisions along\n' +
                    '        with the Terms of Use of the Contents Bank.\n' +
                    '\n' +
                    '        <br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1 Application for use<br>\n' +
                    '            Users must notify the Murouji Temple at the attention provided below to apply for exploitation prior to use\n' +
                    '            of the Contents (images and videos) of Murouji Temple regarding the specific means of use.\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Murouji Temple<br>\n' +
                    '            TEL: 0745-93-2003<br>\n' +
                    '            FAX: 0745-93-2057<br>\n' +
                    '            Mail: yamato-murouji@kcn.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                nyonnkoya: '<div class="additional">\n' +
                    '    <h3 tabindex="0" class="text-center">\n' +
                    '        Additional Provisions for Murouji Temple\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of\n' +
                    '        Murouji Temple offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following\n' +
                    '        additional provisions for downloading. Users must comply with the following terms of additional provisions along\n' +
                    '        with the Terms of Use of the Contents Bank.\n' +
                    '\n' +
                    '        <br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1 Application for use<br>\n' +
                    '            Users must notify the Murouji Temple at the attention provided below to apply for exploitation prior to use\n' +
                    '            of the Contents (images and videos) of Murouji Temple regarding the specific means of use.\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Murouji Temple<br>\n' +
                    '            TEL: 0745-93-2003<br>\n' +
                    '            FAX: 0745-93-2057<br>\n' +
                    '            Mail: yamato-murouji@kcn.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '    <br>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of\n' +
                    '        Kongoji　Temple offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following\n' +
                    '        additional provisions for downloading. Users must comply with the following terms of additional provisions along\n' +
                    '        with the Terms of Use of the Contents Bank.\n' +
                    '        <br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1 Credit<br>\n' +
                    '            Users must insert the following credit for use of the Contents, including but not limited to images and\n' +
                    '            videos, of Kongoji Temple<br>\n' +
                    '            Provided by: Amanosan Kongoji\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            2 Prohibition of exploitation for commercial purposes<br>\n' +
                    '            Exploitation of the Contents (images and videos) of Kongoji　Temple shall be licensed for exploitation for\n' +
                    '            non-profit purposes only, and NOT for commercial purposes. Accordingly, the terms for exploitation under the\n' +
                    '            Creative Commons license shall be [CC BY-NC 4.0].\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            3 Prohibition of editing or modifying the Contents<br>\n' +
                    '            Users may NOT edit or modify the Contents (images and videos) of Kongoji　Temple for use. Due to paragraph 2\n' +
                    '            above, the terms for exploitation under the Creative Accordingly, the terms for exploitation under the\n' +
                    '            Creative Commons license shall be [CC BY-ND 4.0].\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            4 Application for use<br>\n' +
                    '            Users must notify the Kongoji　Temple at the attention provided below to apply for exploitation prior to use\n' +
                    '            of the Contents (images and videos) of Amanosan Kongoji regarding the specific means of use.\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            Amanosan Kongoji<br>\n' +
                    '            TEL: 0721-52-2046<br>\n' +
                    '            *Japanese only<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>\n',
                tamahagane: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        Additional Provisions for Tamahagane steel production techniques<br><br>\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        The following special provisions shall be applied to the exploitation of the Contents of images and videos of Tamahagane steel production techniques offered.<br>\n' +
                    '        Users must click the “Accept” button on the download page after confirming and agreeing to the following additional provisions for downloading. Users must comply with the following terms of additional provisions along with the Terms of Use of the Contents Bank.<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            1  Credit<br>\n' +
                    '            Users must insert the following credit for use of the Contents, including but not limited to images and videos, of Tamahagane steel production techniques\n' +
                    '            Provided by: Nittoho Tatara (The Society for Preservation of Japanese Art Swords)<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
            }
        },
        accessibility: {
            headerImage: 'Header image',
            logo: 'Agency for Cultural Affairs',
            switchJapanese: 'Switch to Japanese',
            switchEnglish: 'Switch to English',
            keywordSearchForm: 'Search box for digital content of cultural properties. ' +
                'Type in search terms and press Enter.',
            keywordSearch: 'Enter search terms',
            searchButton: 'Search button',
            openMenu: 'Open menu',
            closeMenu: 'Close menu',
            copyright: 'Press Enter to jump to Copyright page',
            howToUse: 'Press Enter to jump to How to Use page',
            dropdownSort: 'Change sort settings',
            dropdownNumber: 'Change number of results per page',
            imageModal: 'Pop-up window with enlarged image',
            downloadModal: 'Modal window to download content',
            additionalTermsModal: 'Modal window displaying Additional Restrictions',
            closeModal: 'Close enlarged image',
            pagination: 'Search results',
            addToFavorites: 'Add to Favorites',
            removeFromFavorites: 'Remove from Favorites',
            zoomContent: 'Enlarge image',
            removeContents: 'Remove from Favorites',
            contentImage: '\'s image. Press Enter, or press Space bar to jump to Details page',
            contentCard: '\'s content. Press Enter or press Space bar to jump to Details page',
            contentId: 'Identifier for this content. Press Enter or press Space bar to jump to Details page',
            sSizeContent: 'Select small data',
            mSizeContent: 'Select medium data',
            lSizeContent: 'Select large data',
            textContent: 'Select description text file',
            largeCollection: 'Display as large images',
            smallCollection: 'Display as small images',
            list: 'Display as list',
            changeNumberTo1: 'Show one result per page',
            changeNumberTo2: 'Show two results per page',
            changeNumberTo5: 'Show five results per page',
            createdAt: 'Sort by date added',
            photographedAt: 'Sort by Date photographed/filmed',
            vr: 'Search for Ultra High Definition VR / Panorama',
            seasons: {
                spring: 'Search for spring',
                summer: 'Search for summer',
                autumn: 'Search for autumn',
                winter: 'Search for winter'
            },
            castles: 'Search for castles',
            temple: 'Search for temples and shrines',
            japanHeritage: 'Search for Japan Heritage sites',
            worldHeritage: 'Search for World Heritage sites',
            festivals: 'Search for festivals',
            traditionalEvents: 'Search for traditional events',
            page: 'page',
            previousPage: 'Previous page',
            nextPage: 'Next page',
            readMoreRelated: 'Display more related works',
            readMoreHistories: 'Display more viewing history',
            returnTop: 'Back to top',
            top: 'Press Enter to jump to Top page',
            viewingHistory: 'Press Enter to jump to Viewing History page',
            viewingVR: 'Press Enter to jump to Viewing VR page',
            favorites: 'Press Enter to jump to Favorites',
            privacy: 'Press Enter to jump to Privacy Policy page',
            cookie: 'Press Enter to jump to Cookies Policy page',
            terms: 'Press Enter to jump to Terms of Use page',
            accessibility: 'Press Enter to jump to Accessibility of Use page',
            contact: 'Press Enter to jump to Contact Us page',
            voiceGuide: {
                pageLink: 'In page link',
                moveToCommonMenu: 'Go to common menu',
                moveToContentsArea: 'Go to page body',
                moveToFooterMenu: 'Go to footer'
            }
        },
        staticPages: {
            contactUs: {
                title: '',
            },
            CookiePolicy: '',
            Copyright: '',
            HowToUse: '',
            PrivacyPolicy: '<p class="lead" tabindex="0">Privacy Policy of the Cultural Properties Digital Content Download Site</p>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">1. Basic Stance</h2>\n' +
                '<ol>\n' +
                '    <li tabindex="0">\n' +
                '        The Agency for Cultural Affairs collects information on anyone visiting the Cultural Properties Digital Content Download Site (https://cb.bunka.go.jp/) (hereafter referred as "this website") to make the provision of information and the acceptance of inquiries to the Agency more convenient. The information collected is used only within the scope of those purposes.</li>\n' +
                '</ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">2. Scope of Information to be Collected</h2>\n' +
                '<ol>\n' +
                '    <li tabindex="0">\n' +
                '        (1)This website automatically collects information, including IP addresses. Cookies may be used by this website to automatically collect information pertaining to browsing this website.</li>\n' +
                '    <li tabindex="0">\n' +
                '        (2)For comments and inquiries on the Agency for Cultural Affairs policies (https://www.inquiry.mext.go.jp/inquiry89/), users are invited to register their names, addresses, phone numbers, or emails at their own discretion.</li>\n' +
                '    <li tabindex="0">\n' +
                '        (3)In the event that this website calls for public comments or comments on specific cases, senders of the comments may be requested to provide their names, addresses, occupations, and phone numbers as necessary. The sender’s e-mail address is displayed to the recipient.</li>\n' +
                '</ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">3. Purpose of Use</h2>\n' +
                '<ol>\n' +
                '    <li tabindex="0">\n' +
                '        (1)The information collected as in 2 (1) is used as a reference for the smooth operation of the services provided by this website.</li>\n' +
                '    <li tabindex="0">\n' +
                '        (2)The comments collected as in 2 (2) are used as a reference for future policy making. For this reason, comments or inquiries may be forwarded to relevant departments in the Agency for Cultural Affairs or external administrative agencies, based on their content. The Agency for Cultural Affairs uses senders\' names and emails to reply to or confirm inquiries.</li>\n' +
                '    <li tabindex="0">\n' +
                '        (3)The comments collected as in 2 (3) are used as a reference for future policy making. The Agency for Cultural Affairs may use senders\' names, addresses, phone numbers, and emails for the purpose of replying to the comments.</li>\n' +
                '</ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">4. Restriction of Usage and Provision</h2>\n' +
                '<ol>\n' +
                '    <li tabindex="0">\n' +
                '        The Agency for Cultural Affairs will not use collected information for any purpose other than those described in 3 above nor provide such information to Third Parties. This provision, however, shall not apply if there is a mandatory request for disclosure with a legal basis; if there have been illegal activities, such as unauthorized access and blackmailing; or if there are other special reasons. However, statistical information regarding access to this website or non-identifying information on users’ attributes may be made public.</li>\n' +
                '</ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">5. Security Measures</h2>\n' +
                '<ol>\n' +
                '    <li tabindex="0">\n' +
                '        The Agency for Cultural Affairs will take necessary measures to prevent the leakage, loss, and damage of personal information collected.</li>\n' +
                '</ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">6. Scope</h2>\n' +
                '<ol>\n' +
                '    <li tabindex="0">\n' +
                '        This Privacy Policy applies solely to information collected by this website (https://cb.bunka.go.jp/). In the event that information is forwarded to related ministries, the information will be handled under the responsibilities of the respective ministries.</li>\n' +
                '</ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">7. Other</h2>\n' +
                '<ol>\n' +
                '    <li tabindex="0">\n' +
                '        The Agency for Cultural Affairs may amend this Privacy Policy as necessary.</li>\n' +
                '</ol>\n' +
                '</div>',
            TermsOfUse: '<p tabindex="0" class="lead">THIS TERMS OF USE stipulates the basic terms and conditions regarding the exploitation, use\n' +
                '    and/or downloading functions for the digital contents of Japanese cultural properties, including but not limited to\n' +
                '    images, videos and sounds (hereinafter collectively referred to as "Contents"), provided in the webservice <span\n' +
                '        class="font-weight-bold">“Cultural Properties Digital Content Download Site”</span> (hereinafter referred to as\n' +
                '    "Contents Bank"), owned and operated by the Agency for Cultural Affairs, Government of Japan (hereinafter referred\n' +
                '    to as "ACA").</p>\n' +
                '<p tabindex="0" class="lead mb-40">Users must download or use the Contents of the Contents Bank in accordance with this\n' +
                '    Terms of Use, privacy policy, guidelines and other rules relating to the Contents Bank. By accessing and using the\n' +
                '    Contents Bank, user are deemed to have consented to the provisions of this Terms of Use. When downloading any\n' +
                '    Contents, users must agree to the provisions of this Terms of Use and other additional provisions for applicable\n' +
                '    Contents. Users may agree to all provisions hereof by checking the box at the end of this Terms of Use and clicking\n' +
                '    the “Download” button to download the Contents.</p>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">1. Subject</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            The purpose of the Contents Banks is to promote tourism in Japan by collecting and creating the Contents,\n' +
                '            including but not limited to images and videos, relating to world heritages in Japan, Japanese heritages,\n' +
                '            national treasures, and other important cultural properties (hereinafter collectively referred to as\n' +
                '            "Cultural Properties") and providing them to the public as the Contents, which are available for\n' +
                '            exploitation by users subject to the provisions of this Terms of Use.<br>\n' +
                '            Users may use the Contents of the Contents Bank, without limitation, as stipulated in the following Clause,\n' +
                '            but expressly excluding (i) the Contents which are subject to special additional provisions (hereinafter\n' +
                '            referred to as “Additional Provisions”) and (ii) any exploitation or use of the Contents prohibited under\n' +
                '            Clause 5 below.\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">2. Use of the Contents</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            2.1. All Contents of the Contents Bank are provided in accordance with the terms and conditions of the <a\n' +
                '            href="https://creativecommons.org/licenses/by/4.0/legalcode#s3" tabindex="0" target="_blank"\n' +
                '            class="accent-txt-terms">Creative Commons license [CC BY 4.0]</a>, except (i) when any Additional Provisions\n' +
                '            are applied to the Contents or (ii) for any exploitation of the Contents that are prohibited hereunder.<br>\n' +
                '            With regard to the Contents, users may, without limitation, copy, reproduce, broadcast, cablecast,\n' +
                '            distribute via online, present on-screen, distribute, and communicate to the public through a receiver, in\n' +
                '            accordance with this Terms of Use. Users may also change, modify or adapt the Contents for exploitation. The\n' +
                '            Contents may be used or exploited for commercial or business purposes. In addition to the foregoing, in the\n' +
                '            event of any revision or update of the Creative Commons license, it is deemed that the latest version of\n' +
                '            said license shall apply to the Contents Bank and/or the Contents.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            2.2. Due to the purpose of the Contents Bank, when users download, use or exploit the Contents in accordance\n' +
                '            with the provisions of this Terms of Use, users shall not be obligated to include the name of the author,\n' +
                '            copyright owner or its reference (but in any event, this term shall not prevent applying such notice) of the\n' +
                '            Contents, except for when such notice or credit is required as a condition under the Additional Provisions\n' +
                '            for the Contents.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            2.3. In the event it is specifically stipulated for users to include a notice of the reference, name of the\n' +
                '            owner of the Cultural Properties or others credits upon use of the Contents as the Additional Provisions\n' +
                '            thereof, users are required to click the “Accept” button at the end of the Additional Provisions, and to\n' +
                '            included any notices or credits as provided for each such Content.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            2.4. In the event other terms or conditions are stipulated as Additional Provisions for any Contents,\n' +
                '            including but not limited to prohibiting exploitation of the Contents for commercial or business purposes\n' +
                '            and requirement for users to notifying the owners of the Cultural Properties prior to use of the Contents,\n' +
                '            or other particular Additional Conditions stipulated under the Creative Commons license, users must click\n' +
                '            the “Accept” button of the Additional Provisions to download such Contents and must comply with such terms\n' +
                '            in good faith.\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">3. The rights of the Japanese Government</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            ACA owns the copyrights (including all rights provided for in Articles 21 through 28 of the Japanese\n' +
                '            Copyright Law; the same applying hereinafter), pertaining to all the Contents of Contents Bank. The\n' +
                '            preceding Clause does NOT mean copyrights are assigned or transferred to users.\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">4. Non-exclusive license</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            Users are hereby granted the non-exclusive license to exploit any and all Contents of the Contents Bank,\n' +
                '            subject to the provisions of this Terms of Use. Users may not NOT make any claim or object to the\n' +
                '            exploitation of the same or similar Contents by other third parties.\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">5. Prohibited use</h2>\n' +
                '    <p class="lead" tabindex="0">\n' +
                '        Users may NOT use or exploit any Contents of Contents Bank in any way as provided in the following terms:</p>\n' +
                '    <ol class="list">\n' +
                '        <li tabindex="0">\n' +
                '            (1) In a way that defames or intentionally causes injury to the reputation of the producer or creator of the\n' +
                '            Contents.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            (2) In a way that defames or intentionally causes injury to the reputation of the Cultural Properties which\n' +
                '            are included in the Contents or the owner of the Cultural Properties.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            (3) In a way that defames or intentionally causes injury to the reputation of the country of Japan, other\n' +
                '            countries or territories and/or any other third parties.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            (4) For the purpose of criminal acts or other acts against public policy.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            (5) For the purpose of preventing or disturbing the seamless service of the Contents in the Contents Bank.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            (6) In any other way that expressly deviates from social common sense.\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">6. Copyrights and the rights of subjects</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            6.1. Users must note that when using the Contents by agreeing to this Terms of Use, any moral rights,\n' +
                '            personal rights of any subject in the Contents, including but not limited to portrait rights, right of\n' +
                '            publicity, right of privacy and any other intellectual property rights shall be reserved by ACA and/or the\n' +
                '            author of the original works or the subjects within the Contents, and said rights will not be assigned or\n' +
                '            transferred to users.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            6.2. Users must note that certain restrictions or provisions are applied to the purpose or the use of the\n' +
                '            Contents due to the copyrights of Contents, the author\\s moral rights, other intellectual property rights\n' +
                '            and the personal rights of the subject of the Contents, including but not limited to portrait rights, right\n' +
                '            of publicity and right of privacy.\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">7. Prohibition of deceitful conduct</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            7.1. All Contents of Contents Bank are licensed by ACA to be exploited free of charge for any user subject\n' +
                '            to the provisions of this Terms of Use. Any deceitful conduct, such as authorizing third parties to exploit\n' +
                '            the Contents with or without charge by concealing and deceiving the copyrights of the Contents, shall be\n' +
                '            strictly prohibited.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            7.2. Any sales, distribution, transferring, renting or pledging of any images processed, edited and/or\n' +
                '            created from the Contents provided by the Contents Bank, to third parties who may exploit said images for\n' +
                '            the purpose stipulated under Clause 5 of this Terms of Use shall be strictly prohibited.\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">8. Indemnities</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            8.1. ACA does NOT warrant either expressly or impliedly, the absence of actual or legal defects (including\n' +
                '            but not limited to, defects regarding safety, reliability, accuracy, integrity, validity, conformity for\n' +
                '            specific purpose, security, errors or bugs or infringement of any rights) pertaining to the Contents of the\n' +
                '            Contents Bank. ACA has no obligation to constantly correct or eliminate said defects in providing the\n' +
                '            services of the Contents Bank to users.\n' +
                '        </li>\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            8.2. ACA shall NOT be liable for any form of disputes arising among the users or between a user and a third\n' +
                '            party, or any damages caused by any use of the Contents of the Contents Bank.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            8.3. ACA does NOT warrant that the Contents in the Contents Bank shall always be available to users. ACA\n' +
                '            shall NOT be liable for any damages which users or any third party may incur due to the availability of the\n' +
                '            Contents, regardless of the reasons, including but not limited to maintenance or inspection of the system of\n' +
                '            the Contents Bank.\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">9. Changes to the Contents Bank</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            9.1. ACA may change the way or means of supplying the Contents or the contents thereof, or seize to supply\n' +
                '            the Contents anytime without prior notice to users.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            9.2. ACA shall not be liable for any disputes or damages which may occur to users or any third party\n' +
                '            relating to the changes stipulated in the previous sub-clause.\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">10. Changes to these Terms</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            10.1. ACA may change or revise the terms and conditions of this Terms of Use anytime without prior notice to\n' +
                '            users.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            10.2. In the event users continue to exploit the Contents of the Contents Bank after any change or revision\n' +
                '            is made to this Terms of Use, they shall be deemed to have agreed to the new and changed provisions of the\n' +
                '            Terms of Use. Notwithstanding the foregoing, the new terms and conditions of the changed or revised Terms of\n' +
                '            Use shall not apply to any Contents licensed to users prior to such change or revision.\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">11. Assignment</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            In the event the Contents Bank is assigned or transferred to any third parties, ACA may assign or transfer\n' +
                '            any or all of its rights and obligations under this Terms of Use to any successors or assignees. Users\n' +
                '            hereby consent to the transfer or assignment by ACA in advance.\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">12. Governing law and jurisdiction</h2>\n' +
                '    <ol class="adjust">\n' +
                '        <li tabindex="0">\n' +
                '            12.1. The original version of this Terms of Use shall be in Japanese, and shall be governed by the laws of\n' +
                '            Japan.\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            12.2. Any disputes which may arise from or in relation to this Terms of Use shall be subject to the\n' +
                '            exclusive jurisdiction of the Tokyo District Court in Japan.\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '    <p tabindex="0">\n' +
                '        Office for Press and Information, Promotion of the "Power of Culture" Campaign, Policy Division, Agency for\n' +
                '        Cultural Affairs, Government of Japan<br>\n' +
                '        (Contractor: TBS SPARKLE, Inc.)<br>\n' +
                '        Established on March 25, 2020<br><br>\n' +
                '    </p>\n' +
                '    <p tabindex="0"><a href="https://www.inquiry.mext.go.jp/inquiry89/" target="_blank" class="accent-txt-terms">Opinions and Inquiries</a></p>\n' +
                '</div>\n',
            Accessibility: '<div class="contents-block">\n' +
                '<h2 class="heading02"><span tabindex="0">Test result display based on JIS X 8341-3: 2016 (set of web pages)</span></h2>\n' +
                '<p class="text" tabindex="0">The test result display based on JIS-X8341-3: 2016 (WCAG2.0) is as follows.</p>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">Achieved grade</span></h3>\n' +
                '<ul class="list">\n' +
                '<li tabindex="0">Achievement grade AA compliant</li>\n' +
                '<li tabindex="0">Achievement grade A compliant\n</li>\n' +
                '</ul>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">Range of complete web pages</span></h3>\n' +
                '<p class="text" tabindex="0">Complete set of "CULTURAL PROPERTIES DIGITAL CONTENT DOWNLOAD SITE"</p>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">List of pages tested\n</span></h3>\n' +
                '<div class="contents-block-inner">\n' +
                '<h4 class="heading04"><span tabindex="0">1.Japanese Version</span></h4>\n' +
                '<ul class="url-list">\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/contents/</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/contents/e19b7919-ce49-475b-9d06-9d6085abae7d</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/contents/viewing-history</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/contents/favorites</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/pages/privacy</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/pages/terms-of-use</li>\n' +
                '</ul>\n' +
                '</div>\n' +
                '<div class="contents-block-inner">\n' +
                '<h4 class="heading04"><span tabindex="0">2.English Version</span></h4>\n' +
                '<ul class="url-list">\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/contents/</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/contents/e19b7919-ce49-475b-9d06-9d6085abae7d</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/contents/viewing-history</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/contents/favorites</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/pages/privacy</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/pages/terms-of-use</li>\n' +
                '</ul>\n' +
                '</div>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">Dependent web content technology</span></h3>\n' +
                '<p class="text" tabindex="0">HTML5、CSS3、JavaScript</p>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">Success Criteria Checklist</span></h3>\n' +
                '<p class="text" tabindex="0">See "Achievement criteria checklist" below</p>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">Test period</span></h3>\n' +
                '<p class="text" tabindex="0">From March 2, 2020 to March 4, 2020</p>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">試験に使用したチェックツール</span></h3>\n' +
                '<ul class="list">\n' +
                '<li tabindex="0">W3C Markup Validation Service</li>\n' +
                '<li tabindex="0">miChecker</li>\n' +
                '<li tabindex="0">Color Contrast Analyzer</li>\n' +
                '<li tabindex="0">VoiceOver, NVDA</li>\n' +
                '</ul>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">User agent used for verification</span></h3>\n' +
                '<ul class="list">\n' +
                '<li tabindex="0">Internet Explorer 11 (Microsoft Windows 10)</li>\n' +
                '<li tabindex="0">Chrome (Microsoft Windows 10)</li>\n' +
                '<li tabindex="0">Safari 13 (Mac OS 10.14)</li>\n' +
                '</ul>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">Achievement standard check list based on JIS X 8341-3: 2016 (WCAG2.0)</span></h3>\n' +
                '<div class="accessibility-table">\n' +
                '<table>\n' +
                '<tr>\n' +
                '<th tabindex="0">Subclause</th>\n' +
                '<th tabindex="0">Success criteria</th>\n' +
                '<th tabindex="0">Explanation</th>\n' +
                '<th tabindex="0">grade</th>\n' +
                '<th tabindex="0">Application</th>\n' +
                '<th tabindex="0">Conform\t</th>\n' +
                '<th tabindex="0">Remarks</th>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.1.1</td>\n' +
                '<td tabindex="0">Achievement standard about non-text contents</td>\n' +
                '<td tabindex="0">For content other than text, such as images and buttons, provide alternate text that serves a similar purpose.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.2.1</td>\n' +
                '<td tabindex="0">Achievement standard about media including only recorded sound and media including only recorded video</td>\n' +
                '<td tabindex="0">For audio-only or video-only content, provide alternate text that serves a similar purpose.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.2.2</td>\n' +
                '<td tabindex="0">Achievement standard about caption of recorded audio contents</td>\n' +
                '<td tabindex="0">It provides subtitles (captions) with the same content as audio information for video content.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.2.3</td>\n' +
                '<td tabindex="0">Achievement standard about substitute contents of recorded video contents or audio guide</td>\n' +
                '<td tabindex="0">It provides alternative contents that convey video information, or audio guides such as sub-audio for moving image contents.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">-</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.2.4</td>\n' +
                '<td tabindex="0">Achievement standard about caption of live audio contents</td>\n' +
                '<td tabindex="0">It provides subtitles (captions) with the same content as audio information for live video content.</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">-</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.2.5</td>\n' +
                '<td tabindex="0">Achievement standard about audio guide of recorded video contents</td>\n' +
                '<td tabindex="0">Provide audio guides such as sub-audio that conveys video information for video content.</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">-</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.3.1</td>\n' +
                '<td tabindex="0">Achievement standard about information and relation</td>\n' +
                '<td tabindex="0">Information is implemented so that the program can interpret the meaning of the information.\n' +
                '(Example) Lists are not expressed by "." In the text, but the list (bullet) function in the system is used.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.3.2</td>\n' +
                '<td tabindex="0">Success Criteria for Meaningful Order</td>\n' +
                '<td tabindex="0">Arrange the contents in a meaningful order so that the information can be transmitted in the intended order even when using voice reading software.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.3.3</td>\n' +
                '<td tabindex="0">Achievement standard about sensory characteristic</td>\n' +
                '<td tabindex="0">Since voice-reading software cannot understand sensory information such as shape, size, and direction, the information is transmitted in text.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.4.1</td>\n' +
                '<td tabindex="0">Since voice-reading software cannot understand sensory information such as shape, size, and direction, the information is transmitted in text.</td>\n' +
                '<td tabindex="0">When using color to convey information, not only the color, but also the text.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.4.2</td>\n' +
                '<td tabindex="0">Achievement standard about voice control</td>\n' +
                '<td tabindex="0">Prevent audio such as music from playing automatically when the page is opened. Also, enable the user to control playback / pause.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.4.3</td>\n' +
                '<td tabindex="0">Achievement standard about minimum contrast</td>\n' +
                '<td tabindex="0">The visual representation of the text and imaged characters and the background color shall have a contrast ratio of at least 4.5: 1.\n' +
                'However, large text and large imaged characters (18-point text or 14-point bold text) require a contrast ratio of at least 3: 1.</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.4.4</td>\n' +
                '<td tabindex="0">Achievement standard about size change of text</td>\n' +
                '<td tabindex="0">The size of the text is not fixed, but it can be expanded to 200% with a function such as a browser so that the information can be understood in the same way as normal size.</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.4.5</td>\n' +
                '<td tabindex="0">Achievement standard about imaged letter</td>\n' +
                '<td tabindex="0">Except for essential items such as logos, text should be provided in text without image as much as possible.</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.1.1</td>\n' +
                '<td tabindex="0">Achievement standard about keyboard operation</td>\n' +
                '<td tabindex="0">All functions on the page can be operated only by the keyboard. However, functions that use the mouse trajectory, such as the paint function, are excluded.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.1.2</td>\n' +
                '<td tabindex="0">Achievement standard about focus movement</td>\n' +
                '<td tabindex="0">When you use a keyboard to access a function on a page, use the keyboard to get out of that function.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.2.1</td>\n' +
                '<td tabindex="0">Achievement standard of timing adjustment</td>\n' +
                '<td tabindex="0">When setting  time limit for using content, allow the user to cancel the set time.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">-</td>\n' +
                '<td tabindex="0">-</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.2.2</td>\n' +
                '<td tabindex="0">Achievement standard about suspension, stop and non-display</td>\n' +
                '<td tabindex="0">For moving content such as slide shows and videos, users can pause, stop, and play.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">-</td>\n' +
                '<td tabindex="0">-</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.3.1</td>\n' +
                '<td tabindex="0">Achievement standard about less than three times (flash) light or threshold (threshold) value</td>\n' +
                '<td tabindex="0">If you have flashing content, make the flashing area small enough, no more than three times per second.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.1</td>\n' +
                '<td tabindex="0">Achievement standard about block skip</td>\n' +
                '<td tabindex="0">Install a function that allows you to skip menus such as navigation.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.2</td>\n' +
                '<td tabindex="0">Achievement standard about page title</td>\n' +
                '<td tabindex="0">Give a page title that shows the purpose of the page and its relevance to the site.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.3</td>\n' +
                '<td tabindex="0">Achievement standard about focus order</td>\n' +
                '<td tabindex="0">Links, buttons, etc. should be able to move the focus in the order according to the order of contents and their relevance.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.4</td>\n' +
                '<td tabindex="0">Achievement standard about purpose of link in context</td>\n' +
                '<td tabindex="0">Whenever possible, text links should help you understand the linked page.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.5</td>\n' +
                '<td tabindex="0">Achievement standard about plural means of arrival</td>\n' +
                '<td tabindex="0">Prepare some means to visit each page.</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.6</td>\n' +
                '<td tabindex="0">Achievement standard about heading and label</td>\n' +
                '<td tabindex="0">Make the purpose and contents of the headings and labels clear.</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.7</td>\n' +
                '<td tabindex="0">Achievement standard about visually recognizable focus</td>\n' +
                '<td tabindex="0">When the focus is moved to the content by using the keyboard, visually recognize that the focus is moved.</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.1.1</td>\n' +
                '<td tabindex="0">Achievement standard about page language</td>\n' +
                '<td tabindex="0">Use language attributes to help programs understand what the page’s primary language is.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.1.2</td>\n' +
                '<td tabindex="0">Success Criteria for Partially Used Languages</td>\n' +
                '<td tabindex="0">When a non-primary language appears on a page, use language attributes to help the program understand what the language is.</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.2.1</td>\n' +
                '<td tabindex="0">Achievement standard about on-focus</td>\n' +
                '<td tabindex="0">All content should not change state (execute) when it receives focus.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.2.2</td>\n' +
                '<td tabindex="0">Achievement standard about situation change by user interface component</td>\n' +
                '<td tabindex="0">Do not change the state (execute) just by entering text in the form or checking the radio button. Be sure to prepare an execute button.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.2.3</td>\n' +
                '<td tabindex="0">Achievement standard about consistent navigation</td>\n' +
                '<td tabindex="0">Navigation such as menus that are repeatedly displayed on multiple pages should be displayed in the same order on every page.</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.2.4</td>\n' +
                '<td tabindex="0">Achievement standard about consistent distinctiveness</td>\n' +
                '<td tabindex="0">All websites that have the same function have the same label, alternative text, and distinguished name.</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.3.1</td>\n' +
                '<td tabindex="0">Achievement standard about identification of input error point</td>\n' +
                '<td tabindex="0">If there is an input error in the input form such as inquiry, inform the information in text so that the content of the error can be easily transmitted.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">-</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.3.2</td>\n' +
                '<td tabindex="0">Achievement standard about label or explanation</td>\n' +
                '<td tabindex="0">In the input form for inquiries, etc., present an appropriate label to the form, and also indicate whether or not it is mandatory, input restrictions (only half-width alphanumeric characters, etc.) and input examples to prevent users from making input mistakes.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">-</td>\n' +
                '<td tabindex="0">-</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.3.3</td>\n' +
                '<td tabindex="0">Achievement standard about presentation of input error correction method</td>\n' +
                '<td tabindex="0">If you make a mistake in the input form for inquiries, etc., suggest correction candidates that you think are appropriate.</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.3.4</td>\n' +
                '<td tabindex="0">Achievement standard about legal obligation, financial transaction, data modification and error avoidance of reply transmission</td>\n' +
                '<td tabindex="0">When users have legal obligations such as income tax filings or when money is exchanged, make sure that you can confirm and correct information before sending it, so that users do not make mistakes To do so.</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.4.1.1</td>\n' +
                '<td tabindex="0">Achievement standard about parsing</td>\n' +
                '<td tabindex="0">HTML, CSS, etc. of web pages should comply with the specifications.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.4.1.2</td>\n' +
                '<td tabindex="0">Achievement standard about distinguished name, role and settable value that program can interpret</td>\n' +
                '<td tabindex="0">HTML, CSS, etc. of web pages Each feature of a web page complies with the specifications and allows a program such as a browser to interpret the information.should comply with the specifications.</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '</table>\n' +
                '</div>\n' +
                '</div>',
        }
    },
    ja: {
        siteInfo: {
            top: 'トップ',
            title: '文化財デジタルコンテンツダウンロード機能',
            description: '日本の国宝・重要文化財等の文化財とその地域の魅力を伝えるための多言語情報、' +
                '高精細な動画・静止画素材をダウンロードすることができます。',
            contents: '検索結果',
            favorites: 'お気に入り',
            histories: '閲覧履歴'
        },
        messages: {
            headerTitle: '文化財デジタルコンテンツ',

            headerText: '日本の国宝・重要文化財等の文化財とその地域の魅力を伝えるための多言語情報、' + '<br>' +
                '高精細な動画・静止画素材をダウンロードすることができます。',

            topInfo: '利用規約及び' +
                '<br class="d-inline d-md-none">' +
                'クリエイティブ・コモンズ・ライセンス表示に' +
                '<br class="d-inline d-md-none">' +
                '従って二次利用が可能です。' +
                '<br>' +
                '訪日観光客に対応する着地整備や' +
                '<br class="d-inline d-md-none">' +
                '新たなコンテンツ創造などに' +
                '<br class="d-inline d-md-none">' +
                'ご活用ください。',

            vr: '超高精細VR・パノラマ',
            japaneseSeasons: '日本の四季',
            seasons: {
                spring: '春',
                summer: '夏',
                autumn: '秋',
                winter: '冬'
            },
            castles: '城郭',
            temple: '神社仏閣',
            japanHeritage: '日本遺産ストーリー',
            worldHeritage: '世界遺産',
            festivals: '祭',
            traditionalEvents: '伝統行事',
            english: {
                vr: 'Ultra High Definition VR / Panorama',
                japaneseSeasons: 'Japanese Four Seasons',
                seasons: {
                    spring: 'Spring',
                    summer: 'Summer',
                    autumn: 'Autumn',
                    winter: 'Winter'
                },
                castles: 'Castles',
                temple: 'Temples and Shrines',
                japanHeritage: 'Japan Heritage Stories',
                worldHeritage: 'World Heritage',
                festivals: 'Festivals',
                traditionalEvents: 'Traditional Events',
            }
        },
        buttons: {
            top: 'トップ',
            viewingHistory: '閲覧履歴',
            favorites: 'お気に入り',
            howToUse: 'ご利用規約',
            searchCriteria: '絞込み検索',
            categories: '分類',
            culturalPropertyType: '文化財区分',
            seasons: '季節',
            regions: '地域',
            people: '人物',
            all: 'すべて',
            photos: '静止画',
            videos: '動画',
            photosAndVideos: '静止画・動画',
            vr: 'VR',
            vrPhotos: 'VR静止画',
            vrVideos: 'VR動画',
            videoTypes: '動画種別',
            licensing: 'ライセンス',
            search: '検索',
            searching: '検索中...',
            clear: '条件クリア',
            privacy: 'プライバシーポリシー',
            cookie: 'クッキーポリシー',
            copyright: 'コピーライト',
            terms: '利用規約',
            contact: 'お問い合わせ',
            accessibility: 'アクセシビリティ',
            keywordSearch: '選択したキーワードで検索',
            removeContents: 'お気に入りから削除',
            agree: '同意する',
            disagree: '同意しない',
        },
        breadcrumbs: {
            top: 'トップページに関するパンくずリスト',
            searchResults: '検索結果に関するパンくずリスト'
        },
        numberOfHits: {
            searchResults: '検索結果',
            results: '件'
        },
        toolbars: {
            styles: '表示スタイル',
            perPage: '表示数',
            sortBy: '表示順',
            createdAt: '新着順',
            photographedAt: '撮影年月日'
        },
        contents: {
            aboutTheWork: '作品情報',
            aboutTheCulturalProperty: '文化遺産情報',
            title: 'タイトル',
            subtitle: 'サブタイトル',
            date: '撮影年月日',
            photographed: '撮影年月日',
            filmed: '撮影年月日',
            licensing: 'ライセンス表記',
            codec: 'コーデック',
            fps: 'フレームレート',
            duration: '尺',
            size: 'サイズ',
            name: '名称',
            designation: '指定区分',
            year: '西暦',
            location: '所在地',
            description: '詳細情報',
            vrLink: 'VR動画を見る',
            imageVr: '画像をつかんでドラックすることで360度閲覧ができます。',
            addToFavorites: 'お気に入りに追加',
            removeFromFavorites: 'お気に入りから削除',
            favorites: 'お気に入り',
            zoom: '拡大',
            closePreview: '閉じる',
            content: '詳細情報',
            relatedContents: '関連作品',
            relatedTerms: '関連キーワード',
            viewingHistory: '閲覧履歴',
            opinionsAndInquiries: '御意見・お問合せ',
            readMore: 'もっと見る',
            seeMore: 'もっと見る',
            loading: '検索中です。このままお待ちください。',
            noResults: '検索条件に一致するコンテンツはありませんでした。'
        },
        downloads: {
            textData: 'テキストデータ',
            format: '34k / txt 形式',
            agree: '<a href="/ja/pages/terms-of-use" tabindex="0" target="_blank">'
                + '利用規約' + '</a>' + 'に同意します',
            download: 'ダウンロード',
            chooseSize: '選択してください。',
            canDownloadTextData: 'テキストデータを選択するとダウンロードできます。',
            vrTextData: 'ダウンロード後、VRコンテンツをご利用いただくには専用ブラウザやアプリケーションが必要になります。',
            additionalTerms: 'この素材を使用するには追加利用規約への同意が必要です。',
            readAdditionalTerms: '追加利用規約を読む'
        },
        searchConditions: {
            culturalPropertyTypes: {
                worldHeritage: '世界遺産',
                japaneseHeritage: '日本遺産',
                nationalTreasure: '国宝',
                important: '重要文化財',
                other: 'その他'
            },
            seasons: {
                spring: '春',
                summer: '夏',
                autumn: '秋',
                winter: '冬'
            },
            regions: {
                hokkaido: '北海道',
                tohoku: '東北',
                kanto: '関東',
                hokuriku: '北陸信越',
                tokai: '東海',
                kansai: '関西',
                chugoku: '中国',
                shikoku: '四国',
                kyusyu: '九州',
                okinawa: '沖縄'
            },
            people: {
                exist: 'あり',
                notExist: 'なし'
            },
            photos: {
                length: '縦',
                width: '横'
            },
            imageTypes: {
                vr: '360VR',
            },
            videos: {
                duration: {
                    less10Seconds: '10秒未満',
                    more10Seconds: '10秒以上',
                    more30Seconds: '30秒以上',
                    more60Seconds: '60秒以上'
                }
            },
            videoTypes: {
                edited: '編集済み',
                material: '素材',
                vr: 'VR',
            },
            licenses: {
                all: '全ての利用可能',
                impossible: '商用利用不可・条件あり',
                notation: '表記条件あり',
                others: 'その他条件あり',
                iseShrine: 'その他条件あり（伊勢神宮）',
                izumoShrine: 'その他条件あり（出雲大社）',
                todaiji: 'その他条件あり（東大寺）'
            },
            traditionalEvents: '伝統行事',
            additionalRestrictions: {
                dazaifu: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク<br>' +
                    '太宰府天満宮コンテンツ利用にあたっての付加規約</h3>\n' +
                    '    <p tabindex="0">文化庁コンテンツバンクで提供する太宰府天満宮の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　クレジットの表記<br>\n' +
                    '            太宰府天満宮の画像・映像等のコンテンツの利用にあたって，下記のクレジット表記を必ず行なってください。<br>\n' +
                    '            提供：　太宰府天満宮<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-2　商用利用不可<br>\n' +
                    '            太宰府天満宮の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                isejingu: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク<br>' +
                    '神宮コンテンツ利用にあたっての付加規約</h3>\n' +
                    '    <p tabindex="0">文化庁コンテンツバンクで提供する神宮の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-１　商用利用不可<br>\n' +
                    '            神宮の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-２　コンテンツの加工不可<br>\n' +
                    '            神宮の画像・映像等のコンテンツの利用では，コンテンツを加工しての公開はできません。映像コンテンツの使用箇所を編集する等の軽微の加工は可能ですが，神宮の尊厳を損なうような画像処理・レイアウト・説明・キャプション・ナレーション･テロップ表示などは出来ません。<br>\n' +
                    '            付加条件-１.2により，クリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利-改変禁止4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-3　使用申請<br>\n' +
                    '            神宮の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、 かならず事前に下記の神宮司庁へ利用申請のご連絡をお願いします。<br>\n' +
                    '            神宮司庁　広報室　TEL０５９６－２４－１１１１<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                kunozan: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク<br>' +
                    '久能山東照宮コンテンツ利用にあたっての付加規約</h3>\n' +
                    '    <p tabindex="0">文化庁コンテンツバンクで提供する久能山東照宮の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　商用利用不可<br>\n' +
                    '            久能山東照宮の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります。<br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                murakami: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク<br>' +
                    '“日本最大の海賊”の本拠地：芸予諸島<br>\n' +
                    '        コンテンツ利用にあたっての付加規約\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">文化庁コンテンツバンクで提供する“日本最大の海賊”の本拠地：芸予諸島の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　使用申請<br>\n' +
                    '            【①\t羽織（赤）・鎧（黒）】<br>\n' +
                    '            今治市村上水軍博物館保管資料の画像・映像等のコンテンツ利用では，今治市村上水軍博物館への使用申請が必要です。かならず事前に下記の今治市村上水軍博物館へ特別利用申請のご連絡をお願いします。<br>\n' +
                    '            今治村上海賊ミュージアム<br>\n' +
                    '            TEL 0897-74-1065 e-mail i2922@imabari-city.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                murakamiForChosenji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク<br>\n' +
                    '        “日本最大の海賊”の本拠地：芸予諸島<br>\n' +
                    '        コンテンツ利用にあたっての付加規約\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">文化庁コンテンツバンクで提供する“日本最大の海賊”の本拠地：芸予諸島の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　使用申請<br>\n' +
                    '            【①\t羽織（赤）・鎧（黒）】<br>\n' +
                    '            今治市村上水軍博物館保管資料の画像・映像等のコンテンツ利用では，今治市村上水軍博物館への使用申請が必要です。かならず事前に下記の今治市村上水軍博物館へ特別利用申請のご連絡をお願いします。<br>\n' +
                    '            今治村上海賊ミュージアム<br>\n' +
                    '            TEL 0897-74-1065 e-mail i2922@imabari-city.jp<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            【②\t船団の絵　※朝鮮通信使上関来航図】<br>\n' +
                    '            山口県上関町の超専寺所蔵の画像のコンテンツの利用にあたっては， かならず事前に下記\n' +
                    '            の山口県上関町教育委員会へ特別利用申請のご連絡をお願いします。<br>\n' +
                    '            山口県上関町教育委員会<br>\n' +
                    '            TEL 0820-62-1460 e-mail kyouiku2@town.kaminoseki.lg.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                nachikumanotaisha: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク' +
                    '<br>熊野那智大社コンテンツ利用にあたっての付加規約</h3>\n' +
                    '    <p tabindex="0">文化庁コンテンツバンクで提供する熊野那智大社の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　クレジットの表記<br>\n' +
                    '            熊野那智大社の熊野那智大社、那智の滝、大門坂は画像・映像等のコンテンツの利用にあたって，下記のクレジット表記を必ず行なってください。<br>\n' +
                    '            クレジット名：熊野那智大社<br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                nageiredou: '<div class="additional">\n' +
                    '    <h3 class="contents-title" tabindex="0">文化庁コンテンツバンク三徳山三佛寺奥の院「投入堂」\n' +
                    '        <span>コンテンツ利用にあたっての付加規約</span>\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">文化庁コンテンツバンクで提供する三徳山三佛寺奥の院「投入堂」の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br>\n' +
                    '    </p>\n' +
                    '    <ul class="note">\n' +
                    '        <li tabindex="0">\n' +
                    '            <h2 class="note__title">付加条件-1　クレジットの表記</h2>\n' +
                    '            <p class="note__txt">三徳山三佛寺投入堂の画像・映像等のコンテンツの利用にあたって，下記のクレジット表記を必ず行なってください。<br>\n' +
                    '            三徳山三佛寺奥の院「投入堂」　Mitokusan Sanbutsuji Nageiredo</p>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            <h2 class="note__title">付加条件-2　使用申請</h2>\n' +
                    '            <p class="note__txt">三徳山三佛寺投入堂の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法ついて、かならず事前に下記の三徳山三佛寺へ利用申請のご連絡をお願いします。<br>\n' +
                    '            三徳山三佛寺　TEL0858-43-2666</p>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                sazaedo: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　円通三匝堂（会津さざえ堂）<br>\n' +
                    '        コンテンツ利用にあたっての付加規約</h3>\n' +
                    '    <p tabindex="0">文化庁コンテンツバンクで提供する円通三匝堂（会津さざえ堂）の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　商用利用不可<br>\n' +
                    '            円通三匝堂（会津さざえ堂）の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります<br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                takeoonsen: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク武雄温泉楼門・武雄温泉新館<br>\n' +
                    '        コンテンツ利用にあたっての付加規約\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">文化庁コンテンツバンクで提供する武雄温泉楼門・武雄温泉新館の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　クレジットの表記<br>\n' +
                    '            武雄温泉楼門・武雄温泉新館の画像・映像等のコンテンツの利用にあたって，下記のクレジット表記を必ず行なってください。<br>\n' +
                    '            映像協力：　武雄温泉株式会社<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-2　商用利用不可<br>\n' +
                    '            武雄温泉楼門・武雄温泉新館の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                yashimaji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　屋島寺コンテンツ利用にあたっての付加規約\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">文化庁コンテンツバンクで提供する久屋島寺の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　商用利用不可<br>\n' +
                    '            屋島寺の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                about: '<div class="additional">\n' +
                    '<h3 tabindex="0">文化庁コンテンツバンク　日本遺産について<br>\n' +
                    'コンテンツ利用にあたっての付加規約\n' +
                    '</h3>\n' +
                    '<p tabindex="0">文化庁コンテンツバンクで提供する日本遺産についての画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。\n' +
                    '</p>\n' +
                    '<ul>\n' +
                    '<li tabindex="0">\n' +
                    '付加条件-1　使用申請<br>\n' +
                    '【①\t羽織（赤）・鎧（黒）】<br>\n' +
                    '今治市村上水軍博物館保管資料の画像・映像等のコンテンツ利用では，今治市村上水軍博物館への使用申請が必要です。かならず事前に下記の今治市村上水軍博物館へ特別利用申請のご連絡をお願いします。<br>\n' +
                    '今治村上海賊ミュージアム <br>\n' +
                    'TEL 0897-74-1065 e-mail i2922@imabari-city.jp\n' +
                    '</li>\n' +
                    '</ul>\n' +
                    '</div>',
                ama: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　鳥羽・志摩の海女漁の技術コンテンツ利用にあたっての 付加規約\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">文化庁コンテンツバンクで提供する鳥羽・志摩の海女漁の技術の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。\n' +
                    '        <br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　クレジットの表記<br>\n' +
                    '            鳥羽・志摩の海女漁の技術の画像・映像等のコンテンツの利用にあたって，下記のクレジット表記を必ず行なってください。<br>\n' +
                    '            提供：　海女小屋体験施設さとうみ庵<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                asukadera: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　飛鳥寺コンテンツ利用にあたっての付加規約\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">文化庁コンテンツバンクで提供する飛鳥寺跡、飛鳥大仏の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。\n' +
                    '        <br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　使用申請<br>\n' +
                    '            飛鳥寺跡、飛鳥大仏の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の飛鳥寺へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            飛鳥寺<br>\n' +
                    '            TEL　０７４４―５４－２１２６<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                futagoji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　両子寺コンテンツ利用にあたっての付加規約\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する両子寺の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　使用申請<br>\n' +
                    '            両子寺の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li>\n' +
                    '            両子寺　TEL０９７８－６５－０２５３<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                gyodaCityLocalMuseum: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　行田市郷土資料館　コンテンツ利用にあたっての付加規約\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する行田市郷土博物館所蔵資料の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　使用申請<br>\n' +
                    '            行田市郷土博物館所蔵資料の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について企画概要を作成のうえ、かならず事前に下記の行田市郷土博物館へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li>\n' +
                    '            行田市郷土博物館<br>\n' +
                    '            TEL ０４８－５５４－５９１１<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                hakoneSekisho: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　箱根旧街道「箱根関所」コンテンツ利用にあたっての付加規約\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する箱根旧街道「箱根関所」の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　商用利用不可<br>\n' +
                    '            箱根旧街道「箱根関所」の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-２　コンテンツの加工不可<br>\n' +
                    '            箱根旧街道「箱根関所」の画像・映像等のコンテンツの利用では，コンテンツを加工しての公開はできません。映像コンテンツの使用箇所を編集する等の軽微の加工は可能ですが，画像処理・レイアウト・説明・キャプション・ナレーション･テロップ表示などは出来ません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【改変禁止4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-３　使用申請<br>\n' +
                    '            箱根旧街道「箱根関所」の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の箱根関所へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li>\n' +
                    '            箱根関所<br>\n' +
                    '            TEL　 0460－83－6635<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                jionji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　慈恩寺コンテンツ利用にあたっての付加規約\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する慈恩寺の仏像の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　使用申請<br>\n' +
                    '            慈恩寺の仏像の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の宗教法人本山慈恩寺へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li>\n' +
                    '            宗教法人本山慈恩寺　TEL０２３７－８７－３９９３<br>\n' +
                    '            e-mail  customer@honzan-jionji.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                kamakurabungakukan: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　鎌倉文学館コンテンツ利用にあたっての付加規約\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する鎌倉文学館の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　　商用利用不可<br>\n' +
                    '            鎌倉文学館の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-2　使用申請<br>\n' +
                    '            鎌倉文学館の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の鎌倉文学館へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li>\n' +
                    '            鎌倉文学館　TEL 0467-23-3911<br>\n' +
                    '            e-mail bungakukan@kamakura-arts.or.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                kashiharajingu: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　深田池コンテンツ利用にあたっての付加規約\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する深田池の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　商用利用不可<br>\n' +
                    '            深田池の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-２　コンテンツの加工不可<br>\n' +
                    '            深田池の画像・映像等のコンテンツの利用では，コンテンツを加工しての公開はできません。映像コンテンツの使用箇所を編集する等の軽微の加工は可能ですが，橿原神宮の尊厳を損なう様な変更・画像処理・レイアウト・説明・キャプション・ナレーション･テロップ表示などは出来ません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【改変禁止4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-３　使用申請<br>\n' +
                    '            深田池の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の橿原神宮へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li>\n' +
                    '            橿原神宮 総務部 教化渉外課<br>\n' +
                    '            メール：kasiharajingu_syougai@yahoo.co.jp<br>\n' +
                    '            TEL: 0744（22）3271<br>\n' +
                    '            FAX:0744（24）7720<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                kenchoji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　建長寺コンテンツ利用にあたっての付加規約\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する建長寺の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　商用利用不可<br>\n' +
                    '            建長寺の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-２　使用申請<br>\n' +
                    '            建長寺の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の臨済宗建長寺派宗務本院　内務部へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            臨済宗建長寺派宗務本院　内務部　TEL０４６７－２２－０９８１<br>\n' +
                    '            ※日本語での対応となります。<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                kishutoshogu: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　紀州東照宮　コンテンツ利用にあたっての付加規約\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する紀州東照宮の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　クレジットの表記<br>\n' +
                    '            紀州東照宮の画像・映像等のコンテンツの利用にあたって，下記のクレジット表記を必ず行なってください。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            クレジット名：紀州東照宮<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                konojinjya: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　籠神社コンテンツ利用にあたっての付加規約\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する籠神社のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　使用申請<br>\n' +
                    '            籠神社のコンテンツの利用にあたっては、具体的な使用方法等について、事前に籠神社の承認を得るようお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            籠神社<br>\n' +
                    '            TEL　0772-27-0006<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                oosakihachimangu: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　大崎八幡宮コンテンツ利用にあたっての付加規約\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">文化庁コンテンツバンクで提供する大崎八幡宮の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　　使用申請<br>\n' +
                    '            大崎八幡宮の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の大崎八幡宮　社務所へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            大崎八幡宮　社務所<br>\n' +
                    '            TEL　０２２-２３４-３６０６<br>\n' +
                    '            e-mail oosaki@oosaki-hachiman.or.jp<br><br>\n' +
                    '\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                sendaitoshogu: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　仙台東照宮コンテンツ利用にあたっての付加規約\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する仙台東照宮の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください\n' +
                    '        <br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　　使用申請<br>\n' +
                    '            仙台東照宮の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            仙台東照宮<br>\n' +
                    '            TEL ０２２－２３４－３２４７<br>\n' +
                    '            E-mail: sakura@s-toshogu.com<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                shurijoato: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　首里城跡コンテンツ利用にあたっての付加規約\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する首里城跡の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　クレジットの表記<br>\n' +
                    '            首里城跡の画像・映像等のコンテンツの利用にあたって，下記のクレジット表記を必ず行なってください。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            提供：　国営沖縄記念公園（首里城公園）\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                wakayamajo: '<div class="additional">\n' +
                    '    <h3 tabindex="0">文化庁コンテンツバンク　和歌山城コンテンツ利用にあたっての付加規約\n' +
                    '\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する和歌山城のドローン映像のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　使用申請<br>\n' +
                    '            和歌山城のドローン映像のコンテンツの利用にあたっては、具体的な使用方法等について、事前に和歌山市の承認を得るようお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            和歌山市　和歌山城整備企画課<br>\n' +
                    '            TEL　073-435-1044<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                nihonkoku: '<div class="additional">\n' +
                    '    <h3 class="contents-title" tabindex="0">\n' +
                    '        文化庁コンテンツバンク<br>　\n' +
                    '        日本国創成のとき―飛鳥を翔（かけ）た女性たち―　ストーリー動画<br>\n' +
                    '        コンテンツ利用にあたっての付加規約\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する深田池、飛鳥寺跡、飛鳥大仏の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul class="note">\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　商用利用不可<br>\n' +
                    '            深田池の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-２　コンテンツの加工不可<br>\n' +
                    '            深田池の画像・映像等のコンテンツの利用では，コンテンツを加工しての公開はできません。映像コンテンツの使用箇所を編集する等の軽微の加工は可能ですが，橿原神宮の尊厳を損なう様な変更・画像処理・レイアウト・説明・キャプション・ナレーション･テロップ表示などは出来ません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【改変禁止4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-３　使用申請<br>\n' +
                    '            深田池の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の橿原神宮へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            橿原神宮 総務部 教化渉外課<br>\n' +
                    '            メール：kasiharajingu_syougai@yahoo.co.jp<br>\n' +
                    '            TEL: 0744（22）3271<br>\n' +
                    '            FAX:0744（24）7720<br><br>\n' +
                    '        </li>\n' +
                    '                <li tabindex="0">\n' +
                    '            付加条件-４　使用申請<br>\n' +
                    '            飛鳥寺跡、飛鳥大仏の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の飛鳥寺へ利用申請のご連絡をお願いします<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '        飛鳥寺<br>\n' +
                    '        TEL　０７４４―５４－２１２６<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                wakanoura: '<div class="additional">\n' +
                    '    <h3 class="contents-title" tabindex="0">\n' +
                    '        文化庁コンテンツバンク　絶景の宝庫 和歌の浦　ストーリー動画<br>　\n' +
                    '        コンテンツ利用にあたっての付加規約\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する紀州東照宮、和歌山城のドローン映像の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul class="note">\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　クレジットの表記<br>\n' +
                    '            紀州東照宮の画像・映像等のコンテンツの利用にあたって，下記のクレジット表記を必ず行なってください。<br>\n' +
                    '            クレジット名：紀州東照宮<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-2　使用申請<br>\n' +
                    '            和歌山城のドローン映像のコンテンツの利用にあたっては、具体的な使用方法等について、事前に和歌山市の承認を得るようお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            和歌山市　和歌山城整備企画課<br>\n' +
                    '            TEL　073-435-1044<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                izakamakura: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        文化庁コンテンツバンク<br>　\n' +
                    '        「いざ，鎌倉」～歴史と文化が描くモザイク画のまちへ～　ストーリー動画<br>\n' +
                    '        コンテンツ利用にあたっての付加規約<br><br>\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する建長寺、鎌倉文学館の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　商用利用不可<br>\n' +
                    '            建長寺の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-２　使用申請<br>\n' +
                    '            建長寺の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の臨済宗建長寺派宗務本院　内務部へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li>\n' +
                    '        臨済宗建長寺派宗務本院　内務部　TEL０４６７－２２－０９８１<br>\n' +
                    '        ※日本語での対応となります。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-３　　商用利用不可<br>\n' +
                    '            鎌倉文学館の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-４　使用申請<br>\n' +
                    '            鎌倉文学館の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の鎌倉文学館へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '        鎌倉文学館　TEL 0467-23-3911<br>\n' +
                    '            e-mail bungakukan@kamakura-arts.or.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                masamune: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        文化庁コンテンツバンク　政宗が育んだ“伊達”な文化　ストーリー動画 コンテンツ利用にあたっての付加規約<br><br>\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する大崎八幡宮・仙台東照宮の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。<br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　　使用申請<br>\n' +
                    '            大崎八幡宮の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の大崎八幡宮　社務所へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            大崎八幡宮　社務所<br>\n' +
                    '            TEL　０２２-２３４-３６０６<br>\n' +
                    '            e-mail oosaki@oosaki-hachiman.or.jp<br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-2　　使用申請<br>\n' +
                    '            仙台東照宮の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記へ利用申請のご連絡をお願いします。<br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            仙台東照宮<br>\n' +
                    '            TEL ０２２－２３４－３２４７<br>\n' +
                    '            E-mail: sakura@s-toshogu.com<br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                kongoji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        文化庁コンテンツバンク　天野山金剛寺コンテンツ利用にあたっての付加規約\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する天野山金剛寺の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。\n' +
                    '        <br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　クレジットの表記<br>\n' +
                    '            天野山金剛寺の画像・映像等のコンテンツの利用にあたって，下記のクレジット表記を必ず行なってください。<br>\n' +
                    '            提供：天野山金剛寺\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-２　商用利用不可<br>\n' +
                    '            天野山金剛寺の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります。\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-3　コンテンツの加工不可<br>\n' +
                    '            天野山金剛寺の画像・映像等のコンテンツの利用では，コンテンツを加工しての公開はできません。映像コンテンツの使用箇所を編集する等の軽微の加工は可能ですが，画像処理・レイアウト・説明・キャプション・ナレーション･テロップ表示などは出来ません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【改変禁止4.0国際】となります。\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-４　使用申請<br>\n' +
                    '            天野山金剛寺の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の天野山金剛寺へ利用申請のご連絡をお願いします。\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            天野山金剛寺<br>\n' +
                    '            TEL: 0721-52-2046<br>\n' +
                    '            ※日本語での対応となります。<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                muroji: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        文化庁コンテンツバンク　女性とともに今に息づく女人高野　～時を超え、時に合わせて見守り続ける癒しの聖地～\n' +
                    '        　ストーリー動画　コンテンツ利用にあたっての付加規約\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する大本山室生寺の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。\n' +
                    '\n' +
                    '        <br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　　使用申請<br>\n' +
                    '            大本山室生寺の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記へ利用申請のご連絡をお願いします。\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            真言宗室生寺派　大本山室生寺<br>\n' +
                    '            TEL: 0745-93-2003<br>\n' +
                    '            FAX: 0745-93-2057<br>\n' +
                    '            Mail: yamato-murouji@kcn.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>',
                nyonnkoya: '<div class="additional">\n' +
                    '    <h3 tabindex="0" class="text-center">\n' +
                    '        文化庁コンテンツバンク<br>\n' +
                    '        女性とともに今に息づく女人高野　～時を超え、時に合わせて見守り続ける癒しの聖地～<br>\n' +
                    '        ストーリー動画　コンテンツ利用にあたっての付加規約\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する大本山室生寺の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。\n' +
                    '        <br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　　使用申請<br>\n' +
                    '            大本山室生寺の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記へ利用申請のご連絡をお願いします。\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            真言宗室生寺派　大本山室生寺<br>\n' +
                    '            TEL: 0745-93-2003<br>\n' +
                    '            FAX: 0745-93-2057<br>\n' +
                    '            Mail: yamato-murouji@kcn.jp<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '    <br>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する天野山金剛寺の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。\n' +
                    '        <br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　クレジットの表記<br>\n' +
                    '            天野山金剛寺の画像・映像等のコンテンツの利用にあたって，下記のクレジット表記を必ず行なってください。<br>\n' +
                    '            提供：天野山金剛寺\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-２　商用利用不可<br>\n' +
                    '            天野山金剛寺の画像・映像等のコンテンツの利用は非営利の利用のみ許諾されており，商用での利用はできません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【表示-非営利4.0国際】となります。\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-3　コンテンツの加工不可<br>\n' +
                    '            天野山金剛寺の画像・映像等のコンテンツの利用では，コンテンツを加工しての公開はできません。映像コンテンツの使用箇所を編集する等の軽微の加工は可能ですが，画像処理・レイアウト・説明・キャプション・ナレーション･テロップ表示などは出来ません。それに伴いクリエイティブ・コモンズ・ライセンスの利用条件は【改変禁止4.0国際】となります。\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-４　使用申請<br>\n' +
                    '            天野山金剛寺の画像・映像等のコンテンツの利用にあたっては，具体的な使用方法について、かならず事前に下記の天野山金剛寺へ利用申請のご連絡をお願いします。\n' +
                    '            <br><br>\n' +
                    '        </li>\n' +
                    '        <li tabindex="0">\n' +
                    '            天野山金剛寺<br>\n' +
                    '            TEL: 0721-52-2046<br>\n' +
                    '            ※日本語での対応となります。<br><br>\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>\n',
                tamahagane: '<div class="additional">\n' +
                    '    <h3 tabindex="0">\n' +
                    '        文化庁コンテンツバンク<br>\n' +
                    '        玉鋼製造（たたら吹き）伝承 コンテンツ利用にあたっての付加規約\n' +
                    '    </h3>\n' +
                    '    <p tabindex="0">\n' +
                    '        文化庁コンテンツバンクで提供する玉鋼製造（たたら吹き）伝承の画像・映像等のコンテンツ利用にあたっては，以下のように特別な付加条件がついています。<br>\n' +
                    '        下記の付加規約に同意いただき，ダウンロードページの同意チェックボタンにチェック頂いた上でダウンロードして利用ください。またコンテンツの利用にあたっては，コンテンツバンクの基本規約に加えて，下記の付加規約の条件も遵守して利用ください。\n' +
                    '\n' +
                    '        <br><br>\n' +
                    '    </p>\n' +
                    '    <ul>\n' +
                    '        <li tabindex="0">\n' +
                    '            付加条件-1　クレジットの表記<br>\n' +
                    '            玉鋼製造（たたら吹き）伝承の画像・映像等のコンテンツの利用にあたって，下記のクレジット表記を必ず行なってください。\n' +
                    '            提供：日刀保たたら（公益財団法人日本美術刀剣保存協会）\n' +
                    '        </li>\n' +
                    '    </ul>\n' +
                    '</div>'
            }
        },
        accessibility: {
            headerImage: 'ヘッダーの画像',
            logo: '文化庁 AGENCY FOR CULTURAL AFFAIRS',
            switchJapanese: '日本語に切り替える',
            switchEnglish: '英語に切り替える',
            keywordSearchForm: '文化財コンテンツを検索するための入力フォームです。' +
                '検索するためにはキーワードを入力してEnterを押下して下さい',
            keywordSearch: 'キーワードで検索',
            searchButton: '検索ボタン',
            openMenu: 'メニューを開く',
            closeMenu: 'メニューを閉じる',
            copyright: 'Enterを押下するとコピーライトに関するページに遷移します',
            howToUse: 'Enterを押下するとご利用規約に関するページに遷移します',
            dropdownSort: '表示順を切り替え',
            dropdownNumber: '表示数を切り替え',
            imageModal: '画像拡大プレビューのためのポップアップ',
            downloadModal: 'コンテンツダウンロードを行うためのモーダル',
            additionalTermsModal: '利用条件が記載されたモーダル',
            closeModal: '拡大プレビューを閉じる',
            pagination: '検索結果ページ',
            addToFavorites: 'コンテンツをお気に入りに追加',
            removeFromFavorites: 'コンテンツをお気に入りから削除',
            zoomContent: 'コンテンツの画像を拡大',
            removeContents: 'お気に入りから削除',
            contentCard: 'のカード、EnterまたはSpaceを押下すると詳細ページに遷移します',
            contentImage: 'のサムネイル、EnterまたはSpaceを押下すると詳細ページに遷移します',
            contentId: 'このコンテンツの識別子、EnterまたはSpaceを押下すると詳細ページに遷移します',
            sSizeContent: 'Sサイズのコンテンツを選択',
            mSizeContent: 'Mサイズのコンテンツを選択',
            lSizeContent: 'Lサイズのコンテンツを選択',
            textContent: 'コンテンツのテキストデータを選択',
            largeCollection: '表示スタイルを大きいタイルに変更',
            smallCollection: '表示スタイルを小さいタイルに変更',
            list: '表示スタイルをリストに変更',
            changeNumberTo1: 'コンテンツの表示数を1に変更',
            changeNumberTo2: 'コンテンツの表示数を2に変更',
            changeNumberTo5: 'コンテンツの表示数を5に変更',
            createdAt: 'コンテンツを新着順に並び替え',
            photographedAt: 'コンテンツを撮影年月日順に並び替え',
            vr: '「超高精細VR・パノラマ」で検索する',
            seasons: {
                spring: '「季節　ー春ー」で検索する',
                summer: '「季節　ー夏ー」で検索する',
                autumn: '「季節　ー秋ー」で検索する',
                winter: '「季節　ー冬ー」で検索する',
            },
            castles: '「城郭」で検索する',
            temple: '「寺社仏閣」で検索する',
            japanHeritage: '「日本遺産」で検索する',
            worldHeritage: '「世界遺産」で検索する',
            festivals: '「祭り」で検索する',
            traditionalEvents: '「伝統行事」で検索する',
            page: 'ページ',
            previousPage: '前のページに戻る',
            nextPage: '次のページに進む',
            readMoreRelated: '関連作品をもっと見る',
            readMoreHistories: '閲覧履歴をもっと見る',
            returnTop: 'ページの先頭に戻る',
            top: 'Enterを押下するとトップページに遷移します',
            viewingHistory: 'Enterを押下すると閲覧履歴に遷移します',
            viewingVR: 'Enterを押下するとVR画像閲覧ページに遷移します',
            favorites: 'Enterを押下するとお気に入りに遷移します',
            privacy: 'Enterを押下するとプライバシーポリシーに関するページに遷移します',
            cookie: 'Enterを押下するとクッキーポリシーに関するページに遷移します',
            terms: 'Enterを押下すると利用規約に関するページに遷移します',
            accessibility: 'Enterを押下するとアクセシビリティに関するページに遷移します',
            contact: 'Enterを押下すると問い合せに関するページに遷移します',
            commentary: 'Enterを押下すると解説文についてに関するページに遷移します',
            voiceGuide: {
                pageLink: 'ページ内移動リンク',
                moveToCommonMenu: '共通メニューに移動',
                moveToContentsArea: 'ページ本文に移動',
                moveToFooterMenu: 'フッターに移動'
            }
        },
        staticPages: {
            contactUs: {
                title: '',
            },
            CookiePolicy: '',
            Copyright: '',
            HowToUse: '',
            PrivacyPolicy: '<p class="lead" tabindex="0">文化庁デジタルコンテンツダウンロード機能　プライバシーポリシー</p>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">1. 基本的考え方</h2>\n' +
                '<ol>\n' +
                '<li tabindex="0">\n' +
                '文化庁では，文化庁デジタルコンテンツダウンロード機能（https://cb.bunka.go.jp/　以下「当サイト」という）において提供するサービス（ホームページによる情報提供、各種御意見の受付等）の円滑な運営に必要な範囲で、当サイトを利用される皆様の情報を収集しています。収集した情報は利用目的の範囲内で適切に取り扱います。\n' +
                '</li>\n' +
                '</ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">2. 収集する情報の範囲</h2>\n' +
                '<ol>\n' +
                '    <li tabindex="0">\n' +
                '    (1)当サイトでは，IPアドレス等の情報を自動的に収集します。\n' +
                '</li>\n' +
                '    <li tabindex="0">\n' +
                '    (2)文化庁の施策などに関する御意見・お問合せ（https://www.inquiry.mext.go.jp/inquiry89/）の御利用にあたっては，お名前や，御住所，電話番号，メールアドレス等について，差出人の任意で登録をお願いしています。\n' +
                '</li>\n' +
                '    <li tabindex="0">\n' +
                '    (3)パブリックコメントや個別事案ごとの御意見等を当サイトで募集する場合には，場合に応じてお名前，御住所，職業，電話番号等の記入をお願いすることがあります。また，差出人のメールアドレスは受信者側に表示されます。\n' +
                '</li>\n' +
                '</ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">3. 利用目的</h2>\n' +
                '<ol>\n' +
                '    <li tabindex="0">\n' +
                '        (1)2（1）において収集した情報は，当サイトが提供するサービスを円滑に運営するための参考として利用します\n' +
                '</li>\n' +
                '    <li tabindex="0">\n' +
                '        (2)2（2）において収集した御意見等は，今後の施策立案の参考とさせていただきます。そのため，御意見・お問合せの内容に応じて，庁内の担当部局に転送することがあるとともに，所管外の場合には担当の行政機関に転送することがあります。また，お問合せの回答や確認の御連絡のため，お名前，メールアドレスを利用します。\n' +
                '</li>\n' +
                '    <li tabindex="0">\n' +
                '        (3)2（3）で収集した御意見等は，今後の施策立案の参考とさせていただきます。また，お名前，御住所，電話番号，メールアドレスについては御意見等に対する返信を行う場合に利用することがあります\n' +
                '    </li>\n' +
                '</ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">4. 利用及び提供の制限</h2>\n' +
                '<ol>\n' +
                '    <li tabindex="0">\n' +
                '        文化庁では，法令に基づく開示要請があった場合，不正アクセス，脅迫等の違法行為があった場合その他特別の理由のある場合を除き，収集した情報を3の利用目的以外に利用することや，第三者に提供することはありません。ただし，統計的に処理された当サイトのアクセス情報，利用者属性等の情報については公表することがあります\n' +
                '</li>\n' +
                '</ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">5. 安全確保の措置</h2>\n' +
                '<ol>\n' +
                '    <li tabindex="0">\n' +
                '        文化庁は，収集した情報の漏えい，滅失又はき損の防止その他収集した情報の適切な管理のために必要な措置を講じます。\n' +
                '</li>\n' +
                '</ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">6. 適用範囲</h2>\n' +
                '<ol>\n' +
                '    <li tabindex="0">\n' +
                '        本プライバシーポリシーは，当サイト（https://cb.bunka.go.jp/）においてのみ適用されます。関係府省等における情報の取扱いについては，それぞれの組織の責任において行われます。\n' +
                '</li>\n' +
                '</ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h2 tabindex="0">7. その他</h2>\n' +
                '<ol>\n' +
                '    <li tabindex="0">\n' +
                '        文化庁では，このプライバシーポリシーを必要に応じて改定することがあります。\n' +
                '</li>\n' +
                '</ol>\n' +
                '</div>',
            TermsOfUse: '<p tabindex="0" class="lead">\n' +
                '    この基本利用規約（以下「本規約」という。）は，文化庁（以下「当庁」という。）が提供する文化財デジタルコンテンツ　ダウンロード機能（以下「コンテンツバンク」という。）及びコンテンツバンクに含まれる画像・映像・音声等のコンテンツ（以下，「コンテンツ」という。）の使用又は利用に関する基本的な条件等を定めるものです。</p>\n' +
                '<p tabindex="0" class="lead mb-40">\n' +
                '    利用者は，本規約，プライバシーポリシー及びガイドラインその他コンテンツバンクに関する規則等に従って，コンテンツバンク及びコンテンツバンクに含まれるコンテンツを使用又は利用する必要があり，コンテンツバンクの利用をもって本規約の内容を承諾頂いたものとみなします。また，コンテンツのダウンロードに当たっては，本規約及びコンテンツごとの付加規約に同意の上，同意ボタンをチェックし全ての規約に承諾いただいた上で利用してください。</p>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">1. 目的</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            コンテンツバンクの目的は，日本各地の世界遺産，日本遺産，国宝及び重要文化財等(これらを総称して，以下「文化財等」という。)に関する画像・動画等のコンテンツを収集・制作し，二次利用可能なコンテンツとして本規約に基づいて公開することによって，日本への観光を促進することにあります。<br>\n' +
                '            その目的に沿うため，コンテンツバンクのコンテンツは，次項に定める通り，一部特別な付加規約のあるコンテンツ及び第5項に定める禁止事項に掲げる利用方法を除き，どなたでも自由に利用することができます。\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">2. コンテンツの公開条件</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            2.1. コンテンツバンクに含まれるすべてのコンテンツは，特別な付加規約が付されている場合及び禁止事項に掲げる利用方法を除き，<a\n' +
                '            href="https://creativecommons.org/licenses/by/4.0/legalcode.ja#s3" tabindex="0" target="_blank"\n' +
                '            class="accent-txt-terms">クリエイティブ・コモンズ・ライセンス【表示4.0 国際】</a>の利用条件に準拠し公開しています。<br>\n' +
                '            すなわち，利用者は，コンテンツの複製，放送，有線放送，ネット送信，公衆への上映，受信装置を用いた公衆への伝達及び頒布を本規約の定めるところにより自由に行うことができます。コンテンツを改変し，翻案して利用することも可能です。そしてそれらの利用は，営利・商用目的で行うことも可能です。なお，コンテンツの利用に当たり，クリエイティブ・コモンズ・ライセンスのバージョンがアップデートされた場合には最新のバージョンに準拠し公開しているものとみなします。\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            2.2.\n' +
                '            利用者が本規約の定めるところによりコンテンツを利用する場合には，特別な付加規約で条件とされている場合を除き，コンテンツバンクの目的に照らして著作者，著作権者及び出典を表記する義務を免除します（記載することを妨げるものではありません）。\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            2.3. コンテンツごとの特別な付加規約で，出典又は文化財所蔵元等の表記方法に関し，特別に指定がされている場合は，付加規約の同意ボタンをチェックの上，コンテンツごとに指定された通りの表記を行ってください。\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            2.4.\n' +
                '            その他，営利・商用目的利用不可，使用時に文化財所蔵元への連絡が必要等，コンテンツごとの特別な付加規約として示されている条件，又はクリエイティブ・コモンズ・ライセンスの利用条件として特別な付加条件がある場合は，付加規約の同意ボタンをチェックの上，誠実にその条件を遵守してください。\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">3. 国の権利</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            当庁は，コンテンツバンクに含まれる一切のコンテンツについて，著作権（著作権法第21条から第28条までに規定されたすべての権利を含みます。以下，同じ。）を保有しています。前項の定めは，この著作権を利用者に対し譲渡することを意味しません。\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">4. 非独占的利用許諾</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            コンテンツバンクに含まれるすべてのコンテンツは，あらゆる利用者に対し，本規約の定めるところにより非独占的に利用を許諾しているものです。利用者は，同一のコンテンツ又は類似のコンテンツを第三者が利用することに関し，一切異議を申し立てることはできません。\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">5. 利用方法の禁止事項</h2>\n' +
                '    <p class="lead">\n' +
                '        利用者は，コンテンツバンクに含まれるすべてのコンテンツについて，以下の各号に規定する目的で利用することはできません。\n' +
                '    </p>\n' +
                '    <ol class="list">\n' +
                '        <li tabindex="0">\n' +
                '            (1) コンテンツ制作者の名誉又は声望を害する方法での利用\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            (2) コンテンツに撮影された文化財等又は文化財等の保有者の名誉又は声望を害する方法での利用\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            (3) 日本国，他国及びその他第三者の名誉又は声望を害する目的での利用\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            (4) 犯罪的行為目的での利用その他公序良俗に反する目的での利用\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            (5) コンテンツバンクにおいて，コンテンツの円滑な提供を妨げる目的での利用\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            (6) その他，社会常識の観点からみて著しく妥当性を欠く態様での利用\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">6. 著作権及び被写体の権利</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            6.1.\n' +
                '            利用者は本規約に同意してコンテンツを使用する場合でも，著作者人格権や，被写体となる人物の肖像権，パブリシティ権，プライバシー権などの人格権及びその他の知的財産権は，当庁及び作品原作者や被写体に留まり，利用者に譲渡されてはいないことに留意してください。\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            6.2.\n' +
                '            利用者は利用目的や利用方法によっては，コンテンツの著作権，著作者人格権及びその他の知的財産権や被写体となる人物の肖像権，パブリシティ権及びプライバイシー権などの人格権などに基づいて制約があることを了承してください。\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">7. 詐欺的な行為の禁止等</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            7.1.\n' +
                '            コンテンツバンクに含まれるすべてのコンテンツは，当庁が本規約の定めるところにより万人に対し無償で利用を許諾しているものです。その事実を隠蔽する等して，コンテンツの著作権の所在を偽り，コンテンツの利用を有償・無償で第三者に許諾するなどの詐欺的な行為を行うことを禁止します。\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            7.2 .コンテンツバンクより提供されたコンテンツを加工，編集，制作した画像等を，本規約の禁止事項に掲げる目的で利用するおそれがある第三者へ販売，配布，譲渡，貸与，担保提供することを禁止します。\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">8. 免責</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            8.1.\n' +
                '            当庁は，コンテンツバンクで提供されるコンテンツに事実上又は法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ及び権利侵害などを含む。）がないことを明示的にも黙示的にも保証するものではありません。当庁は，利用者に対して，かかる瑕疵を常に除去してコンテンツバンクに関するサービスを提供する義務を負うものではありません。\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            8.2. 当庁は，コンテンツバンクに含まれるコンテンツを利用者が利用することで利用者間又は利用者と第三者との間で生じるいかなる形態での紛争又はあらゆる損害について責任を負うものではありません。\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            8.3.\n' +
                '            当庁は，コンテンツバンクに含まれるコンテンツが常に提供されることを保証するものではありません。当庁は，コンテンツバンクのシステムの保守点検といった理由に関わらず，コンテンツバンクに含まれるコンテンツが提供できない事態が発生したことによって利用者又は第三者に生じるあらゆる損害について責任を負うものではありません。\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">9. 提供方法及び内容の変更</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            9.1. 当庁は，利用者に対してあらかじめ通知することなく，いつでも本規約に定めるコンテンツの提供方法若しくは提供内容を変更し，又は提供を中止すること（以下「変更等」といいます。）ができることとします。\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            9.2. 当庁は，前項の変更等に関連して，利用者又は第三者に生じるあらゆる紛争及び損害について責任を負うものではありません。\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">10. 本規約の変更</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            10.1. 当庁は，利用者に対してあらかじめ通知することなく，いつでも本規約の内容を変更することができます。\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            10.2.\n' +
                '            利用者は，本規約の変更があった後，コンテンツバンクに含まれるコンテンツの利用を継続する場合，変更後の本規約について同意をしたものとみなします。ただし，本規約変更前に利用者が変更前の規約に基づいて取得したコンテンツに適用されるライセンスの内容はこの限りではありません。\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">11. 事業譲渡等</h2>\n' +
                '    <ol>\n' +
                '        <li tabindex="0">\n' +
                '            コンテンツバンクが事業譲渡等により承継された場合は，利用者と国の間の契約（本規約を含む）における国の地位を当該承継者に譲渡することができます。利用者は，事業譲渡等についてあらかじめ同意します。\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '    <h2 tabindex="0">12. 準拠法，裁判管轄</h2>\n' +
                '    <ol class="adjust">\n' +
                '        <li tabindex="0">\n' +
                '            12.1. 本規約は，日本語を正文とし，準拠法は日本法とします。\n' +
                '        </li>\n' +
                '        <li tabindex="0">\n' +
                '            12.2. 本規約に起因し，又は関連する一切の紛争については，東京地方裁判所を第一審の専属的合意管轄裁判所とします。\n' +
                '        </li>\n' +
                '    </ol>\n' +
                '    <p tabindex="0">\n' +
                '        文化庁政策課文化発信室<br>\n' +
                '        （事業受託：株式会社ＴＢＳスパークル）<br>\n' +
                '        令和2年3月25日制定<br><br>\n' +
                '    </p>\n' +
                '    <p tabindex="0"><a href="https://www.inquiry.mext.go.jp/inquiry89/" target="_blank"  class="accent-txt-terms">御意見・お問合せ</a></p>\n' +
                '</div>\n',
            Accessibility: '<div class="contents-block">\n' +
                '<h2 class="heading02"><span tabindex="0">JIS X 8341-3:2016に基づく試験結果表示（ウェブページ一式）</span></h2>\n' +
                '<p class="text" tabindex="0">JIS-X8341-3:2016 (WCAG2.0)に基づく試験結果表示は、以下のとおりです。</p>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">達成した等級</span></h3>\n' +
                '<ul class="list">\n' +
                '<li tabindex="0">達成等級A準拠</li>\n' +
                '<li tabindex="0">達成等級AA準拠</li>\n' +
                '</ul>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">ウェブページ一式の範囲</span></h3>\n' +
                '<p class="text" tabindex="0">「文化財デジタルコンテンツダウンロード機能」WEBサイト一式</p>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">試験を行ったページのリスト</span></h3>\n' +
                '<div class="contents-block-inner">\n' +
                '<h4 class="heading04"><span tabindex="0">1.日本語版</span></h4>\n' +
                '<ul class="url-list">\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/contents/</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/contents/e19b7919-ce49-475b-9d06-9d6085abae7d</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/contents/viewing-history</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/contents/favorites</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/pages/privacy</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/ja/pages/terms-of-use</li>\n' +
                '</ul>\n' +
                '</div>\n' +
                '<div class="contents-block-inner">\n' +
                '<h4 class="heading04"><span tabindex="0">2.英語版</span></h4>\n' +
                '<ul class="url-list">\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/contents/</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/contents/e19b7919-ce49-475b-9d06-9d6085abae7d</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/contents/viewing-history</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/contents/favorites</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/pages/privacy</li>\n' +
                '<li tabindex="0">https://cb.bunka.go.jp/en/pages/terms-of-use</li>\n' +
                '</ul>\n' +
                '</div>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">依存したウェブコンテンツ技術</span></h3>\n' +
                '<p class="text" tabindex="0">HTML5、CSS3、JavaScript</p>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">達成基準チェックリスト</span></h3>\n' +
                '<p class="text" tabindex="0">下記「達成基準チェックリスト」を参照</p>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">試験実施期間</span></h3>\n' +
                '<p class="text" tabindex="0">2020年3月2日から2020年3月4日まで</p>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">試験に使用したチェックツール</span></h3>\n' +
                '<ul class="list">\n' +
                '<li tabindex="0">W3C Markup Validation Service</li>\n' +
                '<li tabindex="0">miChecker</li>\n' +
                '<li tabindex="0">カラー・コントラスト・アナライザー</li>\n' +
                '<li tabindex="0">VoiceOver, NVDA</li>\n' +
                '</ul>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">検証に用いたユーザーエージェント</span></h3>\n' +
                '<ul class="list">\n' +
                '<li tabindex="0">Internet Explorer 11 (Microsoft Windows 10)</li>\n' +
                '<li tabindex="0">Chrome (Microsoft Windows 10)</li>\n' +
                '<li tabindex="0">Safari 13 (Mac OS 10.14)</li>\n' +
                '</ul>\n' +
                '</div>\n' +
                '<div class="contents-block">\n' +
                '<h3 class="heading03"><span tabindex="0">JIS X 8341-3:2016(WCAG2.0)に基づく達成基準チェックリスト</span></h3>\n' +
                '<div class="accessibility-table">\n' +
                '<table>\n' +
                '<tr>\n' +
                '<th tabindex="0">細分箇条</th>\n' +
                '<th tabindex="0">達成基準</th>\n' +
                '<th tabindex="0">解説</th>\n' +
                '<th tabindex="0">等級</th>\n' +
                '<th tabindex="0">適用</th>\n' +
                '<th tabindex="0">適合</th>\n' +
                '<th tabindex="0">備考</th>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.1.1</td>\n' +
                '<td tabindex="0">非テキストコンテンツに関する達成基準</td>\n' +
                '<td tabindex="0">画像やボタンなど、文字以外のコンテンツには、それらと同等の目的を果たす代替テキストを提供する。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.2.1</td>\n' +
                '<td tabindex="0">収録済みの音声しか含まないメディア及び収録済みの映像しか含まないメディアに関する達成基準</td>\n' +
                '<td tabindex="0">音声のみ、または映像のみのコンテンツには、それらと同等の目的を果たす代替テキストを提供する。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.2.2</td>\n' +
                '<td tabindex="0">収録済みの音声コンテンツのキャプションに関する達成基準</td>\n' +
                '<td tabindex="0">音声のみ、または映像のみのコンテンツには、それらと同等の目的を果たす代替テキストを提供する。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.2.3</td>\n' +
                '<td tabindex="0">収録済みの映像コンテンツの代替コンテンツ又は音声ガイドに関する達成基準</td>\n' +
                '<td tabindex="0">動画コンテンツに対して、映像情報を伝える代替コンテンツ、もしくは副音声などの音声ガイドを提供する。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">-</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.2.4</td>\n' +
                '<td tabindex="0">ライブの音声コンテンツのキャプションに関する達成基準</td>\n' +
                '<td tabindex="0">生放送の動画コンテンツに対して、音声情報と同じ内容の字幕（キャプション）を提供する。</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">-</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.2.5</td>\n' +
                '<td tabindex="0">収録済みの映像コンテンツの音声ガイドに関する達成基準</td>\n' +
                '<td tabindex="0">動画コンテンツに対して、映像情報を伝える副音声などの音声ガイドを提供する。</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">-</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.3.1</td>\n' +
                '<td tabindex="0">情報及び関係性に関する達成基準</td>\n' +
                '<td tabindex="0">情報は、プログラムが情報の意味を解釈できるように実装する。<br>（例）リストはテキストの「・」で表現するのではなく、システム内のリスト（箇条書き）機能を用いる。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.3.2</td>\n' +
                '<td tabindex="0">意味のある順序に関する達成基準</td>\n' +
                '<td tabindex="0">コンテンツを意味のある順序で並べ、音声読み上げソフトなどを使用しても、意図通りの順に情報が伝わるようにする。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.3.3</td>\n' +
                '<td tabindex="0">感覚的な特徴に関する達成基準</td>\n' +
                '<td tabindex="0">音声読み上げソフトでは形や大きさ、方向など感覚的な情報を理解できないため、テキストで情報を伝える。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.4.1</td>\n' +
                '<td tabindex="0">色の使用に関する達成基準</td>\n' +
                '<td tabindex="0">色を用いて情報を伝えるときは、色だけではなく、テキストでも情報を伝える。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.4.2</td>\n' +
                '<td tabindex="0">音声制御に関する達成基準</td>\n' +
                '<td tabindex="0">音楽などの音声が、ページを開いたときに自動で再生しないようにする。また、再生・一時停止などをユーザーがコントロールできるようにする。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.4.3</td>\n' +
                '<td tabindex="0">最低限のコントラストに関する達成基準</td>\n' +
                '<td tabindex="0">テキスト及び画像化された文字の視覚的な表現と背景色には、少なくとも 4.5:1のコントラスト比がなければならない。<br>ただし、サイズの大きなテキスト及びサイズの大きな画像化された文字（18 ポイントのテキスト又は 14 ポイントの太字のテキスト）には、少なくとも3:1のコントラスト比が必要</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.4.4</td>\n' +
                '<td tabindex="0">テキストのサイズ変更に関する達成基準</td>\n' +
                '<td tabindex="0">テキストの大きさは固定せず、ブラウザなどの機能で200%まで拡大し、通常のサイズと同じように情報を理解できるようにする。</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.1.4.5</td>\n' +
                '<td tabindex="0">画像化された文字に関する達成基準</td>\n' +
                '<td tabindex="0">ロゴなど必要不可欠なものを除いて、文字は可能な限り画像化せずにテキストで提供する。</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.1.1</td>\n' +
                '<td tabindex="0">キーボード操作に関する達成基準</td>\n' +
                '<td tabindex="0">ページ上のすべての機能をキーボードのみでも操作できるようにする。ただし、ペイント機能などマウスの軌跡などを利用する機能は除く。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.1.2</td>\n' +
                '<td tabindex="0">フォーカス移動に関する達成基準</td>\n' +
                '<td tabindex="0">キーボードを用いてページ上の機能にアクセスしたとき、キーボードでその機能から抜け出せるようにする。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.2.1</td>\n' +
                '<td tabindex="0">タイミング調整可能の達成基準</td>\n' +
                '<td tabindex="0">コンテンツを利用するのに制限時間を設定する場合は、利用者が設定時間の解除などをできるようにする。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.2.2</td>\n' +
                '<td tabindex="0">一時停止，停止及び非表示に関する達成基準</td>\n' +
                '<td tabindex="0">スライドショーや動画などの動きのあるコンテンツは、利用者が一時停止・停止・再生などをできるようにする。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.3.1</td>\n' +
                '<td tabindex="0">3回のせん（閃）光又はいき（閾）値以下に関する達成基準</td>\n' +
                '<td tabindex="0">閃光を放つコンテンツがある場合、閃光する場所を十分に小さくし、1秒間に3回以内にする。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.1</td>\n' +
                '<td tabindex="0">ブロックスキップに関する達成基準</td>\n' +
                '<td tabindex="0">ナビゲーションなどのメニューをスキップすることができる機能を設置する。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.2</td>\n' +
                '<td tabindex="0">ページタイトルに関する達成基準</td>\n' +
                '<td tabindex="0">ページの目的やサイトとの関連性がわかるようなページタイトルをつける。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.3</td>\n' +
                '<td tabindex="0">フォーカス順序に関する達成基準</td>\n' +
                '<td tabindex="0">リンクやボタンなどは、コンテンツの順番や関連性に従った順でフォーカス移動できるようにする。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.4</td>\n' +
                '<td tabindex="0">文脈におけるリンクの目的に関する達成基準</td>\n' +
                '<td tabindex="0">テキストリンクは、可能な限りそれ単体でリンク先のページを理解できるようにする。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.5</td>\n' +
                '<td tabindex="0">複数の到達手段に関する達成基準</td>\n' +
                '<td tabindex="0">各ページに訪れる手段を、いくつか用意する。</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.6</td>\n' +
                '<td tabindex="0">見出し及びラベルに関する達成基準</td>\n' +
                '<td tabindex="0">見出しやラベルなどは、目的や内容がわかるようにする。</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.2.4.7</td>\n' +
                '<td tabindex="0">視覚的に認識可能なフォーカスに関する達成基準</td>\n' +
                '<td tabindex="0">キーボードを用いてコンテンツにフォーカスを移したとき、視覚的にフォーカスが移動したことがわかるようにする。</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.1.1</td>\n' +
                '<td tabindex="0">ページの言語に関する達成基準</td>\n' +
                '<td tabindex="0">言語属性を用いてページの主要な言語が何かをプログラムが理解できるようにする。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.1.2</td>\n' +
                '<td tabindex="0">部分的に用いられている言語に関する達成基準</td>\n' +
                '<td tabindex="0">主要ではない言語がページ内に現れたときは、その言語が何かをプログラムが理解できるように、言語属性を用いる。</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.2.1</td>\n' +
                '<td tabindex="0">オンフォーカスに関する達成基準</td>\n' +
                '<td tabindex="0">すべてのコンテンツは、フォーカスを受け取っただけで状態が変化（実行）しないようにする。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.2.2</td>\n' +
                '<td tabindex="0">ユーザインタフェースコンポーネントによる状況の変化に関する達成基準</td>\n' +
                '<td tabindex="0">フォームにテキストを入力する、ラジオボタンにチェックを入れるなどしただけで、状態が変化（実行）しないようにする。必ず、実行ボタンを用意する。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.2.3</td>\n' +
                '<td tabindex="0">一貫したナビゲーションに関する達成基準</td>\n' +
                '<td tabindex="0">複数のページで繰り返し表示されるメニューなどのナビゲーションは、どのページでも同じ順序で表示する。</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.2.4</td>\n' +
                '<td tabindex="0">一貫した識別性に関する達成基準</td>\n' +
                '<td tabindex="0">ウェブサイトの中で同じ機能をもつものは、すべて同じラベルや代替テキスト、識別名などを設定する。</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.3.1</td>\n' +
                '<td tabindex="0">入力エラー箇所の特定に関する達成基準</td>\n' +
                '<td tabindex="0">問い合わせなどの入力フォームで入力エラーがあった場合、エラーの内容が容易に伝わるようテキストで情報を伝える。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">エラー表示なし</td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.3.2</td>\n' +
                '<td tabindex="0">ラベル又は説明文に関する達成基準</td>\n' +
                '<td tabindex="0">お問い合わせなどの入力フォームで、フォームに適切なラベルを提示し、また必須の有無、入力制限（半角英数のみなど）や入力例を提示し、利用者が入力ミスをしないようにする。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.3.3</td>\n' +
                '<td tabindex="0">入力エラー修正方法の提示に関する達成基準</td>\n' +
                '<td tabindex="0">お問い合わせなどの入力フォームで、入力ミスがあったときなどに、適切と思われる修正候補を提示する。</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.3.3.4</td>\n' +
                '<td tabindex="0">法的義務，金銭的取引，データ変更及び回答送信のエラー回避に関する達成基準</td>\n' +
                '<td tabindex="0">利用者に所得税申告などの法的な義務が生じたり、金銭のやり取りが発生したりする場合、情報を送信する前に必ず確認や修正が出来るようにするなどして、利用者が間違いをしないようにする。</td>\n' +
                '<td tabindex="0">AA</td>\n' +
                '<td tabindex="0">—</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.4.1.1</td>\n' +
                '<td tabindex="0">構文解析に関する達成基準</td>\n' +
                '<td tabindex="0">ウェブページのHTMLやCSSなどは、仕様に準拠したものにする。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '<tr>\n' +
                '<td tabindex="0">7.4.1.2</td>\n' +
                '<td tabindex="0">プログラムが解釈可能な識別名，役割及び設定可能な値に関する達成基準</td>\n' +
                '<td tabindex="0">ウェブページの各機能は、仕様に準拠し、ブラウザなどのプログラムが情報を解釈できるようにする。</td>\n' +
                '<td tabindex="0">A</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0">○</td>\n' +
                '<td tabindex="0"></td>\n' +
                '</tr>\n' +
                '</table>\n' +
                '</div>\n' +
                '</div>',
        }
    }
};

export default new VueI18n({
    locale: 'ja',
    fallbackLocale: 'en',
    messages
})
