<template>
    <div class="viewing-histories container">
        <div class="row">
            <div class="col-12 p-0">
                <h3 tabindex="0" class="title">{{ $t('contents.viewingHistory') }}</h3>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-5 mt-3 d-none d-md-flex">
            <div class="col mb-2 mb-md-4"
                 v-for="(content, key, loop) in reverseObject($root.caches.viewingHistoryOfContents)"
                 :key="content.id">
                <content-card :content="content"
                              @zoom-button-has-clicked="$refs.imagePreviewModal.open(content)"
                              v-focus="loop === 5"
                              v-if="loop < initDisplayCount || isDisplayAllContents">
                </content-card>
            </div>
        </div>
        <div class="row d-block d-md-none">
            <div class="col-12 p-0">
                <div class="col"
                     v-for="(content, key, loop) in reverseObject($root.caches.viewingHistoryOfContents)"
                     :key="content.id">
                    <content-table :content="content"
                                   @zoom-button-has-clicked="$refs.imagePreviewModal.open(content)"
                                   v-focus="loop === 5"
                                   v-if="loop < initDisplayCount || isDisplayAllContents">
                    </content-table>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-12 text-center" v-if="isDisplayShowMoreButton">
                <button @click="showMoreButtonHasClicked"
                        tabindex="0"
                        :aria-label="$t('accessibility.readMoreHistories')"
                        class="btn btn-outline-dark btn-more-detail">{{ $t('contents.seeMore') }}</button>
            </div>
        </div>
        <image-preview-modal ref="imagePreviewModal"></image-preview-modal>
    </div>
</template>

<script>
    import ContentCard from "../ContentCard/ContentCard";
    import ImagePreviewModal from "../ImagePreviewModal/ImagePreviewModal";
    import ContentTable from "../ContentTable/ContentTable";
    export default {
        name: "ViewingHistories",
        components: {ImagePreviewModal, ContentCard, ContentTable},
        data: function () {
            return {
                isDisplayAllContents: false,
                initDisplayCount: 5
            }
        },
        methods: {
            reverseObject: function (object) {
                const reverseObj = {};
                Object.keys(object).reverse().forEach((key) => {
                    reverseObj[key] = object[key]
                });
                return reverseObj;
            },
            showMoreButtonHasClicked: function () {
                this.isDisplayAllContents = true;
            },
            initData: function () {
                this.isDisplayAllContents = false;
            }
        },
        directives: {
            focus: {
                inserted: function (el, binding) {
                    if (binding.value) {
                        el.focus()
                    }
                }
            }
        },
        computed: {
            isDisplayShowMoreButton: function () {
                return !this.isDisplayAllContents &&
                    Object.keys(this.$root.caches.viewingHistoryOfContents).length > this.initDisplayCount;
            }
        }
    }
</script>

<style src="./ViewingHistories.scss" lang="scss" scoped/>
