<template>
    <div v-if="content">
        <div class="modal fade"
             tabindex="-1"
             :aria-label="$t('accessibility.downloadModal')"
             :aria-hidden="!isOpen"
             :aria-modal="isOpen"
             :class="{show: isOpen}"
             :style="{display: isOpen ? 'block' : 'none'}">
            <div class="modal-dialog modal-xl"
                 role="dialog"
                 id="downloadPanelModal"
                 tabindex="0">
                <div class="modal-content" role="document">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <p class="modal-text"
                                   :aria-label="$t('accessibility.contentId')">
                                    {{ content.id }}
                                </p>
                                <h2 tabindex="0" class="modal-title">{{ title }}</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <download-panel :content="content" @additional-terms-modal-has-closed="additionalTermsModalHasClosed">
                                </download-panel>
                            </div>
                        </div>
                    </div>
                    <div class="modal-header">
                        <button type="button"
                            tabindex="0"
                            @click="close"
                            class="close"
                            data-dismiss="modal"
                            :aria-label="$t('accessibility.closeModal')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DownloadPanel from "./DownloadPanel";
    export default {
        name: "DownloadPanelModal",
        components: {DownloadPanel},
        data: function () {
            return {
                isOpen: false,
                title: null,
                content: null,
                lastFocusedElement: null
            }
        },
        updated: function () {
            this.focusElement();
        },
        methods: {
            open: function (content) {
                this.lastFocusedElement = document.activeElement;
                const body = document.getElementsByTagName('body')[0];
                body.classList.toggle('modal-open');
                this.content = content;
                this.title = content.relationships.details[this.$i18n.locale].title;
                this.isOpen = true;
            },
            close: function () {
                const body = document.getElementsByTagName('body')[0];
                body.classList.remove('modal-open');
                this.isOpen = false;
                this.lastFocusedElement.focus();
            },
            additionalTermsModalHasClosed: function () {
                const body = document.getElementsByTagName('body')[0];
                body.classList.toggle('modal-open');
            },
            focusElement: function () {
                if (this.isOpen) {
                    const modal = document.getElementById('downloadPanelModal');
                    modal.focus();
                }
            }
        }
    }
</script>

<style src="./DownloadPanelModal.scss" lang="scss" scoped />
