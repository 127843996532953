<template>
    <div class="px-md-0 col-12">
        <ul class="breadcrumb-list container mx-md-3 mx-xl-auto row" aria-label="パンくずリスト">
            <li>
                <router-link tabindex="0" :to="{name: 'Top'}" :aria-label="$t('breadcrumbs.top')">
                    <span class="step">{{ $t('buttons.top') }}</span>
                </router-link>
            </li>
            <li>
                <span tabindex="0" aria-current="page">解説文について</span>
            </li>
        </ul>
        <div class="container text-center">
            <h1 class="title" id="contents-area"><span tabindex="0">解説文について</span></h1>
        </div>
        <div class="px-0">
            <div class="about-commentary row">
                <p tabindex="0" class="lead">文化遺産デジタルコンテンツダウンロード機能に掲載している解説文（詳細情報）については、
                    「魅力的な多言語解説作成指針」（観光庁）に準拠して執筆・監修を行っています。国指定等文化財としての情報は、
                    <a href="https://kunishitei.bunka.go.jp/bsys/index" target="_blank"  class="accent-txt-terms">国指定等文化財データベース</a>
                    をご覧ください。</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AboutCommentary",
        metaInfo: function() {
            return {
                title: this.$t('buttons.terms'),
                titleTemplate: `%s | ${this.$t('siteInfo.title')}`,
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
                    },
                    {
                        vmid: 'og-title',
                        property: 'og:title',
                        content: this.$t('buttons.terms'),
                    },
                    {
                        vmid: 'og-description',
                        property: 'og:description',
                        content: this.$t('staticPages.TermsOfUse'),
                    }
                ]
            }
        }
    }
</script>
