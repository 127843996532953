<template>
    <div class="row">
        <div class="col-12 d-flex justify-content-center align-items-center">
            <nav :aria-label="$t('accessibility.pagination')">
                <ul class="pagination">
                    <li class="page-item" v-if="meta.current_page !== 1">
                        <a class="page-link previous"
                           href="javascript:void(0)"
                           :aria-label="$t('accessibility.previousPage')"
                           @click="$root.searchContentsByPage(meta.current_page - 1)">
                            <img alt="" class="ico_arrow_left" src="../../assets/icons/ico_arrow_left.svg">
                        </a>
                    </li>
                    <li class="page-item">
                        <a class="page-link"
                           href="javascript:void(0)"
                           :class="{active: isCurrent(1)}"
                           :aria-label="$route.params.lang === 'en' ? 'page 1' : '1ページ'"
                           @click="$root.searchContentsByPage(1)">1</a>
                    </li>
                    <li class="page-item" v-if="meta.current_page < 4 && meta.last_page > 2">
                        <a class="page-link"
                           href="javascript:void(0)"
                           :class="{active: isCurrent(2)}"
                           :aria-label="$route.params.lang === 'en' ? 'page 2' : '2ページ'"
                           @click="$root.searchContentsByPage(2)">2</a>
                    </li>
                    <li class="page-item" v-if="meta.current_page < 4 && meta.last_page > 3">
                        <a class="page-link"
                           href="javascript:void(0)"
                           :class="{active: isCurrent(3)}"
                           :aria-label="$route.params.lang === 'en' ? 'page 3' : '3ページ'"
                           @click="$root.searchContentsByPage(3)">3</a>
                    </li>
                    <li class="page-item" v-if="meta.current_page >= 4">
                        ...
                    </li>
                    <li class="page-item" v-if="meta.current_page >= 4">
                        <a class="page-link"
                           href="javascript:void(0)"
                           :class="{active: isCurrent(meta.current_page - 1)}"
                           :aria-label="$t('accessibility.page') + (meta.current_page - 1)"
                           @click="$root.searchContentsByPage(meta.current_page - 1)">{{ meta.current_page - 1 }}</a>
                    </li>
                    <li class="page-item" v-if="meta.current_page >= 4 && meta.last_page !== meta.current_page">
                        <a class="page-link"
                           href="javascript:void(0)"
                           :class="{active: isCurrent(meta.current_page)}"
                           :aria-label="$route.params.lang === 'en' ? $t('accessibility.page') + (meta.current_page)
                                                                    : (meta.current_page) + $t('accessibility.page')"
                           @click="$root.searchContentsByPage(meta.current_page)">{{ meta.current_page }}</a>
                    </li>
                    <li class="page-item" v-if="meta.current_page >= 3 &&
                                                (meta.current_page + 1) <= meta.last_page &&
                                                (meta.current_page + 1) !== meta.last_page">
                        <a class="page-link"
                           href="javascript:void(0)"
                           :class="{active: isCurrent(meta.current_page + 1)}"
                           :aria-label="$route.params.lang === 'en' ? $t('accessibility.page') + (meta.current_page + 1)
                                                                    : (meta.current_page + 1) + $t('accessibility.page')"
                           @click="$root.searchContentsByPage(meta.current_page + 1)">{{ meta.current_page + 1 }}</a>
                    </li>
                    <li class="page-item" v-if="((meta.last_page - (meta.current_page + 1)) > 1) &&
                     (meta.last_page > 4)">
                        ...
                    </li>
                    <li class="page-item" v-if="meta.last_page > 1">
                        <a class="page-link"
                           href="javascript:void(0)"
                           :class="{active: isCurrent(meta.last_page)}"
                           :aria-label="$route.params.lang === 'en' ? $t('accessibility.page') + (meta.last_page)
                                                                    : (meta.last_page) + $t('accessibility.page')"
                           @click="$root.searchContentsByPage(meta.last_page)">{{ meta.last_page }}</a>
                    </li>
                    <li class="page-item" v-if="meta.last_page !== meta.current_page">
                        <a class="page-link next"
                           href="javascript:void(0)"
                           :aria-label="$t('accessibility.nextPage')"
                           @click="$root.searchContentsByPage(meta.current_page + 1)">
                            <img alt="" class="ico_arrow_right" src="../../assets/icons/ico_arrow_right.svg">
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Pagination",
        props: ['meta'],
        computed: {
            isCurrent: function () {
                return function (page) {
                    return this.meta.current_page === page;
                }
            }
        }
    }
</script>

<style src="./Pagination.scss" lang="scss" scoped />
