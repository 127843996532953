<script>
    import moment from 'moment';
    export default {
        name: "Helper",
        methods: {
            round: function (number, precision) {
                const shift = function (number, precision, reverseShift) {
                    if (reverseShift) {
                        precision = -precision;
                    }

                    const numArray = ("" + number).split("e");
                    return + (numArray[0] + "e" + (numArray[1] ? (+ numArray[1] + precision) : precision));
                };

                return shift(Math.round(shift(number, precision, false)), precision, true);
            },
            convertByteSize: function (size) {
                const sizes = [' B', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB'];
                let ext = sizes[0];
                if (typeof size === 'number') {
                    for (let i = 1; i < sizes.length; i += 1) {
                        if (size >= 1024) {
                            size = size / 1024;
                            ext = sizes[i];
                        }
                    }
                }
                return this.round(size, 2) + ext;
            },
            isPc: function () {
                const ua = window.navigator.userAgent;

                if (!(ua.indexOf('iphone') > 0) && !(ua.indexOf('Android') > 0) && !(ua.indexOf('Mobile') > 0)
                    && !(ua.indexOf('iPad') > 0)) {
                    return true;
                }

                return false;
            },
            convertCamelCase: function (str) {
                str = str.charAt(0).toLowerCase() + str.slice(1);
                return str.replace(/[-_](.)/g, function(match, group1) {
                    return group1.toUpperCase();
                });
            },
            truncate: function (str, len) {
                // var length = len;
                // if (this.$route.params.lang === 'en') {
                //     length = len * 2;
                // }
                return str.length <= len ? str: (str.substr(0, len)+"...");
            }
        },
        filters: {
            dateFormat: function (date) {
                if (!date) {
                    return '-'
                }

                if (!moment(date).isValid()) {
                    return date;
                }

                return moment(date).format('YYYY/MM/DD')
            }
        },
    }
</script>
