<template>
    <div class="search-panel">
        <div class="search-bar-bg d-flex">
            <div class="flex-fill bg-dark"></div>
            <div class="flex-fill"></div>
        </div>
        <div class="container p-0 mr-md-0 ml-md-3 ml-lg-4 mx-xl-auto">
            <div class="search-bar row m-0">
                <div class="d-flex title">
                    <span tabindex="11" class="d-flex justify-content-start align-items-center">{{ $t('buttons.searchCriteria') }}</span>
                </div>
                <div class="d-flex search-bar-links">
                    <div class="search-bar-links-inner mx-auto" id="search-bar-links-inner">
                        <span class="ico-block-before" @click="scrollSearchBar('left')">
                            <img src="./images/ico_search_arrow_left.svg" alt="" class="ico-arrow-before">
                        </span>
                        <button class="btn btn-primary ml-md-5"
                                tabindex="12"
                                @click="changeConditionCollection('culturalPropertyTypes')"
                                :aria-expanded="activatedConditionCollection === 'culturalPropertyTypes' ? 'true' : 'false'"
                                :class="{active: activatedConditionCollection === 'culturalPropertyTypes'}">
                            {{ $t('buttons.culturalPropertyType' )}}
                        </button>
                        <button class="btn btn-primary"
                                tabindex="14"
                                @click="changeConditionCollection('photos')"
                                :aria-expanded="activatedConditionCollection === 'photos' ? 'true' : 'false'"
                                :class="{active: activatedConditionCollection === 'photos'}">
                            {{ $t('buttons.photos') }}
                        </button>
                        <button class="btn btn-primary"
                                tabindex="16"
                                @click="changeConditionCollection('videos')"
                                :aria-expanded="activatedConditionCollection === 'videos' ? 'true' : 'false'"
                                :class="{active: activatedConditionCollection === 'videos'}">
                            {{ $t('buttons.videos') }}
                        </button>
                        <button class="btn btn-primary"
                                tabindex="18"
                                @click="changeConditionCollection('licenses')"
                                :aria-expanded="activatedConditionCollection === 'licenses' ? 'true' : 'false'"
                                :class="{active: activatedConditionCollection === 'licenses'}">
                            {{ $t('buttons.licensing') }}
                        </button>
                        <button class="btn btn-primary"
                                tabindex="20"
                                @click="changeConditionCollection('categories')"
                                :aria-expanded="activatedConditionCollection === 'categories' ? 'true' : 'false'"
                                :class="{active: activatedConditionCollection === 'categories'}">
                            {{ $t('buttons.categories') }}
                        </button>
                        <button class="btn btn-primary"
                                tabindex="22"
                                @click="changeConditionCollection('regions')"
                                :aria-expanded="activatedConditionCollection === 'regions' ? 'true' : 'false'"
                                :class="{active: activatedConditionCollection === 'regions'}">
                            {{ $t('buttons.regions') }}
                        </button>
                        <button class="btn btn-primary"
                                tabindex="24"
                                @click="changeConditionCollection('people')"
                                :aria-expanded="activatedConditionCollection === 'people' ? 'true' : 'false'"
                                :class="{active: activatedConditionCollection === 'people'}">
                            {{ $t('buttons.people') }}
                        </button>
                        <button class="btn btn-primary mr-md-5"
                                tabindex="26"
                                @click="changeConditionCollection('seasons')"
                                :aria-expanded="activatedConditionCollection === 'seasons' ? 'true' : 'false'"
                                :class="{active: activatedConditionCollection === 'seasons'}">
                            {{ $t('buttons.seasons') }}
                        </button>
                        <span class="ico-block-after" @click="scrollSearchBar('right')">
                            <img src="./images/ico_search_arrow_right.svg" alt="" class="ico-arrow-after">
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="category-bar" v-show="activatedConditionCollection !== 'all'">
            <div class="container p-0">
                <div class="row category-bar-inner">
                    <div class="col-12 col-md-9 col-lg-10 px-lg-0 category-bar-list">
                        <div class="row" v-show="activatedConditionCollection === 'culturalPropertyTypes'">
                            <div class="col-6 col-md-4 col-lg-4 pl-md-0"
                                 v-for="culturalPropertyType in orderedSpecifiedCategories"
                                 :key="culturalPropertyType.id">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           tabindex="13"
                                           class="custom-control-input"
                                           role="checkbox"
                                           :aria-checked="$root.searchConditions.selectedCulturalPropertyTypes.length > 0 ?
                                        'true' : 'false'"
                                           v-model="$root.searchConditions.selectedCulturalPropertyTypes"
                                           :value="culturalPropertyType.id"
                                           :id="culturalPropertyType.id"
                                           @change="searchButtonHasClicked">
                                    <label class="custom-control-label" :for="culturalPropertyType.id">
                                        {{ culturalPropertyType.attributes.name[$i18n.locale] }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="activatedConditionCollection === 'photos'">
                            <div class="col-6 col-md-4 col-lg-4 pl-md-0" v-for="photo in searchConditions.photos"
                                 :key="photo">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           tabindex="15"
                                           class="custom-control-input"
                                           role="checkbox"
                                           :aria-checked="$root.searchConditions.selectedPhotos.length > 0 ?
                                        'true' : 'false'"
                                           v-model="$root.searchConditions.selectedPhotos"
                                           :value="photo"
                                           :id="photo"
                                           @change="searchButtonHasClicked">
                                    <label class="custom-control-label" :for="photo">
                                        {{ $t(photo) }}
                                    </label>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 px-md-0" v-for="ImageType in searchConditions.imageTypes"
                                 :key="ImageType">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           tabindex="15"
                                           class="custom-control-input"
                                           role="checkbox"
                                           :aria-checked="$root.searchConditions.selectedImageTypes.length > 0 ?
                                        'true' : 'false'"
                                           v-model="$root.searchConditions.selectedImageTypes"
                                           :value="ImageType"
                                           :id="ImageType"
                                           @change="searchButtonHasClicked">
                                    <label class="custom-control-label" :for="ImageType">
                                        {{ $t(ImageType) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="activatedConditionCollection === 'videos'">
                            <div class="col-6 col-md-4 col-lg-4 pl-md-0" v-for="duration in searchConditions.videos.durations"
                                 :key="duration">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           tabindex="17"
                                           class="custom-control-input"
                                           role="checkbox"
                                           :aria-checked="$root.searchConditions.selectedVideos.length > 0 ?
                                        'true' : 'false'"
                                           v-model="$root.searchConditions.selectedVideos"
                                           :value="duration"
                                           :id="duration"
                                           @change="searchButtonHasClicked">
                                    <label class="custom-control-label" :for="duration">
                                        {{ $t(duration) }}
                                    </label>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 px-md-0" v-for="size in searchConditions.videos.frameSizes"
                                 :key="size">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           tabindex="17"
                                           class="custom-control-input"
                                           role="checkbox"
                                           :aria-checked="$root.searchConditions.selectedSizes.length > 0 ?
                                        'true' : 'false'"
                                           v-model="$root.searchConditions.selectedSizes"
                                           :value="size"
                                           :id="size"
                                           @change="searchButtonHasClicked">
                                    <label class="custom-control-label" :for="size">
                                        {{ size }}
                                    </label>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-4 px-md-0" v-for="videoType in searchConditions.videoTypes"
                                 :key="videoType">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           tabindex="17"
                                           class="custom-control-input"
                                           role="checkbox"
                                           :aria-checked="$root.searchConditions.selectedVideoTypes.length > 0 ?
                                        'true' : 'false'"
                                           v-model="$root.searchConditions.selectedVideoTypes"
                                           :value="videoType"
                                           :id="videoType"
                                           @change="searchButtonHasClicked">
                                    <label class="custom-control-label" :for="videoType">
                                        {{ $t(videoType) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="activatedConditionCollection === 'licenses'">
                            <div class="col-12 col-md-4 col-lg-4 pl-md-0" v-for="license in searchConditions.licenses"
                                 :key="license">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           tabindex="19"
                                           class="custom-control-input"
                                           role="checkbox"
                                           :aria-checked="$root.searchConditions.selectedLicenses.length > 0 ?
                                        'true' : 'false'"
                                           v-model="$root.searchConditions.selectedLicenses"
                                           :value="license"
                                           :id="license"
                                           @change="searchButtonHasClicked">
                                    <label class="custom-control-label" :for="license">
                                        {{ $t(license) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="activatedConditionCollection === 'categories'">
                            <div class="col-6 col-md-4 col-lg-4 pl-md-0"
                                 v-for="category in $root.caches.categories.categories"
                                 :key="category.id">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           tabindex="21"
                                           class="custom-control-input"
                                           role="checkbox"
                                           :aria-checked="$root.searchConditions.selectedCategories.length > 0 ?
                                        'true' : 'false'"
                                           v-model="$root.searchConditions.selectedCategories"
                                           :value="category.id"
                                           :id="category.id"
                                           @change="searchButtonHasClicked">
                                    <label class="custom-control-label" :for="category.id">
                                        {{ category.attributes.name[$i18n.locale] }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="activatedConditionCollection === 'regions'">
                            <div class="col-6 col-md-4 col-lg-4 pl-md-0" v-for="region in orderedLocations"
                                 :key="region.id">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           tabindex="23"
                                           class="custom-control-input"
                                           role="checkbox"
                                           :aria-checked="$root.searchConditions.selectedRegions.length > 0 ?
                                        'true' : 'false'"
                                           v-model="$root.searchConditions.selectedRegions"
                                           :value="region.id"
                                           :id="region.id"
                                           @change="searchButtonHasClicked">
                                    <label class="custom-control-label" :for="region.id">
                                        {{ region.attributes.name[$i18n.locale] }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="activatedConditionCollection === 'people'">
                            <div class="col-6 col-md-4 col-lg-4 pl-md-0" v-for="person in searchConditions.people"
                                 :key="person">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           tabindex="25"
                                           class="custom-control-input"
                                           role="checkbox"
                                           :aria-checked="$root.searchConditions.selectedPeople.length > 0 ?
                                        'true' : 'false'"
                                           v-model="$root.searchConditions.selectedPeople"
                                           :value="person"
                                           :id="person"
                                           @change="searchButtonHasClicked">
                                    <label class="custom-control-label" :for="person">
                                        {{ $t(person) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-show="activatedConditionCollection === 'seasons'">
                            <div class="col-6 col-md-4 col-lg-4 pl-md-0" v-for="season in orderedSeasons"
                                 :key="season.id">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           tabindex="27"
                                           class="custom-control-input"
                                           role="checkbox"
                                           :aria-checked="$root.searchConditions.selectedSeasons.length > 0 ?
                                        'true' : 'false'"
                                           v-model="$root.searchConditions.selectedSeasons"
                                           :value="season.id"
                                           :id="season.id"
                                           @change="searchButtonHasClicked">
                                    <label class="custom-control-label" :for="season.id">
                                        {{ season.attributes.name[$i18n.locale] }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="selected-category-bar"
             v-if="hasConditions">
            <div class="container p-0">
                <button class="btn btn-primary"
                        tabindex="40"
                        v-for="categoryId in $root.searchConditions.selectedCategories"
                        :key="categoryId"
                        @click="removeCondition($root.searchConditions.selectedCategories, categoryId)">
                    <img alt="" class="ico_close mr-2" src="../../assets/icons/ico_close_blue.svg">
                    <span>{{ getCategoryName(categoryId, 'categories') }}</span>
                </button>
                <button class="btn btn-primary"
                        tabindex="40"
                        v-for="type in $root.searchConditions.selectedCulturalPropertyTypes"
                        :key="type"
                        @click="removeCondition($root.searchConditions.selectedCulturalPropertyTypes, type)">
                    <img alt="" class="ico_close mr-2" src="../../assets/icons/ico_close_blue.svg">
                    <span>{{ getCategoryName(type, 'specified_categories') }}</span>
                </button>
                <button class="btn btn-primary"
                        tabindex="45"
                        v-for="season in $root.searchConditions.selectedSeasons"
                        :key="season"
                        @click="removeCondition($root.searchConditions.selectedSeasons, season)">
                    <img alt="" class="ico_close mr-2" src="../../assets/icons/ico_close_blue.svg">
                    <span>{{ getCategoryName(season, 'seasons') }}</span>
                </button>
                <button class="btn btn-primary"
                        tabindex="49"
                        v-for="region in $root.searchConditions.selectedRegions"
                        :key="region"
                        @click="removeCondition($root.searchConditions.selectedRegions, region)">
                    <img alt="" class="ico_close mr-2" src="../../assets/icons/ico_close_blue.svg">
                    <span>{{ getCategoryName(region, 'locations') }}</span>
                </button>
                <button class="btn btn-primary"
                        tabindex="59"
                        v-for="person in $root.searchConditions.selectedPeople"
                        :key="person"
                        @click="removeCondition($root.searchConditions.selectedPeople, person)">
                    <img alt="" class="ico_close mr-2" src="../../assets/icons/ico_close_blue.svg">
                    <span>{{ $t(person) }}</span>
                </button>
                <button class="btn btn-primary"
                        tabindex="61"
                        v-for="photo in $root.searchConditions.selectedPhotos"
                        :key="photo"
                        @click="removeCondition($root.searchConditions.selectedPhotos, photo)">
                    <img alt="" class="ico_close mr-2" src="../../assets/icons/ico_close_blue.svg">
                    <span>{{ $t(photo) }}</span>
                </button>
                <button class="btn btn-primary"
                        tabindex="63"
                        v-for="duration in $root.searchConditions.selectedVideos"
                        :key="duration"
                        @click="removeCondition($root.searchConditions.selectedVideos, duration)">
                    <img alt="" class="ico_close mr-2" src="../../assets/icons/ico_close_blue.svg">
                    <span>{{ $t(duration) }}</span>
                </button>
                <button class="btn btn-primary"
                        tabindex="65"
                        v-for="size in $root.searchConditions.selectedSizes"
                        :key="size"
                        @click="removeCondition($root.searchConditions.selectedSizes, size)">
                    <img alt="" class="ico_close mr-2" src="../../assets/icons/ico_close_blue.svg">
                    <span>{{ size }}</span>
                </button>
                <button class="btn btn-primary"
                        tabindex="67"
                        v-for="imageType in $root.searchConditions.selectedImageTypes"
                        :key="imageType"
                        @click="removeCondition($root.searchConditions.selectedImageTypes, imageType)">
                    <img alt="" class="ico_close mr-2" src="../../assets/icons/ico_close_blue.svg">
                    <span>{{ $t(imageType) }}</span>
                </button>
                <button class="btn btn-primary"
                        tabindex="69"
                        v-for="videoType in $root.searchConditions.selectedVideoTypes"
                        :key="videoType"
                        @click="removeCondition($root.searchConditions.selectedVideoTypes, videoType)">
                    <img alt="" class="ico_close mr-2" src="../../assets/icons/ico_close_blue.svg">
                    <span>{{ $t(videoType) }}</span>
                </button>
                <button class="btn btn-primary"
                        tabindex="70"
                        v-for="license in $root.searchConditions.selectedLicenses"
                        :key="license"
                        @click="removeCondition($root.searchConditions.selectedLicenses, license)">
                    <img alt="" class="ico_close mr-2" src="../../assets/icons/ico_close_blue.svg">
                    <span>{{ $t(license) }}</span>
                </button>
                <button class="btn btn-light"
                        tabindex="80"
                        @click="removeAllCondition">
                    <img alt="" class="ico_close clear_all mr-2" src="../../assets/icons/ico_close_black.svg"> {{ $t('buttons.clear')}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import ApiRouter from "../Systems/ApiRouter";
    import SearchEvent from "../Helper/SearchEvent";

    export default {
        name: "SearchPanel",
        mixins: [ApiRouter, SearchEvent],
        data: function () {
            return {
                activatedConditionCollection: 'all',
                searchConditions: {
                    culturalPropertyTypes: [
                        'searchConditions.culturalPropertyTypes.worldHeritage',
                        'searchConditions.culturalPropertyTypes.japaneseHeritage',
                        'searchConditions.culturalPropertyTypes.nationalTreasure',
                        'searchConditions.culturalPropertyTypes.important',
                        'searchConditions.culturalPropertyTypes.other'
                    ],
                    seasons: [
                        'searchConditions.seasons.spring',
                        'searchConditions.seasons.summer',
                        'searchConditions.seasons.autumn',
                        'searchConditions.seasons.winter'
                    ],
                    regions: [
                        'searchConditions.regions.hokkaido',
                        'searchConditions.regions.tohoku',
                        'searchConditions.regions.kanto',
                        'searchConditions.regions.hokuriku',
                        'searchConditions.regions.tokai',
                        'searchConditions.regions.kansai',
                        'searchConditions.regions.chugoku',
                        'searchConditions.regions.shikoku',
                        'searchConditions.regions.kyusyu',
                        'searchConditions.regions.okinawa'
                    ],
                    people: [
                        'searchConditions.people.exist',
                        'searchConditions.people.notExist'
                    ],
                    photos: [
                        'searchConditions.photos.length',
                        'searchConditions.photos.width'
                    ],
                    videos: {
                        durations: [
                            'searchConditions.videos.duration.more30Seconds',
                            'searchConditions.videos.duration.more60Seconds'
                        ],
                        frameSizes: [
                            'HD',
                            'FullHD',
                            '4K'
                        ],
                    },
                    imageTypes: [
                        'searchConditions.imageTypes.vr',
                    ],
                    videoTypes: [
                        'searchConditions.videoTypes.vr',
                    ],
                    licenses: [
                        'searchConditions.licenses.all',
                        'searchConditions.licenses.impossible',
                        'searchConditions.licenses.notation',
                        'searchConditions.licenses.others'
                    ]
                },
            }
        },
        methods: {
            changeConditionCollection: function (id) {
                if (id === this.activatedConditionCollection) {
                    this.activatedConditionCollection = 'all';
                } else if (id === 'all') {
                    this.removeAllCondition();
                    this.activatedConditionCollection = 'all';
                } else {
                    this.activatedConditionCollection = id;
                }
            },
            removeAllCondition: function () {
                this.$root.caches.selectedSearchPullDown = "all";
                this.$root.searchConditions.selectedCategories = [];
                this.$root.searchConditions.selectedCulturalPropertyTypes = [];
                this.$root.searchConditions.selectedSeasons = [];
                this.$root.searchConditions.selectedRegions = [];
                this.$root.searchConditions.selectedPeople = [];
                this.$root.searchConditions.selectedPhotos = [];
                this.$root.searchConditions.selectedVideos = [];
                this.$root.searchConditions.selectedSizes = [];
                this.$root.searchConditions.selectedImageTypes = [];
                this.$root.searchConditions.selectedVideoTypes = [];
                this.$root.searchConditions.selectedVideoTypes = [];
                this.$root.searchConditions.selectedLicenses = [];
                this.searchButtonHasClicked();
            },
            removeCondition: function (selectedConditions, selectedCondition) {
                selectedConditions.some((item, index) => {
                    if (item === selectedCondition) {
                        selectedConditions.splice(index, 1);
                    }
                });
                this.searchButtonHasClicked();
            },
            getCategoryName: function (categoryId, type) {
                const selectedCategory = this.$root.caches.categories[type].find((category) => {
                    return (category.id === categoryId)
                });

                return selectedCategory.attributes.name[this.$i18n.locale];
            },
            scrollSearchBar: function (direction) {
                const element = document.getElementById("search-bar-links-inner");
                if (direction === 'left') {
                    element.scrollLeft -= 50;
                } else {
                    element.scrollLeft += 50;
                }
            }
        },
        computed: {
            hasConditions: function () {
                return this.$root.searchConditions.selectedCategories.length > 0 ||
                    this.$root.searchConditions.selectedCulturalPropertyTypes.length > 0 ||
                    this.$root.searchConditions.selectedSeasons.length > 0 ||
                    this.$root.searchConditions.selectedRegions.length > 0 ||
                    this.$root.searchConditions.selectedPeople.length > 0 ||
                    this.$root.searchConditions.selectedPhotos.length > 0 ||
                    this.$root.searchConditions.selectedVideos.length > 0 ||
                    this.$root.searchConditions.selectedSizes.length > 0 ||
                    this.$root.searchConditions.selectedImageTypes.length > 0 ||
                    this.$root.searchConditions.selectedVideoTypes.length > 0 ||
                    this.$root.searchConditions.selectedLicenses.length > 0;
            },
            orderedSpecifiedCategories: function () {
                if (this.$root.caches.categories.length === 0) return [];
                const specified_categories = this.$root.caches.categories.specified_categories;
                return specified_categories.sort((a, b) => {
                    return (a.attributes.rank < b.attributes.rank) ? -1 : (a.attributes.rank > b.attributes.rank) ? 1 : 0;
                });
            },
            orderedLocations: function () {
                if (this.$root.caches.categories.length === 0) return [];
                const locations = this.$root.caches.categories.locations;
                return locations.sort((a, b) => {
                    return (a.attributes.rank < b.attributes.rank) ? -1 : (a.attributes.rank > b.attributes.rank) ? 1 : 0;
                });
            },
            orderedSeasons: function () {
                if (this.$root.caches.categories.length === 0) return [];
                const seasons = this.$root.caches.categories.seasons;
                return seasons.sort((a, b) => {
                    return (a.attributes.rank < b.attributes.rank) ? -1 : (a.attributes.rank > b.attributes.rank) ? 1 : 0;
                });
            }
        }
    }
</script>

<style src="./SearchPanel.scss" lang="scss" scoped />
