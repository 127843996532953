<template>
    <div>
        <div class="container">
        <div class="breadcrumb-list" aria-label="パンくずリスト">
            <router-link :to="{name: 'Top'}" :aria-label="$t('breadcrumbs.top')">
                <span class="step">{{ $t('buttons.top') }}</span>
            </router-link>
            <span aria-current="page">{{ $t('buttons.howToUse') }}</span>
        </div>
        <div class="container">
            <div class="row text-center">
                <div class="col-12">
                    <h1 class="title" id="contents-area">{{ $t('buttons.howToUse') }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-12 contents">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br><br>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br><br>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HowToUse",
        metaInfo: function() {
            return {
                // TODO ご利用方法の言語変数が出来次第差し替える
                title: this.$t('buttons.howToUse'),
                titleTemplate: `%s | ${this.$t('siteInfo.title')}`,
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
                    },
                    {
                        vmid: 'og-title',
                        property: 'og:title',
                        content: this.$t('buttons.howToUse'),
                    },
                    {
                        vmid: 'og-description',
                        property: 'og:description',
                        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
                    }
                ]
            }
        }
    }
</script>
