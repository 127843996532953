<template>
    <div class="tool-bar">
        <div class="row">
            <div class="col-8 col-md-10 col-lg-8">
                <div class="switch-display d-none d-md-inline-flex">
                    <div class="d-sm-none d-md-inline-flex">
                        <p class="tool-bar-text d-flex align-items-center"><span tabindex="0">{{ $t('toolbars.styles') }}</span></p>
                        <button class="btn btn-link"
                                :aria-label="$t('accessibility.largeCollection')"
                                tabindex="0"
                                @click="changeDisplayStyle('largeCollection')"
                                :class="{active: activeDisplayStyle === 'largeCollection'}">
                            <icon icon="th-large" size="2x"></icon>
                        </button>
                        <button class="btn btn-link"
                                :aria-label="$t('accessibility.smallCollection')"
                                tabindex="0"
                                @click="changeDisplayStyle('smallCollection')"
                                :class="{active: activeDisplayStyle === 'smallCollection'}">
                            <icon icon="th" size="2x"></icon>
                        </button>
                        <button class="btn btn-link"
                                :aria-label="$t('accessibility.list')"
                                tabindex="0"
                                @click="changeDisplayStyle('list')"
                                :class="{active: activeDisplayStyle === 'list'}">
                            <icon icon="th-list" size="2x"></icon>
                        </button>
                    </div>
                    <div class="d-md-inline-flex display-number">
                        <p class="tool-bar-text d-flex align-items-center"><span tabindex="0">{{ $t('toolbars.perPage') }}</span></p>
                        <button class="btn btn-primary d-md-inline-flex align-items-center justify-content-center"
                                :aria-label="$t('accessibility.changeNumberTo1')"
                                tabindex="0"
                                @click="changeDisplayContentCount(displayCount.s)"
                                :class="{active: $root.searchConditions.limit === displayCount.s}">
                            {{ displayCount.s }}
                        </button>
                        <button class="btn btn-primary d-md-inline-flex align-items-center justify-content-center"
                                :aria-label="$t('accessibility.changeNumberTo2')"
                                tabindex="0"
                                @click="changeDisplayContentCount(displayCount.m)"
                                :class="{active: $root.searchConditions.limit === displayCount.m}">
                            {{ displayCount.m }}
                        </button>
                        <button class="btn btn-primary d-md-inline-flex align-items-center justify-content-center"
                                :aria-label="$t('accessibility.changeNumberTo5')"
                                tabindex="0"
                                @click="changeDisplayContentCount(displayCount.l)"
                                :class="{active: $root.searchConditions.limit === displayCount.l}">
                            {{ displayCount.l }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-4 col-md-2 col-lg-4">
                <div class="dropdown-sort d-none d-md-inline-flex justify-content-end">
                    <div class="dropdown">
                        <button class="btn btn-light dropdown-toggle"
                                tabindex="0"
                                type="button"
                                aria-haspopup="true"
                                :aria-label="$t('accessibility.dropdownSort')"
                                :aria-expanded="dropDownToSort ? 'true' : 'false'"
                                @click="sortDropDownSwitch">
                            {{ $t('toolbars.' + activeSortByMenu) }}
                        </button>
                        <div v-show="dropDownToSort"
                             class="dropdown-menu dropdown-menu-right"
                             role="menu"
                             :aria-label="$t('accessibility.dropdownSort')">
                            <button class="dropdown-item"
                                    tabindex="0"
                                    type="button"
                                    :aria-label="$t('accessibility.createdAt')"
                                    @click="changeSortBy('created_at')">
                                {{ $t('toolbars.createdAt') }}
                            </button>
                            <button class="dropdown-item"
                                    tabindex="0"
                                    type="button"
                                    :aria-label="$t('accessibility.photographedAt')"
                                    @click="changeSortBy('photographed_at')">
                                {{ $t('toolbars.photographedAt') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="smart-phone-display d-inline-flex d-md-none justify-content-center">
                <div class="dropdown">
                    <button class="btn btn-light dropdown-toggle"
                            tabindex="0"
                            type="button"
                            aria-haspopup="true"
                            :aria-expanded="dropDownToNumber ? 'true' : 'false'"
                            @click="numberDropDownSwitch">
                        {{ $t('toolbars.perPage') }}
                    </button>
                    <div v-show="dropDownToNumber"
                         class="dropdown-menu-number"
                         role="menu"
                         :aria-label="$t('accessibility.dropdownNumber')">
                        <button class="dropdown-item"
                                tabindex="0"
                                type="button"
                                :aria-label="$t('accessibility.changeNumberTo1')"
                                :class="{active: $root.searchConditions.limit === displayCount.s}"
                                @click="changeDisplayContentCount(displayCount.s)">
                            {{ displayCount.s }}
                        </button>
                        <button class="dropdown-item"
                                tabindex="0"
                                type="button"
                                :aria-label="$t('accessibility.changeNumberTo2')"
                                :class="{active: $root.searchConditions.limit === displayCount.m}"
                                @click="changeDisplayContentCount(displayCount.m)">
                            {{ displayCount.m }}
                        </button>
                        <button class="dropdown-item"
                                tabindex="0"
                                type="button"
                                :aria-label="$t('accessibility.changeNumberTo5')"
                                :class="{active: $root.searchConditions.limit === displayCount.l}"
                                @click="changeDisplayContentCount(displayCount.l)">
                            {{ displayCount.l }}
                        </button>
                    </div>
                </div>
                <div class="dropdown">
                    <button class="btn btn-light dropdown-toggle"
                            tabindex="0"
                            type="button"
                            aria-haspopup="true"
                            :aria-expanded="dropDownToSort ? 'true' : 'false'"
                            @click="sortDropDownSwitch">
                        {{ $t('toolbars.' + activeSortByMenu) }}
                    </button>
                    <div v-show="dropDownToSort"
                         class="dropdown-menu-sort"
                         role="menu"
                         :aria-label="$t('accessibility.dropdownSort')">
                        <button class="dropdown-item"
                                tabindex="0"
                                type="button"
                                :aria-label="$t('accessibility.createdAt')"
                                @click="changeSortBy('created_at')">
                            {{ $t('toolbars.createdAt') }}
                        </button>
                        <button class="dropdown-item"
                                tabindex="0"
                                type="button"
                                :aria-label="$t('accessibility.photographedAt')"
                                @click="changeSortBy('photographed_at')">
                            {{ $t('toolbars.photographedAt') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ApiRouter from "../Systems/ApiRouter";
    import Helper from "../Helper/Helper";

    export default {
        name: "ToolBar",
        mixins: [ApiRouter, Helper],
        data: function () {
            return {
                displayCount: {
                    s: 50,
                    m: 100,
                    l: 200
                },
                dropDownToSort: false,
                dropDownToNumber: false,
                activeSortByMenu: 'sortBy',
                activeDisplayStyle: 'smallCollection'
            }
        },
        methods: {
            sortDropDownSwitch: function () {
                this.dropDownToSort = !this.dropDownToSort;
            },
            numberDropDownSwitch: function () {
                this.dropDownToNumber = !this.dropDownToNumber;
            },
            changeDisplayStyle: function (style) {
                this.activeDisplayStyle = style;
                this.$emit('display-style-button-clicked', style)
            },
            changeDisplayContentCount: function (count) {
                this.$root.searchConditions.limit = count;
                this.$root.searchContents();
                this.dropDownToNumber = false;
            },
            changeSortBy: function (sort) {
                this.$root.searchConditions.sort = sort;
                this.$root.searchContents();
                this.dropDownToSort = !this.dropDownToSort;
                this.activeSortByMenu = this.convertCamelCase(sort);
            }
        }
    }
</script>

<style src="./ToolBar.scss" lang="scss" scoped />
