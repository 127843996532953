<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex">
                <div class="border-right">
                    <div class="row">
                        <div class="col-12 pl-0">
                            <div class="thumb-block"
                                 tabindex="0"
                                 role="link"
                                 @keyup.enter="contentTableHasClicked(content)"
                                 @keyup.space="contentTableHasClicked(content)"
                                 @click="contentTableHasClicked(content)"
                                 @mouseover="selectVideo(content)"
                                 @mouseleave="selectedVideo = ''">
                                <figure id="contents-area" class="thumb"
                                        v-if="content.attributes.content_type === 'video'
                                        && content.attributes.video_type !== 'searchConditions.videoTypes.vr'"
                                        v-html="videoPreview(content)"
                                        :aria-label="(content.relationships.details[this.$i18n.locale].title) +
                                            $t('accessibility.contentImage')">
                                </figure>
                                <figure v-else class="thumb">
                                    <img :src="content.attributes.thumbnail_url"
                                         :alt="(content.relationships.details[this.$i18n.locale].title) +
                                            $t('accessibility.contentImage')">
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div class="row d-none d-lg-block">
                        <div class="col-12">
                            <div class="action-area d-flex justify-content-start align-items-center">
                                <button class="btn btn-light btn-sm mr-2"
                                        v-if="enableAddToFavorites"
                                        @click="addToFavorites(content)"
                                        :aria-label="$t('accessibility.addToFavorites')"
                                        tabindex="0">
                                    <svg class="ico_add" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                        <g transform="translate(0 0)">
                                            <text transform="translate(0 11)" fill="#696969" font-size="12" font-family="HiraginoSans-W3, Hiragino Sans"><tspan x="0" y="0">＋</tspan></text>
                                        </g>
                                    </svg> {{ $t('contents.addToFavorites') }}
                                </button>
                                <button v-if="!enableAddToFavorites"
                                        @click="removeFromFavorites(content.id)"
                                        class="btn btn-light btn-sm mr-2"
                                        :aria-label="$t('accessibility.removeFromFavorites')"
                                        tabindex="0">
                                    {{ $t('contents.removeFromFavorites') }}
                                </button>
                                <button class="btn btn-light btn-sm"
                                        v-if="content.attributes.content_type === 'image'"
                                        @click="$emit('zoom-button-has-clicked', content)"
                                        :aria-label="$t('accessibility.zoomContent')"
                                        tabindex="0">
                                    <svg class="ico_zoom" xmlns="http://www.w3.org/2000/svg" width="16.905" height="16.905" viewBox="0 0 16.905 16.905">
                                        <g transform="translate(0.375 0.375)">
                                            <circle cx="5.965" cy="5.965" r="5.965" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.75"/>
                                            <line x2="5.5" y2="5.5" transform="translate(10.5 10.5)" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" fill="none"/>
                                        </g>
                                    </svg> {{ $t('contents.zoom') }}
                                </button>
                                <img tabindex="0" alt="" v-if="content.attributes.image_type === 'searchConditions.imageTypes.vr'
                                || content.attributes.video_type === 'searchConditions.videoTypes.vr'"
                                     class="ico_movie_picture mb-adjust"
                                     src="@/assets/icons/ico_vr.svg">
                                <img tabindex="0" alt="" v-else-if="content.attributes.content_type === 'video'
                                && content.attributes.video_type !== 'searchConditions.videoTypes.vr'"
                                     class="ico_movie_picture mb-adjust"
                                     src="@/assets/icons/ico_movie_play.svg">
                                <img tabindex="0" alt="" v-else-if="content.attributes.content_type === 'image'
                                && content.attributes.image_type !== 'searchConditions.imageTypes.vr'"
                                     class="ico_movie_picture"
                                     src="@/assets/icons/ico_picture.svg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex w-100">
                    <div class="col-6 px-0 d-none d-lg-block right-border" role="table">
                        <table class="table table-borderless content-table d-none d-lg-table" role="rowgroup">
                            <caption tabindex="0" class="voice-guide">{{ $t('contents.aboutTheWork' )}}</caption>
                            <thead>
                            <tr role="row">
                                <th colspan="2" class="list-info bg-light table-head w-50" role="columnheader" tabindex="0">
                                    {{ $t('contents.aboutTheWork' )}}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr role="row">
                                <td class="font-weight-bold" role="cell" tabindex="0">ID</td>
                                <td class="right-border"
                                    role="cell"
                                    :aria-label="$t('accessibility.contentId')"
                                    @click="contentTableHasClicked(content)"
                                    @keyup.enter="contentTableHasClicked(content)"
                                    @keyup.space="contentTableHasClicked(content)">
                                    <img tabindex="0" alt="" v-if="content.attributes.content_type === 'video'
                                && content.attributes.video_type !== 'searchConditions.videoTypes.vr'"
                                         class="ico_movie_picture mb-adjust"
                                         src="@/assets/icons/ico_movie_play.svg">
                                    <img tabindex="0" alt="" v-else-if="content.attributes.content_type === 'video'
                                && content.attributes.video_type === 'searchConditions.videoTypes.vr'"
                                         class="ico_movie_picture mb-adjust"
                                         src="@/assets/icons/ico_vr.svg">
                                    <img tabindex="0" alt="" v-else class="ico_movie_picture" src="@/assets/icons/ico_picture.svg">
                                    {{ content.id }}
                                </td>
                            </tr>
                            <tr role="row">
                                <td class="font-weight-bold" role="cell" tabindex="0">{{ $t('contents.title') }}</td>
                                <td class="right-border"
                                    role="cell"
                                    @click="contentTableHasClicked(content)"
                                    @keyup.enter="contentTableHasClicked(content)"
                                    @keyup.space="contentTableHasClicked(content)"
                                    tabindex="0">{{ content.relationships.details[this.$i18n.locale].title }}</td>
                            </tr>
                            <tr role="row">
                                <td class="font-weight-bold" role="cell" tabindex="0">{{ $t('contents.subtitle') }}</td>
                                <td class="right-border"
                                    role="cell"
                                    @click="contentTableHasClicked(content)"
                                    @keyup.enter="contentTableHasClicked(content)"
                                    @keyup.space="contentTableHasClicked(content)"
                                    tabindex="0">{{ content.relationships.details[this.$i18n.locale].subtitle }}</td>
                            </tr>
                            <tr role="row">
                                <td class="font-weight-bold" role="cell" tabindex="0">{{ $t('contents.date') }}</td>
                                <td class="right-border" v-if="content.attributes.photographed_at" tabindex="0" role="cell">
                                    {{ content.attributes.photographed_at | dateFormat }}
                                </td>
                                <td class="right-border" v-else role="cell">
                                    -
                                </td>
                            </tr>
                            <tr role="row">
                                <td class="font-weight-bold" role="cell" tabindex="0">{{ $t('contents.licensing') }}</td>
                                <td class="right-border" tabindex="0" role="cell">{{ $t(content.attributes.license_type) }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold" role="cell" tabindex="0">{{ $t('contents.size') }}</td>
                                <td class="right-border" tabindex="0" role="cell">
                                    {{ content.attributes.width }} × {{ content.attributes.height }}
                                    px ({{ convertByteSize(content.attributes.size) }})
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-6 px-0 d-none d-lg-block" role="table">
                        <table class="table table-borderless content-table d-none d-lg-table" role="rowgroup">
                            <caption tabindex="0" class="voice-guide">{{ $t('contents.aboutTheCulturalProperty') }}</caption>
                            <thead>
                            <tr role="row">
                                <th colspan="2" class="bg-light table-head w-50" role="columnheader" tabindex="0">
                                    {{ $t('contents.aboutTheCulturalProperty') }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr role="row">
                                <td class="font-weight-bold" role="cell" tabindex="0">{{ $t('contents.name') }}</td>
                                <td tabindex="0" role="cell">
                                    {{ content.relationships.culturalProps[this.$i18n.locale].name }}
                                </td>
                            </tr>
                            <tr role="row">
                                <td class="font-weight-bold" role="cell" tabindex="0">{{ $t('contents.designation') }}</td>
                                <td tabindex="0" role="cell" v-if="content.relationships.culturalProps[this.$i18n.locale].specified_category">
                                    {{ content.relationships.culturalProps[this.$i18n.locale].specified_category }}
                                </td>
                                <td tabindex="0" role="cell" v-else>-</td>
                            </tr>
                            <tr role="row">
                                <td class="font-weight-bold" role="cell" tabindex="0">{{ $t('contents.year') }}</td>
                                <td tabindex="0" role="cell" v-if="content.relationships.culturalProps[this.$i18n.locale].ad">
                                    {{ content.relationships.culturalProps[this.$i18n.locale].ad }}
                                </td>
                                <td tabindex="0" role="cell" v-else>-</td>
                            </tr>
                            <tr role="row">
                                <td class="font-weight-bold" role="cell" tabindex="0">{{ $t('contents.location') }}</td>
                                <td tabindex="0" role="cell">{{ content.relationships.culturalProps[this.$i18n.locale].location }}</td>
                            </tr>
                            <tr role="row">
                                <td role="none"></td>
                                <td role="none"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="d-flex d-lg-none flex-column justify-content-center h-100" role="table">
                        <div class="ml-2 mb-1" role="row">
                                <span class="text-secondary content-info m-0"
                                      role="cell"
                                      @click="contentTableHasClicked(content)"
                                      @keyup.enter="contentTableHasClicked(content)"
                                      @keyup.space="contentTableHasClicked(content)"
                                      :aria-label="$t('accessibility.contentId')"
                                      tabindex="0">
                                    <img alt="" v-if="content.attributes.content_type === 'video'" class="ico_movie_picture" src="@/assets/icons/ico_movie_play.svg">
                                    <img alt="" v-else class="ico_movie_picture" src="@/assets/icons/ico_picture.svg">
                                    {{ content.id }}
                                </span>
                        </div>
                        <div class="ml-2 mb-1" role="row">
                                <span class="text-secondary content-info m-0"
                                      role="cell"
                                      @click="contentTableHasClicked(content)"
                                      @keyup.enter="contentTableHasClicked(content)"
                                      @keyup.space="contentTableHasClicked(content)"
                                      :aria-label="$t('accessibility.contentId')"
                                      tabindex="0">
                                    {{ content.relationships.details[this.$i18n.locale].title }}
                                </span>
                        </div>
                        <div class="ml-2 m-1 content-info">
                            <button class="btn btn-dark btn-sm"
                                    v-if="enableAddToFavorites"
                                    @click="addToFavorites(content)"
                                    :aria-label="$t('accessibility.addToFavorites')"
                                    tabindex="0">
                                <icon icon="plus"></icon> {{ $t('contents.addToFavorites') }}
                            </button>
                            <button class="btn btn-dark btn-sm"
                                    v-if="!enableAddToFavorites"
                                    @click="removeFromFavorites(content.id)"
                                    :aria-label="$t('accessibility.removeFromFavorites')"
                                    tabindex="0">
                                {{ $t('contents.removeFromFavorites') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Helper from "../Helper/Helper";
    import FavoriteKit from "../Helper/FavoriteKit";
    export default {
        name: "ContentTable",
        mixins: [Helper, FavoriteKit],
        props: ['content'],
        created: function () {
            this.contentHasAdded();
        },
        data: function () {
            return {
                selectedVideo: ""
            }
        },
        methods: {
            contentTableHasClicked: function (content) {
                this.$router.push({name: 'ContentDetail', params: {id: content.id}});
            },
            videoPreview: function (content) {
                if (this.selectedVideo === content.id) {
                    return content.attributes.video_path
                } else {
                    return "<img src='" + content.attributes.thumbnail_url + "' " +
                        "style=\"width: 100%; display: flex; align-items: center; justify-content: center;\">"
                }
            },
            selectVideo: function (content) {
                this.selectedVideo = content.id;
            }
        }
    }
</script>

<style src="./ContentTable.scss" lang="scss" scoped />
<style src="./ContentTableGlobal.scss" lang="scss" />
