<template>
    <div class="container container-xxl">
        <div class="row row-cols-1 row-cols-xl-2 content-list-sp">
            <div class="col mb-md-4 p-0 pl-md-3 pr-md-3" v-for="content in $root.caches.contentCollection.data" :key="content.id">
                <content-table
                    :content="content"
                    @zoom-button-has-clicked="$refs.imagePreviewModal.open(content)">
                </content-table>
            </div>
            <image-preview-modal ref="imagePreviewModal"></image-preview-modal>
        </div>
    </div>
</template>

<script>
    import ContentTable from "../ContentTable/ContentTable";
    import ImagePreviewModal from "../ImagePreviewModal/ImagePreviewModal";
    export default {
        name: "ContentList",
        components: {ImagePreviewModal, ContentTable}
    }
</script>

<style lang="scss">
    .content-list-sp {
        &.row {
            @media screen and (max-width: 320px) {
                margin-left: -30px;
                margin-right: -30px;
            }
        }
    }
</style>

<style src="./ContentList.scss" lang="scss" scoped />
