<template>
    <div class="top-layout">
        <voice-guide></voice-guide>
        <top-header ref="topHeader"></top-header>
        <router-view></router-view>
        <footer-panel></footer-panel>
        <footer-panel-smart-phone :show-back-to-top-btn="true"></footer-panel-smart-phone>
    </div>
</template>

<script>
    import TopHeader from "../../TopHeader/TopHeader";
    import FooterPanel from "../../FooterPanel/FooterPanel";
    import FooterPanelSmartPhone from "../../FooterPanelSmartPhone/FooterPanelSmartPhone";
    import VoiceGuide from "../../VoiceGuide/VoiceGuide";
    export default {
        name: "TopLayout",
        components: {VoiceGuide, FooterPanelSmartPhone, FooterPanel, TopHeader}
    }
</script>
