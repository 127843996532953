<template>
    <div class="collapse-card">
        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between card-header-inner">
                    <p class="mb-0">
                        {{ title }}
                    </p>
<!--                    <a href="javascript:void(0)"-->
<!--                       class="text-dark"-->
<!--                       @click="toggleCollapseCard"-->
<!--                       :aria-expanded="collapsed ? 'false' : 'true'"-->
<!--                       :aria-controls="id">-->
<!--                        <img alt="" v-if="collapsed" src="@/assets/icons/ico_arrow_down_gray.svg">-->
<!--                        <img alt="" v-else src="@/assets/icons/ico_arrow_up_gray.svg">-->
<!--                    </a>-->
                </div>
            </div>

            <transition name="slide-fade-up">
                <div :id="id" class="collapse show" v-if="!collapsed">
                    <div class="card-body">
                        <slot></slot>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CollapseCard",
        props: ['id', 'title'],
        data: function () {
            return {
                collapsed: false
            }
        },
        methods: {
            toggleCollapseCard: function () {
                this.collapsed = !this.collapsed;
            }
        }
    }
</script>

<style src="./CollapseCard.scss" lang="scss" scoped />
