<template>
    <div>
        <div class="container">
            <div class="breadcrumb-list" aria-label="パンくずリスト">
                <router-link :to="{name: 'Top'}" :aria-label="$t('breadcrumbs.top')">
                    <span class="step">{{ $t('buttons.top') }}</span>
                </router-link>
                <span aria-current="page">{{ $t('buttons.contact') }}</span>
            </div>
            <div class="container">
                <div class="row text-center">
                    <div class="col-12">
                        <h1 class="title" id="contents-area">{{ $t('buttons.contact') }}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 contents">
                        <p>{{ $t('staticPages.contactUs.title') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactUs",
        metaInfo: function() {
            return {
                // TODO 問い合わせの言語変数が出来次第差し替える
                title: this.$t('buttons.contact'),
                titleTemplate: `%s | ${this.$t('siteInfo.title')}`,
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
                    },
                    {
                        vmid: 'og-title',
                        property: 'og:title',
                        content: this.$t('buttons.contact'),
                    },
                    {
                        vmid: 'og-description',
                        property: 'og:description',
                        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
                    }
                ]
            }
        }
    }
</script>
