<template>
    <div class="content-collection">
        <div class="row row-cols-1 row-cols-sm-2" :class="sizeClass">
            <div class="col" v-for="content in $root.caches.contentCollection.data" :key="content.id">
                <content-card :content="content"
                              @zoom-button-has-clicked="$refs.imagePreviewModal.open(content)">
                </content-card>
            </div>
        </div>
        <image-preview-modal ref="imagePreviewModal"></image-preview-modal>
    </div>
</template>

<script>
    import ContentCard from "../ContentCard/ContentCard";
    import ImagePreviewModal from "../ImagePreviewModal/ImagePreviewModal";
    export default {
        name: "ContentCollection",
        components: {ImagePreviewModal, ContentCard},
        props: ['size'],
        computed: {
            sizeClass: function () {
                return [
                    `row-cols-md-${this.size - 4}`,
                    `row-cols-lg-${this.size - 2}`,
                    `row-cols-xxl-${this.size}`
                ];
            }
        }
    }
</script>

<style src="./ContentCollection.scss" lang="scss" scoped />
