<template>
    <div>
        <div class="modal fade"
             tabindex="-1"
             :aria-label="$t('accessibility.additionalTermsModal')"
             :aria-hidden="!isOpen"
             :aria-modal="isOpen"
             :class="{show: isOpen}"
             :style="{display: isOpen ? 'block' : 'none'}">
            <div class="modal-dialog"
                 role="dialog"
                 id="additionalTermsModal"
                 tabindex="0">
                <div class="modal-content" role="document">
                    <div class="modal-header">
                        <h2 tabindex="0" class="modal-title">
                            {{ $t('buttons.terms') }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div tabindex="0" class="modal-text" v-html="$t(content.attributes.additional_restriction)"></div>
                        <div class="text-center btn-list">
                            <button tabindex="0" class="btn btn-agree btn-primary mr-3" @click="agree">{{ $t('buttons.agree') }}</button>
                            <button tabindex="0" class="btn btn-disagree btn-danger" @click="disagree">{{ $t('buttons.disagree') }}</button>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                    <button type="button"
                            tabindex="0"
                            @click="close"
                            class="close"
                            data-dismiss="modal"
                            :aria-label="$t('accessibility.closeModal')">
                            <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade show" v-show="isOpen"></div>
    </div>
</template>

<script>
    export default {
        name: "AdditionalTermsModal",
        props: ['content'],
        data: function () {
            return {
                isOpen: false,
                lastFocusedElement: null
            }
        },
        updated: function () {
            this.focusElement();
        },
        methods: {
            open: function () {
                this.lastFocusedElement = document.activeElement;
                const body = document.getElementsByTagName('body')[0];
                body.classList.toggle('modal-open');
                this.isOpen = true;
            },
            close: function () {
                const body = document.getElementsByTagName('body')[0];
                body.classList.remove('modal-open');
                this.isOpen = false;
                this.$emit('additional-terms-modal-has-closed');
                this.lastFocusedElement.focus();
            },
            focusElement: function () {
                if (this.isOpen) {
                    const modal = document.getElementById('additionalTermsModal');
                    modal.focus();
                }
            },
            agree: function () {
                this.$parent.isAdditionalTermsAgree = true;
                this.close();
            },
            disagree: function () {
                this.$parent.isAdditionalTermsAgree = false;
                this.close();
            }
        }
    }
</script>

<style src="./AdditionalTermsModal.scss" lang="scss" scoped />
