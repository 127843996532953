<template>
    <div class="common-block">
        <div class="px-md-0 col-12">
            <ul class="breadcrumb-list container mx-md-3 mx-xl-auto row" aria-label="パンくずリスト">
                <li>
                    <router-link tabindex="0" :to="{name: 'Top'}" :aria-label="$t('breadcrumbs.top')">
                        <span class="step">{{ $t('buttons.top') }}</span>
                    </router-link>
                </li>
                <li>
                    <span tabindex="0" aria-current="page">{{ $t('siteInfo.histories') }}</span>
                </li>
            </ul>
        </div>
        <div class="container text-center">
            <h1 class="title" id="contents-area"><span tabindex="0">{{ $t('siteInfo.histories') }}</span></h1>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6">
            <div class="col mb-4"
                 v-for="content in reverseObject($root.caches.viewingHistoryOfContents)"
                 :key="content.id">
                <content-card :content="content"
                              @zoom-button-has-clicked="$refs.imagePreviewModal.open(content)">
                </content-card>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center loader"
             v-if="$root.caches.contentCollection.data === undefined">
            <h1>Loading...</h1>
        </div>
        <image-preview-modal ref="imagePreviewModal"></image-preview-modal>
    </div>
</template>

<script>
    import ContentCard from "../../components/ContentCard/ContentCard";
    import ImagePreviewModal from "../../components/ImagePreviewModal/ImagePreviewModal";
    export default {
        name: "ViewingHistory",
        components: {ImagePreviewModal, ContentCard},
        metaInfo: function() {
            return {
                title: this.$t('siteInfo.histories'),
                titleTemplate: `%s | ${this.$t('siteInfo.title')}`,
                meta: [
                    {
                        vmid: 'og-title',
                        property: 'og:title',
                        content: this.$t('siteInfo.histories')
                    }
                ]
            }
        },
        methods: {
            reverseObject: function (object) {
                const reverseObj = {};
                Object.keys(object).reverse().forEach((key) => {
                    reverseObj[key] = object[key]
                });
                return reverseObj;
            }
        }
    }
</script>

<style src="./ViewingHistory.scss" lang="scss" scoped />
