<script>
    const host = `${process.env.VUE_APP_API_URL}`;
    export default {
        name: "ApiRouter",
        data: function () {
            return {
                routes :{
                    categories: host + '/api/v1/categories',
                    languages: host + '/api/v1/languages',
                    keywords: host + '/api/v1/keyword',
                    contents: host + '/api/v1/contents',
                    content: function (id) {
                        return host + '/api/v1/contents/' + id;
                    },
                    downloadContents: function (id) {
                        return host + '/api/v1/contents/' + id + '/download';
                    },
                }
            }
        }
    }
</script>
