<template>
    <div class="static-content-layout">
        <voice-guide></voice-guide>
        <default-header></default-header>
        <search-panel></search-panel>
        <div class="static-content-container p-0">
            <router-view></router-view>
        </div>
        <footer-panel :class="{'fixed-footer': isFixedFooter}"></footer-panel>
        <footer-panel-smart-phone :show-back-to-top-btn="true" :class="{'fixed-footer': isFixedFooter}"></footer-panel-smart-phone>
    </div>
</template>

<script>
    import DefaultHeader from "../../DefaultHeader/DefaultHeader";
    import SearchPanel from "../../SearchPanel/SearchPanel";
    import FooterPanel from "../../FooterPanel/FooterPanel";
    import FooterPanelSmartPhone from "../../FooterPanelSmartPhone/FooterPanelSmartPhone";
    import VoiceGuide from "../../VoiceGuide/VoiceGuide";
    export default {
        name: "StaticContentLayout",
        components: {VoiceGuide, FooterPanelSmartPhone, FooterPanel, SearchPanel, DefaultHeader},
        data: function () {
            return {
                isFixedFooter: false
            }
        },
        mounted: function () {
            this.fixFooter();
        },
        methods: {
            fixFooter: function () {
                const docHeight = document.body.clientHeight;
                this.isFixedFooter = docHeight < window.innerHeight;
            }
        }
    }
</script>

<style src="./StaticContentLayout.scss" lang="scss" />
