<template>
    <router-view></router-view>
</template>

<script>

    import ApiRouter from "./components/Systems/ApiRouter";
    import FavoriteKit from "./components/Helper/FavoriteKit";
    import ViewingHistoryKit from "./components/Helper/ViewingHistoryKit";

    export default {
        name: 'app',
        mixins: [ApiRouter, FavoriteKit, ViewingHistoryKit],
        metaInfo: function() {
            return {
                title: this.$t('siteInfo.title'),
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.$t('siteInfo.description'),
                    },
                    {
                        vmid: 'og-title',
                        property: 'og:title',
                        content: this.$t('siteInfo.title'),
                    },
                    {
                        vmid: 'og-type',
                        property: 'og:type',
                        content: 'website',
                    },
                    {
                        vmid: 'og-description',
                        property: 'og:description',
                        content: this.$t('siteInfo.description'),
                    },
                    {
                        vmid: 'og-url',
                        property: 'og:url',
                        content: location.href,
                    },
                    {
                        vmid: 'og-image',
                        property: 'og:image',
                        content: '../img/topimage01.jpg',
                    },
                    {
                        vmid: 'og-site-name',
                        property: 'og:site_name',
                        content: this.$t('siteInfo.title'),
                    },
                    {
                        vmid: 'og-locale',
                        property: 'og:locale',
                        content: this.$i18n.locale,
                    }
                ]
            }
        },
        created: function () {
            this.changeLang();
            this.loadBasicData();
            this.loadCaches();
        },
        methods: {
            changeLang: function () {
                const currentPath = this.$router.history.current.path;

                let lang = navigator.language
                    || navigator.userLanguage
                    || navigator.browserLanguage;
                lang = lang.substr(0, 2);
                lang = lang === 'ja' ? 'ja' : 'en';

                if (currentPath === '/') {
                    this.$router.push({name: 'Top', params: {lang: lang}})
                }

                if (this.$route.params.lang !== 'en' && this.$route.params.lang !== 'ja') {
                    this.$i18n.locale = 'en';
                } else {
                    this.$i18n.locale = this.$route.params.lang;
                }

                document.getElementsByTagName("html")[0].lang = this.$i18n.locale;
            },
            loadBasicData: function () {
                window.axios.get(this.routes.categories)
                    .then((response) => {
                        this.$root.caches.categories = response.data;
                    });
                // .catch((error) => {
                //     this.handleErrorStatusCodes(error);
                // })
            },
            loadCaches: function () {
                this.$root.caches.favoriteContents = this.getFavoriteContents();
                this.$root.caches.viewingHistoryOfContents = this.getViewingHistories();
            }
        }
    }
</script>

<style src="./styles/style.scss" lang="scss"></style>
