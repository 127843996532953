<script>
    export default {
        name: "SearchEvent",
        data: function () {
            return {
                composing: false
            }
        },
        methods: {
            searchButtonHasClicked: function () {
                if (this.composing) {
                    return;
                }

                const currentRouteName = this.$router.history.current.name;

                this.$root.searchContents();

                if (currentRouteName !== 'Contents') {
                    this.$router.push({ name: 'Contents'});
                }

                this.$emit('search-button-has-clicked');

                if (typeof this.closeSideBar === "function") {
                    this.closeSideBar();
                }
            }
        }
    }
</script>
