import Vue from 'vue';
import Router from 'vue-router';
import TopLayout from "./components/Layouts/TopLayout/TopLayout";
import Top from "./pages/Top/Top";
import DefaultLayout from "./components/Layouts/DefaultLayout/DefaultLayout";
import Contents from "./pages/Contents/Contents";
import StaticContentLayout from "./components/Layouts/StaticContentLayout/StaticContentLayout";
import PrivacyPolicy from "./pages/StaticPages/PrivacyPolicy";
import ContentDetail from "./pages/Contents/ContentDetail";
import HowToUse from "./pages/StaticPages/HowToUse";
import CookiePolicy from "./pages/StaticPages/CookiePolicy";
import Copyright from "./pages/StaticPages/Copyright";
import TermsOfUse from "./pages/StaticPages/TermsOfUse";
import ContactUs from "./pages/StaticPages/ContactUs";
import ViewingHistory from "./pages/ViewingHistory/ViewingHistory";
import Favorites from "./pages/Favorites/Favorites";
import Accessibility from "./pages/StaticPages/Accessibility";
import AboutCommentary from "./pages/StaticPages/AboutCommentary";

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/:lang',
            component: TopLayout,
            children: [
                {
                    path: '',
                    name: 'Top',
                    component: Top,
                },
            ],
        },
        {
            path: '/:lang/contents',
            component: DefaultLayout,
            children: [
                {
                    path: '',
                    name: 'Contents',
                    component: Contents,
                },
                {
                    path: 'viewing-history',
                    name: 'ViewingHistory',
                    component: ViewingHistory
                },
                {
                    path: 'favorites',
                    name: 'Favorites',
                    component: Favorites
                },
                {
                    path: ':id',
                    name: 'ContentDetail',
                    component: ContentDetail,
                },
            ]
        },
        {
            path: '/:lang/pages',
            component: StaticContentLayout,
            children: [
                {
                    path: 'privacy',
                    name: 'PrivacyPolicy',
                    component: PrivacyPolicy,
                },
                {
                    path: 'cookie',
                    name: 'CookiePolicy',
                    component: CookiePolicy,
                },
                {
                    path: 'copyright',
                    name: 'Copyright',
                    component: Copyright,
                },
                {
                    path: 'terms-of-use',
                    name: 'TermsOfUse',
                    component: TermsOfUse,
                },
                {
                    path: 'contact',
                    name: 'ContactUs',
                    component: ContactUs,
                },
                {
                    path: 'how-to-use',
                    name: 'HowToUse',
                    component: HowToUse,
                },
                {
                    path: 'accessibility',
                    name: 'Accessibility',
                    component: Accessibility,
                },
                {
                    path: 'about-commentary',
                    name: 'AboutCommentary',
                    component: AboutCommentary,
                },
            ]
        }
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});

