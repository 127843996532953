<script>
    export default {
        name: "SEOKit",
        metaInfo: function() {
            return {
                title: this.seoValues.title,
                titleTemplate: `%s | ${this.$t('siteInfo.title')}`,
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.seoValues.description,
                    },
                    {
                        vmid: 'og-title',
                        property: 'og:title',
                        content: this.seoValues.title,
                    },
                    {
                        vmid: 'og-description',
                        property: 'og:description',
                        content: this.seoValues.description,
                    },
                    {
                        vmid: 'og-image',
                        property: 'og:image',
                        content: this.seoValues.imageUrl,
                    }
                ]
            }
        },
        data: function () {
            return {
                seoValues: {
                    title: undefined,
                    description: undefined,
                    imageUrl: undefined
                }
            }
        },
        methods: {
            updateSeoInfoByContent: function (content) {
                this.seoValues.title = content.relationships.details[this.$i18n.locale].title;
                this.seoValues.description = content.relationships.details[this.$i18n.locale].content_original;
                this.seoValues.imageUrl = content.attributes.thumbnail_url;
            }
        }
    }
</script>
